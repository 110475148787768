import './OfficeStatusSamui.css';
import React from 'react';
//import { Navigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import BreadcrumbArea from './BreadcrumbArea';
import firebase, { analytics, auth, firestore, storage } from './firebase';
import DateTimePicker from 'react-datetime-picker'
import { FileUploader } from "react-drag-drop-files";


class OfficeStatusSamui extends React.Component{

    constructor(props){
      super(props);
      console.log('OfficeStatusSamui Props: ', this.props);

      this.state = {
        user: props.user,
        showAlert: false,
        officeStatus: { status: 'OPEN', message: ''}
      }
      
      console.log('OfficeStatusSamui Constructor', this.state);
    }

    getStatus = () => {
        var statusRef = firestore.collection('changeOfficeStatus').doc('SamuiOfficeStatus');
        statusRef.get().then((statusDoc) => {
            if(statusDoc.exists){
                var officeStatus = statusDoc.data();
                this.setState({officeStatus: officeStatus});
            } else {
                console.log("No officeStatus Document");
            }
        }).catch((error) =>{
            console.error('Error getting officeStatus Document: ', error);
        });

    }


    handleSave = (event) => {

        event.preventDefault();
        event.stopPropagation();
        var officeStatus = this.state.officeStatus;

        officeStatus.timestamp = firebase.firestore.FieldValue.serverTimestamp();
        officeStatus.updatedBy = { name: this.state.user.displayName, email: this.state.user.email, uid: this.state.user.uid };

        firestore.collection('changeOfficeStatus').doc('SamuiOfficeStatus').update(officeStatus)
        .then(() => {
            console.log("Office Status updated with Status: ", officeStatus);
            //statusMessage
            //document.getElementById("statusMessage").style.display = 'block !important';
            //setTimeout(() => document.getElementById("statusMessage").style.display = 'none !important', 5000);

            this.setState({ showAlert: true });
            this.timeout = setTimeout(() => {
                this.setState({ showAlert: false });
            }, 5000);

        })
        .catch((error) => {
            console.error('Error updating customer: ', error);
        });
    }


    handleCancel = (event) => {
        //REDIRECT BACK TO HOME PAGE...
        window.location.assign("/");
    }

    handleChange = (event) => {
        console.log('Handle Change for Event: ', event.target.name, event.target.value);
        var officeStatus = this.state.officeStatus;
        officeStatus[event.target.id.trim()] = event.target.value;
        this.setState({officeStatus: officeStatus});
    }


    render() {
        var officeStatus = this.state.officeStatus;
        var user = this.state.user;
        console.log("Rendering... Office Status: ", officeStatus);

        return(

        <>
        <Header user={user} />
        <BreadcrumbArea currentPage={"Samui Office Status"} user={user} />
        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">

                            <form id='officeStatusForm' className="needs-validation" noValidate  onSubmit={this.handleSave}>
                            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2">

                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="status" className="form-label">Status</label>

                                        <select className="form-select" aria-label="Status" id="status" value={officeStatus.status}  onChange={this.handleChange}>
                                            <option value="OPEN">OPEN</option>
                                            <option value="CLOSED">CLOSED</option>
                                        </select>                                                
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="message" className="form-label">Message</label>
                                        <input type="text" className="form-control" id="message" placeholder="Back at 1pm" value={officeStatus.message} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>

                            {this.state.showAlert && (

                            <div className="position-fixed top-50 start-50 translate-middle" style={{zIndex: 11}}>
                                <div id="liveToast" className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                                    <div className="toast-header">
                                        <h5 className="me-auto">Change Samui Office Status</h5>
                                        <small>Just Now...</small>
                                    </div>
                                    <div className="toast-body">
                                        <span className="success"><i className="bi bi-check-circle-fill"></i></span> Status updated!
                                    </div>
                                </div>
                            </div>

                            )}

                            <div className="mb-3">
                                <button type="submit" className="btn btn-primary mb-3"><i className="bi bi-cloud-check-fill"></i>&nbsp;Save Status</button>
                                <button type="button" className="btn btn-outline-secondary mb-3 ms-3" onClick={this.handleCancel}><i className="bi bi-x-octagon-fill"></i>&nbsp;Cancel</button>
                            </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
        );
    }

    componentDidMount(){
        this.onAuthStateChangedObserver = auth.onAuthStateChanged((user) => {
          if (user) {
            var uid = user.uid;
            this.setState({ user: user });
            this.getStatus();
          } else {
        
            this.setState({ 
              user: null,    
            });        
            console.log('User is logged out...');
          }
        });
      }
    }
    
export default OfficeStatusSamui;
    