import './DailyStatusUpdate.css';
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import BreadcrumbArea from './BreadcrumbArea';
import firebase, { analytics, auth, firestore, storage } from './firebase';


var today = new Date();
var dateString = today.toISOString().split('T')[0]
const defaultStatus = { id: dateString, date: today, openTime: '14:00', closeTime: '22:00', buyers: 0, sellers: 0, renters: 0, listings: 0, activity: '', saved: false};

class DailyStatusUpdate extends React.Component{


    constructor(props){
      super(props);
      console.log('DailyStatusUpdate Props: ', this.props);


      this.state = {
        user: props.user,
        showAlert: false,
        reload: false,
        dailyStatus: defaultStatus
      }
      
      console.log('DailyStatusUpdate Constructor', this.state);
    }

    getStatus = () => {
        var statusRef = firestore.collection('changeDailyStatusReport').doc(this.state.dailyStatus.id);
        statusRef.get().then((statusDoc) => {
            if(statusDoc.exists){
                var dailyStatus = statusDoc.data();
                dailyStatus.saved = true;
                this.setState({reload: false, dailyStatus: dailyStatus});
            } else {
                console.log("No Daily Status Report Document");
                var today = new Date(this.state.dailyStatus.id);
                this.setState({reload: false, dailyStatus: defaultStatus});
            }
        }).catch((error) =>{
            console.error('Error getting Daily Status Report Document: ', error);
        });

    }


    handleSave = (event) => {

        event.preventDefault();
        event.stopPropagation();
        var dailyStatus = this.state.dailyStatus;

        dailyStatus.timestamp = firebase.firestore.FieldValue.serverTimestamp();
        dailyStatus.updatedBy = { name: this.state.user.displayName, email: this.state.user.email, uid: this.state.user.uid };

        firestore.collection('changeDailyStatusReport').doc(this.state.dailyStatus.id).set(dailyStatus)
        .then(() => {
            console.log("Daily Status Report updated with Status: ", dailyStatus);
            //statusMessage
            //document.getElementById("statusMessage").style.display = 'block !important';
            //setTimeout(() => document.getElementById("statusMessage").style.display = 'none !important', 5000);
            dailyStatus.saved = true;

            this.setState({ showAlert: true, dailyStatus: dailyStatus });
            this.timeout = setTimeout(() => {
                this.setState({ showAlert: false });
            }, 5000);

        })
        .catch((error) => {
            console.error('Error updating daily status report: ', error);
        });
    }


    handleCancel = (event) => {
        //REDIRECT BACK TO HOME PAGE...
        window.location.assign("/");
    }

    copyStatus = (event) => {
        console.log('Copy...');
        var dailyStatus = this.state.dailyStatus;

        let clipboardText = 'Daily Status Report: ' + dailyStatus.id;
        clipboardText += '\nOpen Time: ' + dailyStatus.openTime;
        clipboardText += '\nClose Time: ' + dailyStatus.closeTime;
        clipboardText += '\nBuyers: ' + dailyStatus.buyers;
        clipboardText += '\nSellers: ' + dailyStatus.sellers;
        clipboardText += '\nRenters: ' + dailyStatus.renters;
        clipboardText += '\nListings Added: ' + dailyStatus.listings;
        clipboardText += '\nActivities: ' + dailyStatus.activity;

        console.log(clipboardText);
        navigator.clipboard.writeText(clipboardText);    
    
    

    }

    handleChange = (event) => {
        console.log('Handle Change for Event: ', event.target.id, event.target.value);
        var dailyStatus = this.state.dailyStatus;
        dailyStatus[event.target.id.trim()] = event.target.value;
        //If event target == id, load data...
        if(event.target.id.trim() === 'id'){
            this.setState({reload: true, dailyStatus: dailyStatus});
        } else {
            this.setState({reload: false, dailyStatus: dailyStatus});
        }
    }


    render() {

        var reload = this.state.reload;
        if (reload){
            this.getStatus();

            return(
                <>
                <Header user={user} />
                <BreadcrumbArea currentPage={"Daily Office Status Report: " + this.state.dailyStatus.id} user={user} />
                <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
                    <div className=" min-height-400 d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status" style={{marginTop: 'auto', marginBottom: 'auto'}}>
                        <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                </>
              )


        }
        var dailyStatus = this.state.dailyStatus;
        var user = this.state.user;
        console.log("Rendering... Daily Office Status: ", dailyStatus);

        return(

        <>
        <Header user={user} />
        <BreadcrumbArea currentPage={"Daily Office Status Report: " + this.state.dailyStatus.id} user={user} />
        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">

                            <form id='dailyOfficeStatusForm' className="needs-validation" noValidate  onSubmit={this.handleSave}>
                            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1">
                            <div className="col">
                                <div className="mb-3">
                                    <label htmlFor="id" className="form-label">Report Date</label>
                                    <input id="id" aria-label="Date" type="date" onChange={this.handleChange} value={dailyStatus.id} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="openTime" className="form-label">Open Time</label>

                                    <select className="form-select" aria-label="Open Time" id="openTime" value={dailyStatus.openTime}  onChange={this.handleChange}>
                                        <option value="07:00">07:00</option>
                                        <option value="07:30">07:30</option>
                                        <option value="08:00">08:00</option>
                                        <option value="08:30">08:30</option>
                                        <option value="09:00">09:00</option>
                                        <option value="09:30">09:30</option>
                                        <option value="10:00">10:00</option>
                                        <option value="10:30">10:30</option>
                                        <option value="11:00">11:00</option>
                                        <option value="11:30">11:30</option>
                                        <option value="12:00">12:00</option>
                                        <option value="12:30">12:30</option>
                                        <option value="13:00">13:00</option>
                                        <option value="13:30">13:30</option>
                                        <option value="14:00">14:00</option>
                                        <option value="14:30">14:30</option>
                                        <option value="15:00">15:00</option>
                                        <option value="15:30">15:30</option>
                                        <option value="16:00">16:00</option>
                                        <option value="16:30">16:30</option>
                                        <option value="17:00">17:00</option>
                                        <option value="17:30">17:30</option>
                                        <option value="18:00">18:00</option>
                                        <option value="18:30">18:30</option>
                                        <option value="19:00">19:00</option>
                                        <option value="19:30">19:30</option>
                                        <option value="20:00">20:00</option>
                                        <option value="20:30">20:30</option>
                                        <option value="21:00">21:00</option>
                                        <option value="21:30">21:30</option>
                                        <option value="22:00">22:00</option>
                                        <option value="22:30">22:30</option>
                                        <option value="23:00">23:00</option>
                                        <option value="23:30">23:30</option>
                                    </select>                                                
                                    <label htmlFor="closeTime" className="form-label">Close Time</label>

                                    <select className="form-select" aria-label="Close Time" id="closeTime" value={dailyStatus.closeTime}  onChange={this.handleChange}>
                                        <option value="07:00">07:00</option>
                                        <option value="07:30">07:30</option>
                                        <option value="08:00">08:00</option>
                                        <option value="08:30">08:30</option>
                                        <option value="09:00">09:00</option>
                                        <option value="09:30">09:30</option>
                                        <option value="10:00">10:00</option>
                                        <option value="10:30">10:30</option>
                                        <option value="11:00">11:00</option>
                                        <option value="11:30">11:30</option>
                                        <option value="12:00">12:00</option>
                                        <option value="12:30">12:30</option>
                                        <option value="13:00">13:00</option>
                                        <option value="13:30">13:30</option>
                                        <option value="14:00">14:00</option>
                                        <option value="14:30">14:30</option>
                                        <option value="15:00">15:00</option>
                                        <option value="15:30">15:30</option>
                                        <option value="16:00">16:00</option>
                                        <option value="16:30">16:30</option>
                                        <option value="17:00">17:00</option>
                                        <option value="17:30">17:30</option>
                                        <option value="18:00">18:00</option>
                                        <option value="18:30">18:30</option>
                                        <option value="19:00">19:00</option>
                                        <option value="19:30">19:30</option>
                                        <option value="20:00">20:00</option>
                                        <option value="20:30">20:30</option>
                                        <option value="21:00">21:00</option>
                                        <option value="21:30">21:30</option>
                                        <option value="22:00">22:00</option>
                                        <option value="22:30">22:30</option>
                                        <option value="23:00">23:00</option>
                                        <option value="23:30">23:30</option>
                                    </select>                                                

                                </div>
                            </div>

                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="buyers" className="form-label">Buyers</label>

                                        <select className="form-select" aria-label="Buyers" id="buyers" value={dailyStatus.buyers}  onChange={this.handleChange}>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10+</option>
                                        </select>                                                
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="sellers" className="form-label">Sellers</label>

                                        <select className="form-select" aria-label="Sellers" id="buyers" value={dailyStatus.sellers}  onChange={this.handleChange}>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10+</option>
                                        </select>                                                
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="renters" className="form-label">Renters</label>

                                        <select className="form-select" aria-label="Renters" id="renters" value={dailyStatus.renters}  onChange={this.handleChange}>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10+</option>
                                        </select>                                                
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="listings" className="form-label">Listings Added</label>

                                        <select className="form-select" aria-label="Listings Added" id="listings" value={dailyStatus.listings}  onChange={this.handleChange}>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10+</option>
                                        </select>                                                
                                    </div>
                                </div>


                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="activity" className="form-label">Activity</label>
                                        <input type="text" className="form-control" id="activity" placeholder="Visited land. Took Clients to see villa. Etc." value={dailyStatus.activity} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>

                            {this.state.showAlert && (

                            <div className="position-fixed top-50 start-50 translate-middle" style={{zIndex: 11}}>
                                <div id="liveToast" className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                                    <div className="toast-header">
                                        <h5 className="me-auto">Change Samui Daily Office Status</h5>
                                        <small>Just Now...</small>
                                    </div>
                                    <div className="toast-body">
                                        <span className="success"><i className="bi bi-check-circle-fill"></i></span> Status updated!
                                    </div>
                                </div>
                            </div>

                            )}

                            <div className="mb-3">
                                <button type="submit" className="btn btn-primary mb-3"><i className="bi bi-cloud-check-fill"></i>&nbsp;Save Status</button>
                                {this.state.dailyStatus.saved && (
                                    <>
                                    <a className="btn btn-outline-secondary mb-3 ms-3" onClick={ this.copyStatus }>
                                        <i className="bi bi-clipboard-plus"></i> Copy to Clipboard
                                    </a>
                                    </>

                                )}
                                <button type="button" className="btn btn-outline-secondary mb-3 ms-3" onClick={this.handleCancel}><i className="bi bi-x-octagon-fill"></i>&nbsp;Cancel</button>
                            </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
        );
    }

    componentDidMount(){
        this.onAuthStateChangedObserver = auth.onAuthStateChanged((user) => {
          if (user) {
            var uid = user.uid;
            this.setState({ user: user });
            this.getStatus();
          } else {
        
            this.setState({ 
              user: null,    
            });        
            console.log('User is logged out...');
          }
        });
      }
    }
    
export default DailyStatusUpdate;
    