//import './OfficeStatusLog.css';
import React from 'react';
//import { Navigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import BreadcrumbArea from './BreadcrumbArea';
import firebase, { analytics, auth, firestore, storage } from './firebase';
import DateTimePicker from 'react-datetime-picker'
import { FileUploader } from "react-drag-drop-files";


class OfficeStatusLog extends React.Component{

    constructor(props){
      super(props);
      console.log('OfficeStatusLog Props: ', this.props);

      this.state = {
        user: props.user,
        loaded: false,
        officeStatusList: []
      }
      
      console.log('OfficeStatusLog Constructor', this.state);
    }

    getOfficeStatusLog = () => {
        var officeStatusListRef = firestore.collection('changeOfficeStatusLogs').orderBy("timestamp", "desc");
        officeStatusListRef.get().then((officeStatusListSnap) => {
            var officeStatusList = [];
            officeStatusListSnap.forEach((statusLog) => {
                //console.log(user.id, " => ", user.data());
                var statusLogObject = statusLog.data();
                statusLogObject.key = statusLog.id;
                officeStatusList.push(statusLogObject)
            });
            console.log("Storing OfficeStatusLog in State: ", officeStatusList);
            this.setState({officeStatusList: officeStatusList, loaded: true});
            
        }).catch((error) =>{
            console.error('Error getting users: ', error);
        });
    }

    getFormattedDayOfWeek = (fireBaseTimeStamp ) => {
        var jsDate = fireBaseTimeStamp.toDate();        
        var formattedDate = jsDate.toLocaleDateString('en-US', { weekday: "long", timeZone: "Asia/Bangkok"});
        return(formattedDate);
    }

    getFormattedDate = (fireBaseTimeStamp ) => {
        var jsDate = fireBaseTimeStamp.toDate();        
        var formattedDate = jsDate.toLocaleDateString('en-US', { timeZone: "Asia/Bangkok"});
        return(formattedDate);
    }

    getFormattedTime = (fireBaseTimeStamp ) => {
        var jsDate = fireBaseTimeStamp.toDate();        
        var formattedTime = jsDate.toLocaleTimeString('en-US', { timeZone: "Asia/Bangkok"});
        return(formattedTime);
    }

    deleteLogEntry = (logID, event) => {
        if(confirm("Are you sure you want to delete this log entry?")){

            event.preventDefault();
            event.stopPropagation();

            firestore.collection('changeOfficeStatusLogs').doc(logID).delete().then(() => {
                console.log("Log Entry successfully deleted!");
                this.getOfficeStatusLog();
            }).catch((error) => {
                console.error("Error removing Log Entry: ", error);
            });
        }

    }


    render() {
        var officeStatusList = this.state.officeStatusList;
        var user = this.state.user;
        var loaded = this.state.loaded;
        console.log("Rendering... Office Status List: ", officeStatusList.length, officeStatusList);

        if(officeStatusList.length === 0){
            return(
              <>
                <Header user={user} />
                <BreadcrumbArea currentPage={"Loading..."} />
                <div className="container">
                  <div className=" min-height-400 d-flex justify-content-center">
                    <div className="text-primary text-center" role="status" style={{marginTop: 'auto', marginBottom: 'auto'}}>
                        <h2>Loading...</h2>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </>
            )    
        }  

        console.log('OfficeStatusLog length != 0');
        return(

        <>
        <Header user={user} />
        <BreadcrumbArea currentPage={"Office Status Log"} user={user} />
        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <td className="fw-bold table-dark">Office</td>
                                        <td className="fw-bold table-dark">Date</td>
                                        <td className="fw-bold table-dark">Day</td>
                                        <td className="fw-bold table-dark">Time</td>
                                        <td className="fw-bold table-dark">Status</td>
                                        <td className="fw-bold table-dark">Message</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    { officeStatusList.map(officeStatus => (
                                        <tr key={officeStatus.key}>
                                            <td>{officeStatus.id === 'SamuiOfficeStatus' ? 'Samui' : 'Hua Hin'}</td>
                                            <td>{this.getFormattedDate(officeStatus.timestamp)}</td>
                                            <td>{this.getFormattedDayOfWeek(officeStatus.timestamp)}</td>
                                            <td>{this.getFormattedTime(officeStatus.timestamp)}</td>
                                            <td>{officeStatus.status}</td>
                                            <td>
                                                {officeStatus.message}
                                                <button type="button" className="btn btn-outline-secondary float-end" onClick={ this.deleteLogEntry.bind(this, officeStatus.key) }><i className="bi bi-trash-fill"></i></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
        );
    }

    componentDidMount(){
        this.onAuthStateChangedObserver = auth.onAuthStateChanged((user) => {
          if (user) {
            var uid = user.uid;
            this.setState({ user: user });
            this.getOfficeStatusLog();
          } else {
        
            this.setState({ 
              user: null,    
            });        
            console.log('User is logged out...');
          }
        });
      }
    }
    
export default OfficeStatusLog;
    