import './CustomerAddEdit.css';
import React from 'react';
//import { Navigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import BreadcrumbArea from './BreadcrumbArea';
import firebase, { analytics, auth, firestore, storage } from './firebase';
import DateTimePicker from 'react-datetime-picker'
import { FileUploader } from "react-drag-drop-files";
import moment from 'moment/moment';

function deleteMessage(currentList, messageID, event) {
    if(confirm("Are you sure you want to delete this message?")){

        event.preventDefault();
        event.stopPropagation();

        firestore.collection(currentList).doc(messageID).delete().then(() => {
            console.log("Message successfully deleted!");
            window.location.reload();
        }).catch((error) => {
            console.error("Error removing message: ", error);
        });
    }
}


function GetMessages(props){       
    var messageList = props.messageList;
    var currentList = props.currentList;
    var result = [];

    for(let i=0; i< messageList.length; i++){

        result.push(
            <div key={messageList[i].id} className="col mb-2">
                <div className="card border">
                    <div className="card-body">
                        <div className="card-text small">
                            <p className="mb-0">Message ID: { messageList[i].id }</p>
                            <button type="button" className="btn btn-outline-secondary float-end" onClick={ deleteMessage.bind(this,  currentList, messageList[i].id) }><i className="bi bi-trash-fill"></i></button>
                            <GetMessageContents key={ messageList[i].id } message={messageList[i]} currentList={currentList} />
                        </div>
                    </div>
                </div>
            </div>                
        );
    }
    return(result)
}

function GetMessageContents(props){

    var message = props.message;
    var currentList = props.currentList;

    const contact_lists = {
        'changeSamuiContactForm': ['contact_name', 'contact_email_address', 'contact_phone', 'contact_subject', 'contact_ip_address', 'contact_message', 'contact_page_url', 'contact_user_agent', 'subscribe'], 
        'changeSamuiContactFormAlt': ['name', 'email', 'phone', 'subject', 'ipAddress', 'message', 'contactPageURL', 'userAgent', 'subscribe'], 
        'changeRealEstateContactForm': ['name', 'email', 'phone', 'subject', 'ipAddress', 'message', 'contactPageURL', 'userAgent', 'subscribe'], 
        'changeRealEstateContactFormAlt': ['name', 'email', 'phone', 'subject', 'ipAddress', 'message', 'contactPageURL', 'userAgent', 'subscribe'], 
        'changeRealEstateValuationForm': ['contactFormName', 'contactFormEmail', 'contactFormPhone', 'property_special_features', 'ipAddress', 'property_comments', 'userAgent', 'property_search_street', 'property_search_addr_2', 'property_search_city', 'property_search_state', 'property_search_postal', 'property_type'], 
        'changeRealEstateValuationFormAlt': ['contactFormName', 'contactFormEmail', 'contactFormPhone', 'property_special_features','ipAddress', 'property_comments',  'userAgent', 'property_search_street', 'property_search_addr_2', 'property_search_city', 'property_search_state', 'property_search_postal', 'property_type'], 
        'changeHuaHinContactForm': ['name', 'email', 'phone', 'subject', 'ipAddress', 'message', 'contactPageURL', 'contactFormSource', 'userAgent', 'subscribe'],
        'changeHuaHinContactFormAlt': ['name', 'email', 'phone', 'subject', 'ipAddress', 'message', 'contactPageURL', 'contactFormSource', 'userAgent', 'subscribe']
    };
   
    var currentListAlt = currentList + 'Alt';
    var currentListMeta = contact_lists[currentList];
    var currentListMetaAlt = contact_lists[currentListAlt];

    var result = [];

    //result.push(<div key={message.id}>);

    if(message[currentListMeta[0]] === undefined){
        for(let i=0; i< currentListMetaAlt.length; i++){
            result.push(
                <p key={i} className="mb-0">{currentListMetaAlt[i]}: {message[currentListMetaAlt[i]]}</p> 
            );
        }
    } else {
        for(let i=0; i< currentListMeta.length; i++){
            result.push(
                <p key={i} className="mb-0">{currentListMeta[i]}: {message[currentListMeta[i]]}</p> 
            );
        }

    }
    result.push(
        <p className="mb-0">timestamp: {moment(message.timestamp.toDate()).format('DD-MMM-YYYY [at] HH:mm:ss')}</p>         
    );

    if(message['cityLatLong'] !== undefined){
        result.push(
            <p className="mb-0">Coordinates: <a target="_blank" href={"https://maps.google.com/?q=" + message.cityLatLong._lat + "," + message.cityLatLong._long}>{message.cityLatLong._lat},{message.cityLatLong._long}</a></p> 
        );
    }

    //result.push(</div>);
    

    return(result);

}


class MessageList extends React.Component{

    constructor(props){
      super(props);
      console.log('MessageList Props: ', this.props);

      this.state = {
        user: props.user,
        showAlert: false,
        loaded: false,
        currentList: 'changeSamuiContactForm',
        currentTitleIndex: 0,
        //currentList: 'changeRealEstateContactForm',
        //currentList: 'changeRealEstateValuationForm,
        //currentList: 'changeHuaHinContactForm',
        messageList: []
      }
      
      console.log('MessageList Constructor', this.state);
    }

    getMessageList = (listName) => {
        var messageList = [];
        var messageListRef = firestore.collection(listName).orderBy('timestamp', 'desc');
        messageListRef.get().then((messageListSnap) => {
            messageListSnap.forEach((message) => {
                //console.log(message.id, " => ", message.data());
                var messageObject = message.data();
                messageObject.id = message.id;
                messageList.push(messageObject)
            });
            this.setState({messageList: messageList, loaded: true});
        }).catch((error) =>{
            console.error('Error getting messages: ', error);
        });
    }

    switchLists = (event) => {
        console.log('Changing Message List to ', event.target.value, event.target.selectedIndex);
        this.setState({currentList: event.target.value, currentTitleIndex: event.target.selectedIndex, messageList: [], loaded: false });
        this.getMessageList(event.target.value);
    }

    render() {
      
        var messageList = this.state.messageList;
        var currentList = this.state.currentList;
        var user = this.state.user;

        var listOptions = [
            {value: "changeSamuiContactForm", title: "Change Samui Contact Form"},
            {value: "changeRealEstateContactForm", title: "Change Vegas Contact Form"},
            {value: "changeRealEstateValuationForm", title: "Change Vegas Valuation Form"},
            {value: "changeHuaHinContactForm", title: "Change Hua Hin Contact Form"},
        ]

        if(messageList.length === 0){
            return(
              <>
                <Header user={user} />
                <BreadcrumbArea currentPage={"Loading..."} />
                <div className="container">
                  <div className=" min-height-400 d-flex justify-content-center">
                    <div className="text-primary text-center" role="status" style={{marginTop: 'auto', marginBottom: 'auto'}}>
                        <h2>Loading...</h2>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </>
            )    
        }  


        //console.log(this.state.currentTitleIndex);
        //console.log(listOptions);
        //console.log(listOptions[this.state.currentTitleIndex]);
        var selectedTitle = listOptions[this.state.currentTitleIndex].title;

        console.log("Rendering... ", currentList, selectedTitle, " Message List: ", messageList);

        return(

        <>
        <Header user={user} />
        <BreadcrumbArea currentPage={"Message List: " + selectedTitle } user={user} />
        <div className="content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10 m-2">
            <div className="card card-bordered shadow-none mb-3">
                <div className="card-body">

                    <select className="form-select mb-2" aria-label="Choose the Contact Message List" onChange={this.switchLists.bind(this)} value={currentList}>
                        {listOptions.map(optionObject =>
                        <option value={optionObject.value}>{optionObject.title}</option>
                        )}
                    </select>

                    <div className="row row-cols-3">
                        <GetMessages messageList={messageList} currentList={currentList} />
                </div>

                </div>
            </div>
        </div>
        <Footer />
        </>
        );
    }

    componentDidMount(){
        this.onAuthStateChangedObserver = auth.onAuthStateChanged((user) => {
          if (user) {
            var uid = user.uid;
            this.setState({ user: user });
            this.getMessageList(this.state.currentList);
          } else {
        
            this.setState({ 
              user: null,    
            });        
            console.log('User is logged out...');
          }
        });
      }
    }
    
export default MessageList;
    