import './Unauthorized.css';
import React from 'react';
import { useParams } from "react-router-dom";
//import { auth } from './firebase';
import { getAuth, signInWithCustomToken } from "firebase/auth";

function TestPage(props){
  console.log("*** HERE TEST ***");
  const params = useParams();
  console.log("Displaying Test with Token: ", params.token); 

  //:token
  //eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTcwNTIyODQ5NiwiZXhwIjoxNzA1MjMyMDk2LCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay03cjhsaEBjaGFuZ2UtcmVhbC1lc3RhdGUuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJzdWIiOiJmaXJlYmFzZS1hZG1pbnNkay03cjhsaEBjaGFuZ2UtcmVhbC1lc3RhdGUuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJ1aWQiOiI5OWtsVmpSd3lKZE54R1AyejFqTGIxdFZrY0gzIn0.suIJqpWVsgxq3D5OUiGHWqVCPSUrk9g4XXkjtGZ_3qYaEraoXXWm7CMLWjw6NSwFWngey-GTUpIHZBAfw7TA_pHIT8yGJS46rZ6-0sYMIhJ_42DiosBzxoIoMs-yPt0ZmBxLICVlEs9wodurT5RqaSuK0cms_wMUREbQytw1-4gfBcns_np1kZQ55sNtxCFBJlSgdW1QqxcKupgeVaHYeTK2SD2XXF-hvZgetmLdayNY_DUrgbHxPv647uSLkFCczof0mSLkfyjeRdeaoNDdkcnUiRFrHzc9RtyWI0QkarM4nbilZzMeGIL7Q4VDNBNS3UhSjGW0yitfOXJg09L05A

  // http://localhost:3001/test/eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTcwNTIyODQ5NiwiZXhwIjoxNzA1MjMyMDk2LCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay03cjhsaEBjaGFuZ2UtcmVhbC1lc3RhdGUuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJzdWIiOiJmaXJlYmFzZS1hZG1pbnNkay03cjhsaEBjaGFuZ2UtcmVhbC1lc3RhdGUuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJ1aWQiOiI5OWtsVmpSd3lKZE54R1AyejFqTGIxdFZrY0gzIn0.suIJqpWVsgxq3D5OUiGHWqVCPSUrk9g4XXkjtGZ_3qYaEraoXXWm7CMLWjw6NSwFWngey-GTUpIHZBAfw7TA_pHIT8yGJS46rZ6-0sYMIhJ_42DiosBzxoIoMs-yPt0ZmBxLICVlEs9wodurT5RqaSuK0cms_wMUREbQytw1-4gfBcns_np1kZQ55sNtxCFBJlSgdW1QqxcKupgeVaHYeTK2SD2XXF-hvZgetmLdayNY_DUrgbHxPv647uSLkFCczof0mSLkfyjeRdeaoNDdkcnUiRFrHzc9RtyWI0QkarM4nbilZzMeGIL7Q4VDNBNS3UhSjGW0yitfOXJg09L05A


  const auth = getAuth();
  console.log(auth);
  signInWithCustomToken(auth, params.token)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log(user);
      window.open("/", "_self");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
  });
  

    const handleGoogleLogout = () => {

      auth.signOut().then(() => {
        // Sign-out successful.
        
      }).catch((error) => {
        // An error happened.
      });
    }
  
    return(
      <>
      
      <div className="container">
      <div className=" min-height-400 d-flex justify-content-center">
      <div className="text-primary text-center" role="status" style={{marginTop: 'auto', marginBottom: 'auto'}}>
        <h2>Unauthorized</h2>
          <button type="submit" className="btn btn-outline-primary" onClick={ handleGoogleLogout }>
            <span className="d-flex justify-content-center align-items-center">
                Logout...
            </span>
          </button>
        </div>
      </div>
    </div>    
    </>
    );
  
  }

  
  export default TestPage;