import './Unauthorized.css';
import React from 'react';
import { auth } from './firebase';

function Unauthorized(props){
  
    const handleGoogleLogout = () => {

      auth.signOut().then(() => {
        // Sign-out successful.
        
      }).catch((error) => {
        // An error happened.
      });
    }
  
    return(
      <>
      
      <div className="container">
      <div className=" min-height-400 d-flex justify-content-center">
      <div className="text-primary text-center" role="status" style={{marginTop: 'auto', marginBottom: 'auto'}}>
        <h2>Unauthorized</h2>
          <button type="submit" className="btn btn-outline-primary" onClick={ handleGoogleLogout }>
            <span className="d-flex justify-content-center align-items-center">
                Logout...
            </span>
          </button>
        </div>
      </div>
    </div>    
    </>
    );
  
  }

  
  export default Unauthorized;