import './Footer.css';
import React from 'react';

function Footer(props){

    console.log('Footer Props ', props);
    
    return(
  

      <footer className="bg-dark">
        <div className="container pb-1 pb-lg-5">
          <div className="row content-space-t-2">
            <div className="col-lg-12 mb-7 mb-lg-0">

              <div className="mb-5">
                <a className="navbar-brand" href="/" aria-label="Space">
                  { window.location.hostname === 'admin.changehuahin.com' ? 
                  <img className="navbar-brand-logo" src="/img/logo.huahin.inverted.1922.png" alt="Change Samui Real Estate" />
                  :
                  <img className="navbar-brand-logo" src="/img/logo.inverted.1922.png" alt="Change Samui Real Estate" />
                  }
                </a>
              </div>

              <ul className="list-unstyled list-py-1">
                { window.location.hostname === 'admin.changehuahin.com' ? 
                <li><a className="link-sm link-light" href="#"><i className="bi-geo-alt-fill me-1"></i> 124/1-2 Naresdamri Rd, Hua Hin, Hua Hin District, Prachuap Khiri Khan 77110, Thailand</a></li>
                :
                <li><a className="link-sm link-light" href="#"><i className="bi-geo-alt-fill me-1"></i> 79/9 Moo 1 Fisherman Village, Bo Put, Surat Thani 84320, Thailand</a></li>
                }
                <li><a className="link-sm link-light" href="tel:+66-98-906-8285"><i className="bi-telephone-inbound-fill me-1"></i> +66-98-906-8285</a></li>
              </ul>
            
            </div>
    
          </div>
    
          <div className="border-top border-white-10 my-7"></div>
    

          <div className="w-md-85 text-lg-center mx-lg-auto">
            <p className="text-white-50 small">&copy; { new Date().getFullYear() } { window.location.hostname === 'admin.changehuahin.com' ? 'Change Hua Hin Real Estate' : 'Change Samui Real Estate' }. All rights reserved.</p>
            <p className="text-white-50 small">When you visit or interact with our sites, services or tools, we or our authorised service providers may use cookies for storing information to help provide you with a better, faster and safer experience and for marketing purposes.</p>
          </div>
        </div>
      </footer>
  
  

  
    );  
  }


export default Footer;

  