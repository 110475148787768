import './BreadcrumbArea.css';
import React from 'react';
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';

function BreadcrumbArea(props){

    console.log('Breadcrumb Props ', props);
    const navigate = useNavigate();
    const handleGoogleLogout = () => {
      auth.signOut().then(() => {
        // Sign-out successful.
        navigate("/", {replace:true});        
    
      }).catch((error) => {
        // An error happened.
      });
    }
    
    return(
  
      <div className="navbar-dark bg-dark" style={{ backgroundImage: "url(./assets/svg/components/wave-pattern-light.svg)" }}>
        <div className="container content-space-1 content-space-b-lg-3">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h2 text-white">{ props.currentPage }</h1>
              </div>
  
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                  <li className="breadcrumb-item">{ window.location.hostname }</li>
                  <li className="breadcrumb-item"><a href="/">Admin</a></li>
                  { props.parentPage &&
                  <li className="breadcrumb-item"><a href={ props.parentPage.url }>{ props.parentPage.name }</a></li>
                  }
                  <li className="breadcrumb-item active" aria-current="page">{ props.currentPage }</li>
                </ol>
              </nav>
            </div>
  
            <div className="col-auto">
              <div className="d-none d-lg-block">
                { props.user && 
                <span>{ props.user.displayName }&nbsp;</span>
                }
                <a className="btn btn-soft-light btn-sm" onClick={ handleGoogleLogout }>Log out</a>
              </div>
  
            </div>
          </div>
        </div>
      </div>
  
  
    );  
  }


export default BreadcrumbArea;

  