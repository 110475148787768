import './Customers.css';
import React from 'react';

import { Navigate } from 'react-router-dom';
import {Modal} from 'bootstrap';

import firebase, { analytics, auth, firestore, storage } from './firebase';
import md5 from 'md5';
import moment from 'moment';

import Header from './Header';
import Footer from './Footer'
import BreadcrumbArea from './BreadcrumbArea';
import Unauthorized from './Unauthorized';

import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

class Customers extends React.Component{

  constructor(props){
    super(props);

    let siteFilter = localStorage.getItem('FilterCustomerSite');
    let defaultValue = 'all';

    if(window.location.hostname === 'admin.changehuahin.com'){
      defaultValue = 'huahin';
    }
    if(window.location.hostname === 'admin.changesamui.com'){
      defaultValue = 'samui';
    }

    this.state = {
      user: props.user,
      userList: [],
      reminders: [],
      customers: [],
      customersByID: [],
      customersByEmail: [],
      customersByPhone: [],
      searchResults: [],
      editCustomerID: '',
      reminderMessage: '',
      reminderDate: new Date(),
      siteName: siteFilter ? siteFilter : defaultValue,
      pageSize: 20,
      currentPage: 1,
      refreshCount: 0,
      //showCustomerReminderModal: false,
    }
  }

  getAuthorizedUsers = () => {
    var that = this;
    var userList = [];
    var userQuery = firestore.collection('users').where("admin", "==", true).orderBy("displayName", "desc");    
    userQuery.get().then((snapshot) => {
        var userCount = snapshot.size;
        if(userCount){
            snapshot.forEach(function(userDoc){
                var userObject = userDoc.data();
                userObject.id = userDoc.id;
                userList.push(userObject);
            })
        } else {
            console.log('Snapshot is empty...')  
        }
        console.log('Retrieved users:', userList);
        that.setState({userList: userList});
    });      
  }

  getReminders = async () => {
    //let reminders = [];
    //firestore.collection('changeSamuiReminders').onSnapshot((snapshot) => {

    const query = firestore.collection('changeSamuiReminders');
    let reminders = [];
    let snapshot = await query.get();

      var reminderCount = snapshot.size;
      if(reminderCount){
        for(const reminderRef of snapshot.docs){
          let reminderDoc = reminderRef.data();
          reminderDoc.id = reminderRef.id;
          reminders.push(reminderDoc);
 
          let customerByIDList = this.state.customersByID;
          let customer = customerByIDList[reminderDoc.customerID];
          if(customer){
            if(!customer.reminderObjects){
              customer.reminderObjects = [];
            }
            let found = false;
            for(let i = 0; i < customer.reminderObjects.length; i++){
              if(customer.reminderObjects[i].id === reminderDoc.id){
                found = true;
                customer.reminderObjects[i] = reminderDoc;
              }
            }
            if(!found){
              customer.reminderObjects.push(reminderDoc);  
            }
          }
        }
        this.setState({reminders: reminders});
      }
    //});
  }

  getRemindersForCustomer = async (customerID) => {
    const query = firestore.collection('changeSamuiReminders').where('customerID', '==', customerID);
    let reminders = [];
    let snapshot = await query.get();

    var reminderCount = snapshot.size;
    if(reminderCount){
      for(const reminderRef of snapshot.docs){
        let reminderDoc = reminderRef.data();
        reminderDoc.id = reminderRef.id;
        reminders.push(reminderDoc);
      }
    }
    return reminders;
  }

  getCustomers = async() => {

    console.log('Getting customers...');
    var that = this;
    //var query = firestore.collection('changeSamuiCustomers').orderBy("lastname", "asc");
    var query = firestore.collection('changeSamuiCustomers').orderBy("dateCreated", "desc");
    var customers = [];
    var customersByID = [];
    var customersByEmail = [];
    var customersByPhone = [];

    let snapshot = await query.get();
    var customerCount = snapshot.size;
    if(customerCount){
      for(const customerDoc of snapshot.docs) {
        var customerObject = customerDoc.data();
        //console.log('Customer Object:', customerObject);

        /*
        if(customerObject.reminders){
          customerObject.reminderObjects = [];
          for(const reminder of customerObject.reminders) {
            let reminderRef = await reminder.get();
            let reminderObject = reminderRef.data();
            reminderObject.id = reminderRef.id;
            customerObject.reminderObjects.push(reminderObject);
          } 
          console.log('Customer with Reminders: ', customerObject);
        }
        */
        //customerObject.reminderObjects = await this.getRemindersForCustomer(customerDoc.id);

        customerObject.id = customerDoc.id;
        customers.push(customerObject);
        customersByID[customerObject.id] = customerObject;
        if(customerObject.email){
          customersByEmail[customerObject.email] = customerObject;            
        }
        if(customerObject.phone){
          customersByPhone[customerObject.phone] = customerObject;            
        }
      };
    } else {
      console.log('Snapshot is empty...')  
    }
    console.log('Retrieved and sorted customers...');
    that.setState({customers: customers, customersByID: customersByID, customersByEmail: customersByEmail, customersByPhone: customersByPhone});
  }  

  sortCustomersByDateCreated = (customerA, customerB) => {
    return(customerB.dateCreated - customerA.dateCreated);
  }

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } 

  getFormattedDate = (fireBaseTimeStamp ) => {
    var jsDate = fireBaseTimeStamp.toDate();
    
    var momentDate = moment(jsDate);
    var formattedDate = momentDate.format('DD-MMMM-YYYY HH:mm');
    return(formattedDate);

  }

  getFormattedDateOnly = (fireBaseTimeStamp ) => {
    //console.log(fireBaseTimeStamp, fireBaseTimeStamp.constructor.name);
    try{

      var jsDate = fireBaseTimeStamp;

      if(fireBaseTimeStamp.constructor.name !== 'Date'){
        jsDate = fireBaseTimeStamp.toDate();
        //console.log(jsDate);
      }
    
      var momentDate = moment(jsDate);
      //console.log(momentDate);
      var formattedDate = momentDate.format('DD-MMMM-YYYY');
      //console.log(formattedDate);
      return(formattedDate);
    } catch (error) {
      console.error(error);
    }
    return(null);
  }

  handleChange = (event) => {
    console.log('Handle Change for Event: ', event.target.name, event.target.value);
    //var customer = this.state.customer;
    //customer[event.target.id.trim()] = event.target.value;
    //this.setState({customer: customer});
  }

  handlePageClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id)
    });
  }

  handlePageLimitClick = (event) => {
    console.log('Page Limit Changed: ', event.target.value);
    this.setState({ pageSize: Number(event.target.value)  });
  }

  handleSiteLimitClick = (event) => {
    console.log('Site Limit Changed: ', event.target.value);
    localStorage.setItem('FilterCustomerSite', event.target.value);
    this.setState({ siteName: event.target.value  });
  }

  handleSearch = (event) => {
    const searchString = event.target.value.toLowerCase();
    if(searchString.length < 3){
      console.log("Search skipping...")
      this.setState({searchResults: []});
      return;
    }
    console.log('Search: ', searchString);
    const resultCustomers = [];
    const allCustomers = this.state.customers;
    for(let customer of allCustomers) {
      if(customer.firstname.toLowerCase().includes(searchString)){
        console.log('Match on First Name');
        resultCustomers[customer.id] = customer;
      }
      if(customer.lastname.toLowerCase().includes(searchString)){
        console.log('Match on Last Name');
        resultCustomers[customer.id] = customer;
      }
      if(customer.id.toLowerCase() === searchString){
        console.log('Match on ID');
        resultCustomers[customer.id] = customer;
      }
      if(customer.email.toLowerCase().includes(searchString)){
        console.log('Match on Email');
        resultCustomers[customer.id] = customer;
      }
      if(customer.phone.toLowerCase().includes(searchString)){
        console.log('Match on Phone');
        resultCustomers[customer.id] = customer;
      }
      if(customer.notes.toLowerCase().includes(searchString)){
        console.log('Match on Notes');
        resultCustomers[customer.id] = customer;
      }
    }
    const searchResults = Object.values(resultCustomers);
    console.log('Search Results: ', searchResults);
    this.setState({searchResults: searchResults});

  }

  copyCustomer = (customer) => {
    console.log("Copy Customer ID: ", customer.id);

    let clipboardText = 'Customer: ' + customer.firstname + ' ' + customer.lastname;
    clipboardText += '\nType:';
    if (customer.buyer) {
      clipboardText += ' Buyer';
    }
    if (customer.seller) {
      clipboardText += ' Seller';
    }
    if (customer.renter) {
      clipboardText += ' Renter';
    }
    if(customer.email){
      clipboardText += '\nEmail: ' + customer.email;
    }
    if(customer.phone){
      clipboardText += '\nPhone: ' + customer.phone;
    }
    if(customer.budget){
      clipboardText += '\nBudget: ' + customer.budget;
    }
    if(customer.type && customer.type.length > 0){
      clipboardText += '\nType(s): ' + customer.type.join(', ');
    }
    if(customer.bedrooms){
      clipboardText += '\nBedrooms: ' + customer.bedrooms;
    }
    if(customer.bathrooms){
      clipboardText += '\nBathrooms: ' + customer.bathrooms;
    }

    if(customer.notes){
      clipboardText += '\nNOTES: ' + customer.notes;
    }

    console.log(clipboardText);
    navigator.clipboard.writeText(clipboardText);    

    //var liveToast = document.getElementById("liveToast");
    //liveToast.toast("show");
  }

  createCustomerVCARD = (customer) => {

    console.log("vCard for Customer: ", customer);

    let notesText = 'Customer - ' + customer.firstname.trim() + ' ' + customer.lastname.trim();
    notesText += '; Type -';
    if (customer.buyer) {
      notesText += ' Buyer';
    }
    if (customer.seller) {
      notesText += ' Seller';
    }
    if (customer.renter) {
      notesText += ' Renter';
    }
    if(customer.budget){
      notesText += '; Budget - ' + customer.budget;
    }
    if(customer.type && customer.type.length > 0){
      notesText += '; Type(s) - ' + customer.type.join(', ');
    }
    if(customer.bedrooms){
      notesText += '; Bedrooms - ' + customer.bedrooms;
    }
    if(customer.bathrooms){
      notesText += '; Bathrooms - ' + customer.bathrooms;
    }

    if(customer.notes){
      notesText += '; More Info - ' + customer.notes;
    }

    console.log(notesText);

    //PHOTO;JPEG:http://commons.wikimedia.org/wiki/File:Erika_Mustermann_2010.jpg
    
    let functionURL = 'https://us-central1-change-real-estate.cloudfunctions.net/createVCard?fn=' + encodeURIComponent(customer.firstname.trim()) + '&ln=' + encodeURIComponent(customer.lastname.trim());
    if(customer.email){
      functionURL += '&email=' + encodeURIComponent(customer.email);
    } 
    if(customer.photo){
      functionURL += '&photo=' + encodeURIComponent(customer.photo);
    } 
    if(customer.phone){
      functionURL += '&tel=' + encodeURIComponent(customer.phone);
    } 
    functionURL += '&notes=' + encodeURIComponent(notesText);

    window.open(functionURL, "_blank");

  }

  deleteCustomer = (customer) => {
    if(confirm("Are you sure you want to delete this customer record?")){
      console.log('Deleting Customer!');
      firestore.collection('changeSamuiCustomers').doc(customer.id).delete()
      .then(() => {
        console.log('Customer deleted!');
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error removing customer: ", error);
      });
    }
  }
  
  editCustomer = (customer) => {
    console.log("Edit Customer: ", customer.firstname, customer.lastname, customer);
    //this.setState({editCustomerID: customer.id});
    window.location.assign("/customer/" + customer.id);

  }

  addCustomerReminder = (customer) => {
    console.log('Add reminder clicked for customer: ', customer);
    var addReminder = new Modal(document.getElementById('addReminderModal'), {});
    document.getElementById('reminderCustomerID').value = customer.id;
    document.getElementById('reminderID').value = '';
    document.getElementById('reminderOffice').value = customer.office;
    document.getElementById('addReminderLabel').textContent = "Add Reminder for " + customer.firstname + " " + customer.lastname;
    document.getElementById('reminderCustomerName').value = customer.firstname + " " + customer.lastname;
    document.getElementById('reminderMessage').value = '';
    document.getElementById('addEditReminderSaveButton').textContent = "Add Reminder";
    addReminder.show();

  }

  editCustomerReminder = (customer, reminder) => {
    console.log('Edit reminder clicked: ', customer, reminder);

    var addReminder = new Modal(document.getElementById('addReminderModal'), {});
    document.getElementById('reminderCustomerID').value = customer.id;
    document.getElementById('reminderID').value = reminder.id;
    document.getElementById('reminderOffice').value = customer.office;
    document.getElementById('addReminderLabel').textContent = "Edit Reminder for " + customer.firstname + " " + customer.lastname;
    document.getElementById('reminderCustomerName').value = customer.firstname + " " + customer.lastname;
    document.getElementById('reminderMessage').value = reminder.reminderMessage;
    document.getElementById('addEditReminderSaveButton').textContent = "Save Reminder";

    this.setState({reminderDate: reminder.reminderDate});
    addReminder.show();

  }


  handleReminderSave = (event) => {
    let customerID = document.getElementById('reminderCustomerID').value;
    let reminderIDElement = document.getElementById('reminderID');
    let reminderID = '';
    if(reminderIDElement){
      reminderID = reminderIDElement.value;
      console.log("Updating Existing Reminder...");
      console.log('Handle Reminder Save For Customer ID: ', customerID);
      console.log('Handle Reminder Save For Reminder ID: ', reminderID);
    } else {
      console.log("Adding New Reminder...");
      console.log('Handle Add Reminder For Customer ID: ', customerID);
    }
    console.log('New Reminder Due Date: ', moment(this.state.reminderDate).format('YYYY-MM-DD'));
    console.log('New Reminder Text: ', document.getElementById('reminderMessage').value);
    console.log('New Reminder Assign To: ', document.getElementById('assignTo').value);

    const userList = this.state.userList;
    let assignedUser = null;
    for(const user of userList){
      if(user.email === document.getElementById('assignTo').value){
        assignedUser = user;
      }
    }
    
    var dueDate = moment(this.state.reminderDate).toDate();
    //BKK Time is UTC+07:00
    //var dueDateUTC = new Date(Date.UTC(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate(), 7, 0, 0, 0));
    //Save new reminder...

    if(reminderID){

      var reminder = {
        id: reminderID,
        customerID:  customerID,
        customerOffice:  document.getElementById('reminderOffice').value,
        customerName: document.getElementById('reminderCustomerName').value,
        dueDate: dueDate,
        reminderMessage: document.getElementById('reminderMessage').value, // this.state.reminderMessage,
        assignToEmail: assignedUser.email,
        timezone: assignedUser.timezone,
        completed: false,
        dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: { name: this.state.user.displayName, email: this.state.user.email, uid: this.state.user.uid }
      }
      firestore.collection('changeSamuiReminders').doc(reminderID).update(reminder)
      .then(() => { 
        console.log("Reminder updated for Reminder ID: ", reminderID);
        let customerByIDList = this.state.customersByID;
        let customer = customerByIDList[customerID];
        if(customer){
          if(!customer.reminderObjects){
            customer.reminderObjects = [];
          }
          let found = false;
          for(let i = 0; i < customer.reminderObjects.length; i++){
            if(customer.reminderObjects[i].id === reminder.id){
              found = true;
              customer.reminderObjects[i] = reminder;
            }
          }
          if(!found){
            customer.reminderObjects.push(reminder);  
          }

          console.log('Reminder saved. Forcing State Change / Refresh');
          //this.getReminders();  
          this.setState({refreshCount: (this.state.refreshCount + 1)});
        }
      }).catch((error) => {
        console.error('Error writing Reminder: ', error);
      });

    } else {

      var reminder = {
        customerID:  customerID,
        customerOffice:  document.getElementById('reminderOffice').value,
        customerName: document.getElementById('reminderCustomerName').value,
        dueDate: dueDate,
        reminderMessage: document.getElementById('reminderMessage').value, // this.state.reminderMessage,
        assignToEmail: assignedUser.email,
        timezone: assignedUser.timezone,
        completed: false,
        dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: { name: this.state.user.displayName, email: this.state.user.email, uid: this.state.user.uid }
      }
      firestore.collection('changeSamuiReminders').add(reminder)
      .then((reminderRef) => { 
        console.log("Reminder written with Reminder ID: ", reminderRef.id);
        reminder.id = reminderRef.id;

        let customerByIDList = this.state.customersByID;
        let customer = customerByIDList[customerID];
        if(customer){
          if(!customer.reminderObjects){
            customer.reminderObjects = [];
          }
          let found = false;
          for(let i = 0; i < customer.reminderObjects.length; i++){
            if(customer.reminderObjects[i].id === reminder.id){
              found = true;
              customer.reminderObjects[i] = reminder;
            }
          }
          if(!found){
            customer.reminderObjects.push(reminder);  
          }

          console.log('Reminder saved. Forcing State Change / Refresh');
          //this.getReminders();  
          this.setState({refreshCount: (this.state.refreshCount + 1)});
        }

      }).catch((error) => {
        console.error('Error writing Reminder: ', error);
      });
    }

    //Hide modal...
    var addReminder = document.getElementById('addReminderModal')
    var addReminderModal = Modal.getInstance(addReminder) // Returns a Bootstrap modal instance
    addReminderModal.hide();
  }

  /*
  handleReminderChange = (event) => {
    var reminderMessage = event.target.value;
    this.setState({reminderMessage: reminderMessage});
  }
  */

  handleDateTimeChange = (reminderDate) => {
    this.setState({reminderDate: reminderDate});
  };  

  addReminder = (props) => {
    console.log('Adding Reminder Modal to DOM');
    console.log(props);

    return (
        <div className="modal fade" id="addReminderModal" aria-hidden="true">  
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="addReminderLabel"></h3>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div id="existing-internal-info"></div>
              <form>
                <input id="reminderCustomerID" type="hidden" />
                <input id="reminderID" type="hidden" />
                <input id="reminderOffice" type="hidden" />
                <input id="reminderCustomerName" type="hidden" />

                <div className="mb-3">
                  <label className="col-form-label">Date Due:</label>

                  <div style={{ display: 'block' }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label=""
                      id="reminderDate"
                      inputFormat="DD MMMM YYYY"
                      value={ this.state.reminderDate }
                      onChange={this.handleDateTimeChange}
                      renderInput={(params) => <TextField {...params} />}
                      disableMaskedInput={true}
                    />
                  </LocalizationProvider>
                  </div>
                </div>

                <div className="mb-3">
                  <label className="col-form-label">Assign To:</label>
                  <select className="form-select" aria-label="Assign To" id="assignTo" defaultValue={this.state.user.email} >
                      {   this.state.userList.map(user => (
                          <option key={user.id} value={user.email}>{user.displayName + " - " + user.email}</option>

                      ))}
                  </select>

                </div>


                <div className="mb-3">
                  <label className="col-form-label">Reminder Message:</label>
                  <textarea className="form-control" id="reminderMessage" rows="3" placeholder="Schedule appointment to see..." ></textarea>
                </div>


              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={this.handleReminderSave} id="addEditReminderSaveButton">Add Reminder</button>
            </div>
            </div>
          </div>
        </div>
    );
  
  }
  
  editCustomerCreator = (customer) => {
    console.log("Edit Customer Creator: ", customer.firstname, customer.lastname, customer.id);
    //this.setState({editCustomerID: customer.id});
    //window.location.assign("/customer/" + customer.id);

    var editEnteredByModal = new Modal(document.getElementById('editEnteredByModal'), {});
    var createdByID = customer.createdBy.uid;
    console.log('Customer Created By: ', createdByID);
    document.getElementById('enteredBy').value = createdByID;
    document.getElementById('enteredByCustomerID').value = customer.id;
    editEnteredByModal.show();
  }

  handleEnteredByChange = async(event) => {
    let customerID = document.getElementById('enteredByCustomerID').value;
    let newEnteredBy = document.getElementById('enteredBy').value;
    console.log('Handle Change Entered By For Customer ID: ', customerID);
    console.log('New Entered By User ID: ', newEnteredBy)

    const userList = this.state.userList;
    let newEnteredByUser = null;
    for(const user of userList){
      //console.log(user);
      if(user.id === newEnteredBy){
        newEnteredByUser = user;
      }
    }

    if(newEnteredByUser){
      let customerRef =  firestore.collection('changeSamuiCustomers').doc(customerID);
      //console.log('Updating Customer...')
      customerRef.update({
        createdBy : { name: newEnteredByUser.displayName, email: newEnteredByUser.email, uid: newEnteredByUser.id }
      });
      console.log('Entered By Changed. Forcing State Change / Refresh');
      //this.getReminders();
      this.setState({refreshCount: (this.state.refreshCount + 1)});
    } else {
      console.log('No update...');
    }
    
    //Hide modal...
    var enteredBy = document.getElementById('editEnteredByModal')
    var enteredByModal = Modal.getInstance(enteredBy) // Returns a Bootstrap modal instance
    enteredByModal.hide();

  }

  editEnteredBy = (props) => {
    console.log('Edit Entered By Props: ', props);
    return (
      <div className="modal fade" id="editEnteredByModal" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="editEnteredByLabel">Edit</h3>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div id="existing-internal-info"></div>
              <form>
                <input id="enteredByCustomerID" type="hidden" />
                <div className="mb-3">
                  <label className="col-form-label">Customer Entered By:</label>
                  <select className="form-select" aria-label="Entered By" id="enteredBy" >
                      {   this.state.userList.map(user => (
                          <option key={user.id} value={user.id}>{user.displayName + " - " + user.email}</option>

                      ))}
                  </select>

                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={this.handleEnteredByChange}>Update Entered By</button>
            </div>
            </div>
          </div>
        </div>
    );
  
  }

  toggleReminderComplete = (reminderID) => {
    console.log('Toggle Reminder Completed for Reminder ID: ', reminderID);
    const reminderRef = firestore.collection('changeSamuiReminders').doc(reminderID);
    reminderRef.update({
      completed: document.getElementById('isCompleted-' + reminderID).checked
    }).then(() => {
      console.log('Reminder Updated. Forcing State Change / Refresh');
      //this.getCustomers();      
    }).catch((error) => {
      console.error('Error Updating Reminder: ', error);
    });
  }

  deleteReminder = (reminderID, customerID) => {
    if(confirm("Are you sure you want to delete this reminder?")){    
      console.log('Delete Reminder ID: ', reminderID);
      const reminderRef = firestore.collection('changeSamuiReminders').doc(reminderID);
      reminderRef.delete().then(() => {
        console.log('Reminder Deleted. Forcing State Change / Refresh');
        document.getElementById(reminderID).remove();
        
        let newCount = 0;
        let reminderCountID = customerID + '-ReminderCount';
        let reminderCountElement = document.getElementById(reminderCountID).textContent;
        console.log(reminderCountElement);
        let reminderCount = parseInt(reminderCountElement)
        if(isNaN(reminderCount)){
          console.log("Error Parsing Count...");
        }else{
          newCount = reminderCount - 1;
          document.getElementById(reminderCountID).textContent = newCount.toString();
        }

      }).catch((error) => {
        console.error('Error Deleting Reminder: ', error);
      });
    }
  }

  customerList = (customers) => {

    var avatarURL = "https://www.gravatar.com/avatar/";
    //var avatarURL = "https://www.libravatar.org/avatar/";

    
    return (

      <ul className="list-unstyled mb-5">

      {customers.map((customer, index) => (
      <li key={customer.id} className="card card-bordered shadow-none mb-3" >
        <div className="card-body">
          <div className="row">
  
            <div className="col-6 col-md mb-3 mb-md-0">
              <small className="card-subtitle mb-0">Office</small>
              <small className="text-dark fw-semi-bold"> 
              <span className="">{ customer.office === 'huahin' ? 'Hua Hin' : 'Samui' }</span>
              </small>
            </div>


            <div className="col-6 col-md mb-3 mb-md-0">
              <small className="card-subtitle mb-0">Type</small>
              <small className="text-dark fw-semi-bold">
              { customer.buyer && 
              <span className="me-2">Buyer</span>
              }
              { customer.seller && 
              <span>Seller</span>
              }
              { customer.renter && 
              <span>Renter</span>
              }
              </small>
            </div>

            <div className="col-6 col-md mb-3 mb-md-0">
              <small className="card-subtitle mb-0">Phone</small>
              <small className="text-dark fw-semi-bold"><a href={"tel:" + customer.phone}>{customer.phone}</a></small>
            </div>

            <div className="col-6 col-md">
              <small className="card-subtitle mb-0">Email</small>
              <small className="text-dark fw-semi-bold"><a href={"mailto:" + customer.email}>{customer.email}</a></small>
            </div>

            <div className="col-6 col-md mb-3 mb-md-0">
              <small className="card-subtitle mb-0">ID</small>
              <small className="text-dark fw-semi-bold">{customer.id}</small>
            </div>

          </div>

          <hr />

          <div className="row">
            <div className="col-md-3 text-center">
              { customer.photo ?
              <img src={customer.photo} alt={ customer.firstname  + ' ' + customer.lastname} style={{ maxHeight: 200, maxWidth: 200 }} className='rounded' />
              :
              <img src={avatarURL + md5(customer.email) + "?d=mm&s=200"} alt=""/>
              }
            </div>
            <div className="col-md-6 mb-3">
              <h5>{ customer.firstname } {customer.lastname}</h5>

              
              { customer.dateCreated &&
                <p className="mb-0"><b>Created</b>: { this.getFormattedDate(customer.dateCreated) }</p>
              }

              { customer.buyer &&
                <p className="mb-0"><b>Type</b>: Buyer</p>
              }
              { customer.seller &&
                <p className="mb-0"><b>Type</b>: Seller</p>
              }
              { customer.timeline &&
                <p className="mb-0"><b>Timeline</b>: {customer.timeline}</p>
              }
              { customer.budget &&
                <p className="mb-0"><b>Budget</b>: {customer.budget}</p>
              }
              { (customer.type && customer.type.length > 0) &&
                <p className="mb-0"><b>Type(s)</b>: {customer.type.join(', ')}</p>
              }
              { customer.bedrooms &&
                <p className="mb-0"><b>Bedrooms</b>: {customer.bedrooms}</p>
              }
              { customer.bathrooms &&
                <p className="mb-0"><b>Bathrooms</b>: {customer.bathrooms}</p>
              }
              { customer.sizehome &&
                <p className="mb-0"><b>Home Size</b>: {customer.sizehome}</p>
              }
              { customer.sizeland &&
                <p className="mb-0"><b>Land Size</b>: {customer.sizeland}</p>
              }
              { (customer.features && customer.features.length > 0) &&
                <p className="mb-0"><b>Features(s)</b>: {customer.features.join(', ')}</p>
              }
              { (customer.style && customer.style.length > 0) &&
                <p className="mb-0"><b>Style(s)</b>: {customer.style.join(', ')}</p>
              }
            </div>

            <div className="col-md-3">
              <div className="d-grid gap-2">
                <a className="btn btn-primary btn-sm" onClick={ this.editCustomer.bind(this, customer) }>
                  <i className="bi bi-pen small me-2"></i> Edit Customer
                </a>

                <a className="btn btn-secondary btn-sm" onClick={ this.addCustomerReminder.bind(this, customer) }>
                  <i className="bi bi-alarm"></i> Add Reminder
                </a>              

                <a className="btn btn-white btn-sm" onClick={ this.copyCustomer.bind(this, customer) }>
                  <i className="bi bi-clipboard-plus"></i> Copy to Clipboard
                </a>
                <a className="btn btn-white btn-sm" onClick={ this.createCustomerVCARD.bind(this, customer) }>
                  <i className="bi bi-clipboard-plus"></i> Add to Address Book
                </a>              



                { this.state.user.superadmin === true &&
                <a className="btn btn-white btn-sm" onClick={ this.deleteCustomer.bind(this, customer) }>
                  <i className="bi bi-trash"></i> Delete
                </a>              
                }


                <p className="lh-1 entered-by">
                { (customer.createdBy && customer.createdBy.name) &&
                <span>Entered By: {customer.createdBy.name}</span>
                }
                { (this.state.user.superadmin && this.state.user.superadmin === true) && 
                <>&nbsp;<a className="btn btn-sm btn-edit-entered-by" onClick={ this.editCustomerCreator.bind(this, customer) }><i className="bi bi-pencil-square"></i></a></>
                }
                </p>

                  <div className="my-1 text-center">
                      { customer.rating > 0 ? (
                        <img src="/assets/svg/illustrations/star.svg" alt="Review rating" width="24" />
                      ) : (
                          <img src="/assets/svg/illustrations/star-muted.svg" alt="Review rating" width="24" />
                      )}
                      { customer.rating > 1 ? (
                        <img src="/assets/svg/illustrations/star.svg" alt="Review rating" width="24" />
                      ) : (
                          <img src="/assets/svg/illustrations/star-muted.svg" alt="Review rating" width="24" />
                      )}
                      { customer.rating > 2 ? (
                        <img src="/assets/svg/illustrations/star.svg" alt="Review rating" width="24" />
                      ) : (
                          <img src="/assets/svg/illustrations/star-muted.svg" alt="Review rating" width="24" />
                      )}
                      { customer.rating > 3 ? (
                        <img src="/assets/svg/illustrations/star.svg" alt="Review rating" width="24" />
                      ) : (
                          <img src="/assets/svg/illustrations/star-muted.svg" alt="Review rating" width="24" />
                      )}
                      { customer.rating > 4 ? (
                        <img src="/assets/svg/illustrations/star.svg" alt="Review rating" width="24" />
                      ) : (
                          <img src="/assets/svg/illustrations/star-muted.svg" alt="Review rating" width="24" />
                      )}

                  </div>

              </div>
          </div>

          <hr />

          <div className="row">
              { customer.notes &&
                <p className="my-2"><b>Notes</b>: { customer.notes }</p>
              }
          </div>

          <div className="row">
            { (customer.reminderObjects && customer.reminderObjects.length > 0)  &&

              <>
              <h4 className="h5 mt-4 mb-2 position-relative">Reminders&nbsp;
               <span id={customer.id + '-ReminderCount'} className="badge rounded-pill bg-danger position-absolute top-0 ms-2 translate-middle">{customer.reminderObjects.length}</span>
              </h4>
                <div className="row fw-bold border-bottom pb-2 small">
                  <div className="col-sm-2">Actions</div>
                  <div className="col-sm-2">Due</div>
                  <div className="col-sm-3">Assigned</div>
                  <div className="col-sm-5">Message</div>
                </div>
                {customer.reminderObjects.map(reminder => (
                  <div id={ reminder.id } key={ reminder.id } className={(reminder.completed && reminder.completed === true) ? 'row border-bottom py-2 small text-decoration-line-through' : 'row border-bottom py-2 small'}>
                    <div className="col-sm-2">
                      <input type="checkbox" id={ 'isCompleted-' + reminder.id } checked={reminder.completed && reminder.completed === true} onChange={ this.toggleReminderComplete.bind(this, reminder.id) } />
                      <button className="btn btn-sm btn-reminder-actions" onClick={this.editCustomerReminder.bind(this, customer, reminder)}><i className="bi bi-pencil"></i></button>
                      <button className="btn btn-sm btn-reminder-actions" onClick={this.deleteReminder.bind(this, reminder.id, customer.id)}><i className="bi bi-trash"></i></button>
                    </div>
                    <div className="col-sm-2">{ this.getFormattedDateOnly(reminder.dueDate) }</div>
                    <div className="col-sm-3">{ reminder.assignToEmail }</div>
                    <div className="col-sm-5">{ reminder.reminderMessage }</div>
                  </div>
                ))}
              </>
            }

          </div>


        </div>
        </div>

      </li>

      ))}
    </ul>


    );


  }
  
  pageNavigation = (currentPage, pageNumbers) => {

    const renderPageNumbers = pageNumbers.map(number => {

      //console.log("Page Number: ", number, " Current Page: ", currentPage);
      if(number === currentPage){
        //console.log('Setting Page ', number, ' as active...');
      return (
          <li className="d-none d-lg-block page-item active" key={number.toString()}>
          <a className="page-link" 
          key={number.toString()}
          id={number}
          onClick={this.handlePageClick} 
          aria-label={number}>{number}</a>
        </li>
      );
      }else{
        return (
          <li className="d-none d-lg-block page-item" key={number.toString()}>
          <a className="page-link" 
          key={number.toString()}
          id={number}
          onClick={this.handlePageClick} 
          aria-label={number}>{number}</a>
        </li>
      );
      }
    });  

    return(
      <nav aria-label="Page navigation">
      <ul className="pagination pagination-sm justify-content-center">
        <li className={ currentPage === 1 ? "page-item disabled" : "page-item"} key={(currentPage - 1).toString()}>

          <a className="page-link" 
            key={(currentPage - 1).toString()}
            id={currentPage - 1}
            onClick={this.handlePageClick} 
            aria-label="Previous">Prev</a>

        </li>
        {renderPageNumbers}
        <li className={ currentPage === (pageNumbers.length) ? "page-item disabled" : "page-item"} key={(currentPage + 1).toString()}>
          
          <a className="page-link" 
            key={(currentPage + 1).toString()}
            id={currentPage + 1}
            onClick={this.handlePageClick} 
            aria-label="Next">Next</a>


        </li>
      </ul>
    </nav>

    );
  }

  limitBySite = (displayCustomers, siteName) => {
    console.log('Limit by Site: ', siteName);
    let filteredCustomers = [];
    displayCustomers.forEach((customer) => {
      if(customer.office && customer.office === siteName){
        filteredCustomers.push(customer);
      }
      if(!customer.office && siteName === 'samui'){
        filteredCustomers.push(customer);
      }
    });
    return(filteredCustomers);
  }  

  render() {

    const { user, siteName, pageSize, currentPage, customerListLoaded, editCustomerID } = this.state;

    if(editCustomerID){
      return <Navigate to={'/customer/' + editCustomerID} state={this.state} />
    }


    const indexOfLast = currentPage * pageSize;
    const indexOfFirst = indexOfLast - pageSize;

    let displayCustomers = [];

    if(this.state.searchResults.length > 0){

      displayCustomers = this.state.searchResults; //.sort(this.sortCustomersByDateCreated);
  
    } else {

      displayCustomers = this.state.customers; //.sort(this.sortCustomersByDateCreated);
  
    }

    if(siteName !== 'all'){
      displayCustomers = this.limitBySite(displayCustomers, siteName);
    }    


    const displayCount = displayCustomers.length;

    const customers = displayCustomers.slice(indexOfFirst, indexOfLast);
    //console.log('Render Listings: ', listings, ' Current Tab: ', currentTab);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(displayCustomers.length / pageSize); i++) {
      pageNumbers.push(i);
    }

    //const authorizedEmails = ['michael@changevegas.com', 'thida@changesamui.com', 'yuwathida@changevegas.com', 'yuwathida.style@gmail.com']
    if(!user || user.admin === false){
      return (
        <Unauthorized />
      );
    }

    // Need to change this to no results... 
    if(customers.length === 0){
    //if(customerListLoaded === false){
        return(
        <>
        <Header user={user} />
        <BreadcrumbArea currentPage={"Loading..."} />
      
        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
        <div className="row">
          <div className="col-lg-12">
  
            <div className="card">
  
              <div className="card-header border-bottom">
                <form className="input-group input-group-merge">
                  <div className="input-group-prepend input-group-text">
                    <i className="bi-search"></i>
                  </div>
                  <input type="search" className="form-control" placeholder="Search customers" aria-label="Search customers" onChange={ this.handleSearch } />
                </form>
              </div>
            </div>
          </div>
          </div>
        <div className="container">
          <div className=" min-height-400 d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status" style={{marginTop: 'auto', marginBottom: 'auto'}}>
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        </div>
        <Footer />
        </>
      )
    } else {
      //console.log(customers);
    }
    return(
      <>
      <Header user={user} />
      <BreadcrumbArea currentPage={"Customers"} user={user} />
      <span className="d-none">{this.state.refreshCount}</span>
      
      <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
      <div className="row">
        <div className="col-lg-12">

          <div className="card">

            <div className="card-header border-bottom">
              <form className="input-group input-group-merge">
                <div className="input-group-prepend input-group-text">
                  <i className="bi-search"></i>
                </div>
                <input type="search" className="form-control" placeholder="Search customers" aria-label="Search customers" onChange={ this.handleSearch } />
              </form>
            </div>



            <div className="card-body">

              <div className="js-nav-scroller hs-nav-scroller-horizontal">
                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                  <a className="hs-nav-scroller-arrow-link" href="#">
                    <i className="bi-chevron-left"></i>
                  </a>
                </span>

                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                  <a className="hs-nav-scroller-arrow-link" href="#">
                    <i className="bi-chevron-right"></i>
                  </a>
                </span>

                { (this.state.searchResults && this.state.searchResults.length > 0) ? (
                  <h4>Search Results ({ displayCount })</h4>
                ):(
                  <h4>Customers ({ displayCount })</h4>
                )
                }
              </div>


              <div className="d-sm-flex align-items-sm-center mb-5">

                <div className="mb-2 mb-sm-0 me-3">
                  <span><strong className="text-dark">Site</strong></span>
                </div>

                <div className="tom-select-custom">
                  <select className="js-select form-select form-select-sm" onChange={ this.handleSiteLimitClick } value={ siteName }>
                    <option value="all">All</option>
                    <option value="samui">Samui</option>
                    <option value="huahin">Hua Hin</option>
                  </select>
                </div>
                &nbsp;
                <div className="mb-2 mb-sm-0 me-3">
                  <span><strong className="text-dark">Show</strong></span>
                </div>

                <div className="tom-select-custom">
                  <select className="js-select form-select form-select-sm" onChange={ this.handlePageLimitClick } value={ pageSize }>
                    <option value="10">10 customers per page</option>
                    <option value="20">20 customers per page</option>
                    <option value="50">50 customers per page</option>
                    <option value="100">100 customers per page</option>
                  </select>
                </div>

                <a type="button" className="btn btn-primary ms-auto" href="/customer/add"><i className="bi bi-person-plus-fill"></i> Add Customer</a>

              </div>



              <div className="tab-content" id="listingTabContent">
                <div className="tab-pane fade show active" id="saleTab" role="tabpanel" aria-labelledby="sale-tab">

                { this.pageNavigation(currentPage, pageNumbers) }
                { this.customerList(customers) }
                { this.pageNavigation(currentPage, pageNumbers) }

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>
    
    { this.editEnteredBy(this.props) }
    { this.addReminder(this.props) }
    <Footer />

    </>


    ); 

  }

  componentDidMount(){
    if(this.state.user){
      console.log('Customer List Mounted. User: ', this.state.user);
      this.getAuthorizedUsers();
      this.getCustomers();
      this.getReminders();
    }  
  }
}

export default Customers;
