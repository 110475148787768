import React, { useRef } from "react";
// import useDrag and useDrop hooks from react-dnd
import { useDrag, useDrop } from "react-dnd";

const type = "Image"; // Need to pass which type element can be draggable, its a simple string or Symbol. This is like an Unique ID so that the library know what type of element is dragged or dropped on.

const Image = ({ image, index, moveImage, deleteImage, placeImage, activePDFTarget }) => {
    const ref = useRef(null); // Initialize the reference

    
    // useDrop hook is responsible for handling whether any item gets hovered or dropped on the element
    const [, drop] = useDrop({

        // accept receives a definition of what must be the type of the dragged item to be droppable
        accept: type,
        // This method is called when we hover over an element while dragging
        drop(item) { // item is the dragged element

            console.log('Drop: ', item);

            if (!ref.current) {
                console.log('No current ref');
                return;
            }
            const dragIndex = item.id;
            // current element where the dragged element is hovered on
            const hoverIndex = index;

            //console.log('Item: ', item);
            //console.log('Hover Index: ', hoverIndex);

            // If the dragged element is hovered in the same place, then do nothing
            if (dragIndex === hoverIndex) { 
                console.log('Drag and Hover are the same Index');
                return;
            }
            
            // If it is dragged around other elements, then move the image and set the state with position changes
            //console.log('Drag Drop - Drag Index: ', dragIndex, ' Hover Index: ', hoverIndex);
            moveImage(dragIndex, hoverIndex);
            
            /*
                Update the index for dragged item directly to avoid flickering
                when the image was half dragged into the next
            */
            item.index = hoverIndex;
        },
        hover(item){
            console.log('Drag Item: ', item.id, ' Target Item: ', index);
        }
    });
    
    // useDrag will be responsible for making an element draggable. It also expose, isDragging method to add any styles while dragging
    const [{ isDragging }, drag] = useDrag(() => ({

        // what type of item this to determine if a drop target accepts it
        type: type,
        // data of the item to be available to the drop methods
        item: { id: index },
        // method to collect additional data for drop handling like whether is currently being dragged
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging(),
            };
        },
    }));



     
    //Initialize drag and drop into the element using its reference.
    //Here we initialize both drag and drop on the same element (i.e., Image component)

    drag(drop(ref));

    // Add the reference to the element

//<div ref={ref} style={{ opacity: isDragging ? 0 : 1 }} className="file-item">
//<img src={image} className="img-fluid file-img" />
//</div>

    return (

        <div ref={ref} style={{ opacity: isDragging ? 0 : 1 }} className="col file-item position-relative" >
            {false && index < 3 &&
            <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-danger">{index + 1}
                <span className="visually-hidden">PDF Image Position</span>
            </span>
            }
            <div className="border rounded p-1">
                { activePDFTarget > 0 &&
                <button type="button" className="btn btn-outline-success btn-sm btn-place-image" onClick={(event) => { placeImage(event, index);}}><i className="bi bi-arrow-up-circle-fill"></i></button>
                }
                <button type="button" className="btn btn-outline-secondary btn-sm float-end mb-1" onClick={(event) => { deleteImage(event, index);}}><i className="bi bi-trash-fill"></i></button>
                <img src={image} className="img-fluid file-img" alt={index} />
            </div>
        </div>


    );
};

const ImageList = ({ images, moveImage, deleteImage, placeImage, activePDFTarget }) => {

    // render each image by calling Image component
    const renderImage = (image, index) => {
      //console.log("Render Image: ", image, ' Index: ', index)
      return (
        <Image key={index} image={image} index={index} moveImage={moveImage} deleteImage={deleteImage} placeImage={placeImage} activePDFTarget={activePDFTarget} />
      );
    };
  
    // Return the list of files
    return images.map(renderImage);
  };
  
  export default ImageList;