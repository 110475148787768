import './Listings.css';
import React from 'react';
import { firestore, storage } from './firebase';
import BreadcrumbArea from './BreadcrumbArea';
import Header from './Header';
import Footer from './Footer';
import Unauthorized from './Unauthorized';
import ReactImageGrid from "@cordelia273/react-image-grid";
import moment from 'moment';


class Listings extends React.Component{

  constructor(props){
    super(props);

    let siteFilter = localStorage.getItem('FilterListingSite');
    let typeFilter = localStorage.getItem('FilterListingType');
    let kindFilter = localStorage.getItem('FilterListingKind');
    let priceLimit = localStorage.getItem('PriceLimit');
    let pageLimit = localStorage.getItem('PageLimit');
    let visibility = localStorage.getItem('ListingVisibility');
    let sortOrder = localStorage.getItem('ListingSortOrder');
    
    let defaultSite = 'all';
    if(window.location.hostname === 'admin.changehuahin.com'){
      defaultSite = 'changehuahin';
    }
    if(window.location.hostname === 'admin.changesamui.com'){
      defaultSite = 'changesamui';
    }

    this.state = {
      user: props.user,
      loaded: false,
      listings: [],
      listingsByID: [],
      rentals: [],
      forSale: [],
      hidden: [],
      searchResults: [],
      currentPage: 1,
      pageSize: pageLimit? pageLimit : 20,
      priceLimit: priceLimit? priceLimit: -1,
      siteName: siteFilter ? siteFilter : defaultSite,
      listingType: typeFilter ? typeFilter : 'all',
      listingKind: kindFilter ? kindFilter: 'all',
      visibility: visibility ? visibility: 'visible',
      sortOrder: sortOrder ? sortOrder: 'date'
    }
  }

  getListings = () => {

    console.log('Getting listings...');
    var that = this;
    //city = samui does not work 🤷🏻‍♂️
    //var query = firestore.collection('changeSamuiListings').where("city" == "samui").where("is_sold", "!=", true).orderBy("city", "asc").orderBy("is_sold", "asc").orderBy("price", "desc");
    //var query = firestore.collection('changeSamuiListings').where("is_sold", "!=", true).orderBy("is_sold", "asc").orderBy("id", "asc");
    var query = firestore.collection('changeSamuiListings2022');
    var listings = [];
    var listingsByID = [];
    var forSale = [];
    var forRent = [];
    var hidden = [];

    query.get().then((snapshot) => {
      if(snapshot.size){
        snapshot.forEach(function(listingDoc){
          var listingObject = listingDoc.data();
          listingObject.id = listingDoc.id;
          //Don't really care about listings outside Samui...
          //if(listingObject.city === 'samui'){
            listings.push(listingObject);
            listingsByID[listingDoc.id] = listingObject;
            if(listingObject.visible){
              if(listingObject.kind === 'rent'){
                forRent.push(listingObject);
              }
              if(listingObject.kind === 'sale'){
                forSale.push(listingObject);
              }
            } else {
              hidden.push(listingObject);
            }
          //}
        })
      } else {
        console.log('Snapshot is empty...')  
      }
      console.log('Retrieved and sorted listings...');
      that.setState({listings: listings, listingsByID: listingsByID, forSale: forSale, forRent: forRent, hidden: hidden, loaded: true});
    });
  }

  sortListingsByPrice = (listingA, listingB) => {
    return(listingB.price - listingA.price);
  }

  sortListingsByDate = (listingA, listingB) => {
    return(listingB.dateCreated - listingA.dateCreated);
  }


  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
  formatDateTimeString = (dateTime) => {
    return moment(dateTime.toDate()).format('DD-MMM-YYYY [at] HH:mm:ss')
  }

  removeMarkup = (inputString) => { 
    return inputString.replace(/(<([^>]+)>)/gi, " ").replaceAll("&amp;", "&").replaceAll("  ", " "); 
  }

  change_samui_name_to_slug = (listing_name) => {
    //Lower case everything
    listing_name = listing_name.trim().toLowerCase();
    //Make alphanumeric (removes all other characters)
    listing_name = listing_name.replace(/[^a-z0-9_\s-]/g, "");
    //Clean up multiple dashes or whitespaces
    listing_name = listing_name.replace(/[\s-]+/g, " ");
    //Convert whitespaces and underscore to dash
    listing_name = listing_name.replace(/[\s_]/g, "-");
    return listing_name;
}

  handlePageClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id)
    });
  }

  handlePageLimitClick = (event) => {
    console.log('Page Limit Changed: ', event.target.value);
    localStorage.setItem('PageLimit', event.target.value);
    this.setState({ pageSize: Number(event.target.value)  });
  }

  handleVisibilityClick = (event) => {
    console.log('Visibility Type Changed: ', event.target.value);
    localStorage.setItem('ListingVisibility', event.target.value);
    this.setState({ visibility: event.target.value  });
  }

  handleSortOrderChangeClick = (event) => {
    console.log('Sort Order Changed: ', event.target.value);
    localStorage.setItem('ListingSortOrder', event.target.value);
    this.setState({ sortOrder: event.target.value  });
  }

  handleSiteLimitClick = (event) => {
    console.log('Site Limit Changed: ', event.target.value);
    localStorage.setItem('FilterListingSite', event.target.value);
    this.setState({ siteName: event.target.value  });
  }

  handlePriceLimitClick = (event) => {
    console.log('Price Limit Changed: ', event.target.value);
    localStorage.setItem('PriceLimit', event.target.value);
    this.setState({ priceLimit: Number(event.target.value)  });
  }

  handleTypeLimitClick = (event) => {
    console.log('Type Limit Changed: ', event.target.value);
    localStorage.setItem('FilterListingType', event.target.value);
    this.setState({ listingType: event.target.value  });
  }

  handleKindLimitClick = (event) => {
    console.log('Kind Limit Changed: ', event.target.value);
    localStorage.setItem('FilterListingKind', event.target.value);
    this.setState({ listingKind: event.target.value  });
  }

  handleSearch = (event) => {
    const searchString = event.target.value.toLowerCase();
    if(searchString.length < 3){
      console.log("Search skipping...")
      this.setState({searchResults: []});
      return;
    }
    console.log('Search: ', searchString);
    const resultListings = [];
    const allListings = this.state.listings;
    for(let listing of allListings) {
      if(listing.address && listing.address.toLowerCase().includes(searchString)){
        console.log('Match on Address');
        resultListings[listing.id] = listing;
      }
      if(listing.alias && listing.alias.toLowerCase().includes(searchString)){
        console.log('Match on Alias');
        resultListings[listing.id] = listing;
      }
      if(listing.id && listing.id.toLowerCase().startsWith(searchString)){
        console.log('Match on ID');
        resultListings[listing.id] = listing;
      }
      if(listing.name && listing.name.toLowerCase().includes(searchString)){
        console.log('Match on Name');
        resultListings[listing.id] = listing;
      }
      if(listing.description && listing.description.toLowerCase().includes(searchString)){
        console.log('Match on Description');
        resultListings[listing.id] = listing;
      }
      if(listing.internalNotes && listing.internalNotes && listing.internalNotes.toLowerCase().includes(searchString)){
        console.log('Match on Internal Notes');
        resultListings[listing.id] = listing;
      }
      if(listing.villaName && listing.villaName.toLowerCase().includes(searchString)){
        console.log('Match on Villa Name');
        resultListings[listing.id] = listing;
      }
    }
    const searchResults = Object.values(resultListings);
    console.log('Search Results: ', searchResults);
    this.setState({searchResults: searchResults});

  }

  deleteFolder(path) {
    const ref = storage.ref(path);
    ref.listAll()
      .then(dir => {
        dir.items.forEach(fileRef => this.deleteFile(ref.fullPath, fileRef.name));
        dir.prefixes.forEach(folderRef => this.deleteFolder(folderRef.fullPath))
      })
      .catch(error => console.log(error));

      //await Promise.all(enqueues);
  }
  
  deleteFile(pathToFile, fileName) {
    const ref = storage.ref(pathToFile);
    const childRef = ref.child(fileName);
    return(childRef.delete());
    /*
    childRef.delete().then(() => {
      console.log('Deleted file: ', fileName);
    }).catch((error) => {
      console.error('ERROR deleting file: ', pathToFile, error.message);
    });
    */
  }

  deleteListing = (listing) => {
    if(confirm("Are you sure you want to delete this listing record?")){
      console.log('Deleting Listing with ID: ', listing.id);

      firestore.collection('changeSamuiListings2022').doc(listing.id).delete()
      .then(() => {
        console.log('Listing deleted!');
        this.deleteFolder("/changesamui/listings2022/" + listing.id)
        //window.location.reload();
      })
      .catch((error) => {
        console.error("Error removing listing: ", error);
      });
    }
  }


  copyListing = (listing) => {
    console.log("Copy Listing ID: ", listing.id);

    let notes = []
    //TODO: If visible AND when changesamui.com website is updated...

    notes.push('Listing Name: ' + listing.name);
    if(listing.villaName){
      notes.push('Villa Name: ' + listing.villaName);
    }
    if(listing.kind === 'rent'){
      notes.push('฿ ' + this.numberWithCommas(listing.price.toFixed()) + ' / night');
    } else {
      notes.push('฿ ' + this.numberWithCommas(listing.price));
    }

    if(listing.visible){
      if(listing.displaySites.includes('changesamui')){
        notes.push("https://changesamui.com/listing/" + listing.id + "/" + this.change_samui_name_to_slug(listing.name));
      }
      if(listing.displaySites.includes('changehuahin')){
        notes.push("https://changehuahin.com/listing/" + listing.id + "/" + this.change_samui_name_to_slug(listing.name));
      }
    }

    if(listing.commission){
      notes.push('Commission: ' + listing.commission);
    }
    if(listing.owner && listing.owner.name){
      notes.push('Owner Name: ' + listing.owner.name);
    }
    if(listing.owner && listing.owner.phone){
      notes.push('Owner Phone: ' + listing.owner.phone);
    }
    if(listing.owner && listing.owner.email){
      notes.push('Owner Email: ' + listing.owner.email);
    }
    if(listing.owner && listing.owner.whatsApp){
      notes.push('Owner WhatsApp: ' + listing.owner.whatsApp);
    }
    if(listing.owner && listing.owner.line){
      notes.push('Owner Line: ' + listing.owner.line);
    }
    if(listing.owner && listing.owner.notes){
      notes.push('Owner Notes: ' + listing.owner.notes);
    }
    if(listing.internalNotes){
      notes.push('Notes: ' + listing.internalNotes);
    }
    if(listing.commission){
      notes.push('Commission: ' + listing.commission);
    }
    if(listing.description){
      notes.push('Description: ' + this.removeMarkup(listing.description));
    }

    let clipboardText = notes.join("\n");
    console.log(clipboardText);
    navigator.clipboard.writeText(clipboardText);    
  }

  listingList = (listings) => {

    return (

      <ul className="list-unstyled mb-5">

      {listings.map(listing => (


      <li className="card card-bordered shadow-none mb-3" key={ listing.id } >
        <div className="card-body">
          <div className="row">

            <div className="col-6 col-md mb-3 mb-md-0">
              <small className="card-subtitle mb-0">Visible</small>
              <small className="text-dark fw-semi-bold"> 
                <span className="">
                  { listing.visible ? 'Yes' : 'No' }
                </span>
              </small>
            </div>

            <div className="col-6 col-md mb-3 mb-md-0">
              <small className="card-subtitle mb-0">Sold</small>
              <small className="text-dark fw-semi-bold"> 
                <span className="">
                  { listing.isSold ? 'Yes' : 'No' }
                </span>
              </small>
            </div>

            <div className="col-6 col-md mb-3 mb-md-0">
              <small className="card-subtitle mb-0">Office</small>
              <small className="text-dark fw-semi-bold"> 
              <span className="">
                { listing.displaySites.includes('changesamui') ? 'Samui' : '' }
                { listing.displaySites.includes('changehuahin') ? 'Hua Hin' : '' }
                { listing.displaySites.includes('sellerfi') ? 'SellerFi' : '' }
                </span>
              </small>
            </div>


            <div className="col-6 col-md mb-3 mb-md-0">
              <small className="card-subtitle mb-0">ID</small>
              <small className="text-dark fw-semi-bold">{ listing.id }</small>
            </div>

            <div className="col-6 col-md mb-3 mb-md-0">
              <small className="card-subtitle mb-0">{ listing.kind === 'rent' ? 'Rent' : 'Price' }</small>
              <small className="text-dark fw-semi-bold">{ listing.kind === 'rent' ? '฿' + this.numberWithCommas(listing.price.toFixed()) + '/month' : '฿' + this.numberWithCommas(listing.price.toFixed()) }</small>
            </div>

            <div className="col-6 col-md">
              <small className="card-subtitle mb-0">Type</small>
              <small className="text-dark fw-semi-bold">{ listing.type }</small>
            </div>

            <div className="col-6 col-md">
              <small className="card-subtitle mb-0">Bed</small>
              <small className="text-dark fw-semi-bold">{ listing.bed }</small>
            </div>

            <div className="col-6 col-md">
              <small className="card-subtitle mb-0">Bath</small>
              <small className="text-dark fw-semi-bold">{ listing.bath }</small>
            </div>

            { listing.landSize > 0 && 
            <div className="col-6 col-md">
              <small className="card-subtitle mb-0">Land</small>
              <small className="text-dark fw-semi-bold">{ this.numberWithCommas(listing.landSize) } m<sup>2</sup></small>
            </div>
            } 

            { listing.propertySize > 0 && 
            <div className="col-6 col-md">
              <small className="card-subtitle mb-0">Property</small>
              <small className="text-dark fw-semi-bold">{ this.numberWithCommas(listing.propertySize) } m<sup>2</sup></small>
            </div>
            } 

          </div>


          <hr />

          <div className="row">
            <div className="col-md-8">
              <h5>{ listing.isSold ? 'SOLD: ' : '' }{ listing.name } { listing.villaName ? "(" + listing.villaName + ")" : ''}</h5>
              
              { listing.owner &&
                <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-rolodex" viewBox="0 0 16 16">
                        <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                        <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z"/>
                    </svg>&nbsp;
                    Owner Info: {listing.owner.name ? listing.owner.name + ' ' : ''} 
                    {listing.owner.phone ? <><br /><i className="bi bi-telephone"></i>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'tel:' + listing.owner.phone}>{listing.owner.phone}</a></> : <></> } 
                    {listing.owner.email ? <><br /><i className="bi bi-envelope"></i>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'mailto:' + listing.owner.email}>{listing.owner.email}</a></> : <></> }
                    {listing.owner.whatsApp ? <><br /><i className="bi bi-whatsapp"></i>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'https://wa.me/' + listing.owner.whatsApp}>{listing.owner.whatsApp}</a></> : <></> }
                    {listing.owner.line ? <><br /><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-line" viewBox="0 0 16 16"><path d="M8 0c4.411 0 8 2.912 8 6.492 0 1.433-.555 2.723-1.715 3.994-1.678 1.932-5.431 4.285-6.285 4.645-.83.35-.734-.197-.696-.413l.003-.018.114-.685c.027-.204.055-.521-.026-.723-.09-.223-.444-.339-.704-.395C2.846 12.39 0 9.701 0 6.492 0 2.912 3.59 0 8 0ZM5.022 7.686H3.497V4.918a.156.156 0 0 0-.155-.156H2.78a.156.156 0 0 0-.156.156v3.486c0 .041.017.08.044.107v.001l.002.002.002.002a.154.154 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157Zm.791-2.924a.156.156 0 0 0-.156.156v3.486c0 .086.07.155.156.155h.562c.086 0 .155-.07.155-.155V4.918a.156.156 0 0 0-.155-.156h-.562Zm3.863 0a.156.156 0 0 0-.156.156v2.07L7.923 4.832a.17.17 0 0 0-.013-.015v-.001a.139.139 0 0 0-.01-.01l-.003-.003a.092.092 0 0 0-.011-.009h-.001L7.88 4.79l-.003-.002a.029.029 0 0 0-.005-.003l-.008-.005h-.002l-.003-.002-.01-.004-.004-.002a.093.093 0 0 0-.01-.003h-.002l-.003-.001-.009-.002h-.006l-.003-.001h-.004l-.002-.001h-.574a.156.156 0 0 0-.156.155v3.486c0 .086.07.155.156.155h.56c.087 0 .157-.07.157-.155v-2.07l1.6 2.16a.154.154 0 0 0 .039.038l.001.001.01.006.004.002a.066.066 0 0 0 .008.004l.007.003.005.002a.168.168 0 0 0 .01.003h.003a.155.155 0 0 0 .04.006h.56c.087 0 .157-.07.157-.155V4.918a.156.156 0 0 0-.156-.156h-.561Zm3.815.717v-.56a.156.156 0 0 0-.155-.157h-2.242a.155.155 0 0 0-.108.044h-.001l-.001.002-.002.003a.155.155 0 0 0-.044.107v3.486c0 .041.017.08.044.107l.002.003.002.002a.155.155 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156Z"/></svg>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'https://line.me/ti/p/' + listing.owner.line}>{listing.owner.line}</a></> : <></> }
                    {listing.owner.notes ? <><br /><i className="bi bi-pencil-square"></i>&nbsp;{ listing.owner.notes}</> : <></> }
               </p>
              }


              { listing.internal_notes &&
                <p className="mb-0"><b>Internal Notes</b>: { listing.internalNotes }</p>
              }
              { listing.commission &&
                <p className="mb-0"><b>Commission</b>: { listing.commission }</p>
              }
                

              <div className="row gx-2">

                { listing.images && 
                <div className="col imageGridContainer">
                  <ReactImageGrid images={listing.images} />                            
                </div>
                }

              </div>

            </div>

            <div className="col-md-4">
              <div className="d-grid gap-2">
                <a className="btn btn-primary btn-sm" href={'/listing/' + listing.id}>
                  <i className="bi bi-pen small me-2"></i> Edit Listing
                </a>
                { (listing.displaySites.includes('changesamui')) &&
                <a className="btn btn-dark btn-sm" href={'https://changesamui.com/properties/' + listing.id  + "/" + this.change_samui_name_to_slug(listing.name)} target="_blank">
                  <i className="bi bi-arrow-up-right"></i>&nbsp; View Public Listing
                </a>
                }

                { (listing.displaySites.includes('changehuahin')) &&
                <a className="btn btn-dark btn-sm" href={'https://changehuahin.com/properties/' + listing.id  + "/" + this.change_samui_name_to_slug(listing.name)} target="_blank">
                  <i className="bi bi-arrow-up-right"></i>&nbsp; View Public Listing
                </a>
                }

                <a className="btn btn-success btn-sm" href={'/listing/print-customer/' + listing.id}>
                  <i className="bi bi-printer small me-2"></i> Print Customer Listing
                </a>
                <a className="btn btn-danger btn-sm" href={'/listing/print/' + listing.id}>
                  <i className="bi bi-printer small me-2"></i> Print Agent Listing
                </a>
                <a className="btn btn-secondary btn-sm" onClick={ this.copyListing.bind(this, listing) }>
                  <i className="bi bi-clipboard-plus"></i> Copy to Clipboard
                </a>

                { this.state.user.superadmin === true &&
                <a className="btn btn-white btn-sm" onClick={ this.deleteListing.bind(this, listing) }>
                  <i className="bi bi-trash"></i> Delete
                </a>              
                }


              </div>

              <div>
              { (listing.createdBy && listing.createdBy.name) &&
                <span className="eyebrow">Created By: {listing.createdBy.name}</span>
              }              
              { (listing.dateCreated) &&
                <><br /><span className="eyebrow">Created On: {this.formatDateTimeString(listing.dateCreated)}</span></>
              }              
              </div>

              <a href={"https://www.google.com/maps/search/?api=1&query=" + listing.mapCoordinates.latitude + "%2C" + listing.mapCoordinates.longitude} target="_blank" rel="noopener noreferrer">
                <img className="img-fluid mt-3" src={"https://maps.googleapis.com/maps/api/staticmap?center=" + listing.mapCoordinates.latitude + "," + listing.mapCoordinates.longitude + "&markers=size:mid%7Ccolor:0xFFFF00%7Clabel:C%7C" + listing.mapCoordinates.latitude + "," + listing.mapCoordinates.longitude + "&zoom=14&size=310x400&key=AIzaSyAqmGyVMm9TYcZpY8qbPgGfWmx9jErsx1I"} />
                <div className="text-center"><small className='text-muted' style={{ fontVariant: 'small-caps'}}>click to open map in a new window</small></div>
              </a>

            </div>
          </div>
        </div>
      </li>

      ))}


    </ul>


    );


  }
  

  pageNavigation = (currentPage, pageNumbers) => {

    const renderPageNumbers = pageNumbers.map(number => {

      //console.log("Page Number: ", number, " Current Page: ", currentPage);
      if(number === currentPage){
        //console.log('Setting Page ', number, ' as active...');
      return (
          <li className="d-none d-lg-block page-item active" key={number}>
          <a className="page-link" 
          key={number}
          id={number}
          onClick={this.handlePageClick} 
          aria-label={number}>{number}</a>
        </li>
      );
      }else{
        return (
          <li className="d-none d-lg-block page-item" key={number}>
          <a className="page-link" 
          key={number}
          id={number}
          onClick={this.handlePageClick} 
          aria-label={number}>{number}</a>
        </li>
      );
      }
    });  

    return(
      <nav aria-label="Page navigation">
      <ul className="pagination pagination-sm justify-content-center">
        <li className={ currentPage === 1 ? "page-item disabled" : "page-item"}  key={currentPage - 1}>

          <a className="page-link" 
            key={currentPage - 1}
            id={currentPage - 1}
            onClick={this.handlePageClick} 
            aria-label="Previous">Prev</a>

        </li>
        {renderPageNumbers}
        <li className={ currentPage === (pageNumbers.length) ? "page-item disabled" : "page-item"} key={currentPage + 1}>
          
          <a className="page-link" 
            key={currentPage + 1}
            id={currentPage + 1}
            onClick={this.handlePageClick} 
            aria-label="Next">Next</a>


        </li>
      </ul>
    </nav>

    );
  }

  limitBySite = (displayListings, siteName) => {
    console.log('Limit by Site: ', siteName);
    let filteredListings = [];
    displayListings.forEach((listing) => {
      if(listing.displaySites.includes(siteName)){
        filteredListings.push(listing);
      }
    });
    return(filteredListings);
  }

  limitByPrice = (displayListings, priceLimit) => {
    console.log('Limit by price: ', priceLimit);
    if(Number(priceLimit) === -1){
      return displayListings;
    }
    let filteredListings = [];
    displayListings.forEach((listing) => {
      if(listing.price <= parseInt(priceLimit)){
        filteredListings.push(listing);
      }
    });
    return(filteredListings);
  }

  limitByType = (displayListings, listingType) => {
    if(listingType === 'all'){
      return(displayListings);
    }
    console.log('Limit by type: ', listingType);
    let filteredListings = [];
    displayListings.forEach((listing) => {
      if(listing.type === listingType){
        filteredListings.push(listing);
      }
    });
    return(filteredListings);
  }

  limitByKind = (displayListings, listingKind) => {
    if(listingKind === 'all'){
      return(displayListings);
    }
    console.log('Limit by kind: ', listingKind);

    let filteredListings = [];
    displayListings.forEach((listing) => {
      if(listing.kind === listingKind){
        filteredListings.push(listing);
      }
    });
    return(filteredListings);
  }

  limitByVisibility = (displayListings, visibility) => {
    if(visibility === 'all'){
      return(displayListings);
    }
    console.log('Limit by visibility: ', visibility);

    let filteredListings = [];
    displayListings.forEach((listing) => {
      if(listing.visible && visibility === 'visible'){
        filteredListings.push(listing);
      }
      if(listing.visible === false && visibility === 'hidden'){
        filteredListings.push(listing);
      }
    });
    return(filteredListings);
  }

  render() {

    const { user, siteName, sortOrder, pageSize, currentPage, visibility, priceLimit, listingType, listingKind, loaded, searchResults } = this.state;

    const indexOfLast = currentPage * pageSize;
    const indexOfFirst = indexOfLast - pageSize;

    let displayListings = [];

    if(searchResults.length > 0){

      //displayListings = this.state.searchResults.sort(this.sortListingsByPrice);
      displayListings = searchResults.sort(this.sortListingsByDate);
  
    } else {
      console.log('Sort Order: ', sortOrder);
      if(sortOrder === 'date'){
        console.log('Sorting listings by date...');
        displayListings = this.state.listings.sort(this.sortListingsByDate)  
      } else {
        displayListings = this.state.listings.sort(this.sortListingsByPrice)  
      }

      displayListings = this.state.listings;  

    }

    displayListings = this.limitByKind(displayListings, listingKind);

    if(siteName !== 'all'){
      displayListings = this.limitBySite(displayListings, siteName);
    }

    if(priceLimit !== -1){
      displayListings = this.limitByPrice(displayListings, priceLimit);
    }

    if(listingType !== 'all'){
      displayListings = this.limitByType(displayListings, listingType);
    }

    if(visibility !== 'all'){
      displayListings = this.limitByVisibility(displayListings, visibility);
    }

    const displayCount = displayListings.length;

    const listings = displayListings.slice(indexOfFirst, indexOfLast);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(displayListings.length / pageSize); i++) {
      pageNumbers.push(i);
    }

    //const authorizedEmails = ['michael@changevegas.com', 'thida@changesamui.com', 'yuwathida@changevegas.com', 'yuwathida.style@gmail.com']
    if(!user || user.admin === false){
      return (
        <Unauthorized />
      );
    }

    if(!loaded){
      return(
        <>
        <BreadcrumbArea currentPage={"Loading..."} />

      
        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
        <div className="row">
  
          <div className="col-lg-12">
  
            <div className="card">
              <div className="card-header border-bottom">
                <div>
                  <a type="button" className="btn btn-primary ms-auto" href="/listing/add"><i className="bi bi-house-fill"></i> Add Listing</a>
                </div>
              </div>
            </div>
          </div>


          </div>
        <div className="container">
          <div className=" min-height-400 d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status" style={{marginTop: 'auto', marginBottom: 'auto'}}>
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        </div>
        </>
      )
    } else {
      //console.log(listings);
    }
    return(
      <>
      <Header user={user} />
      <BreadcrumbArea currentPage={"Listings"} user={user} />

      <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
      <div className="row">
        <div className="col-lg-12">

          <div className="card">

            <div className="card-header border-bottom">
              <form className="input-group input-group-merge">
                <div className="input-group-prepend input-group-text">
                  <i className="bi-search"></i>
                </div>
                <input type="search" className="form-control" placeholder="Search listings" aria-label="Search listings" onChange={ this.handleSearch } />
              </form>
            </div>



            <div className="card-body">

              <div className="js-nav-scroller hs-nav-scroller-horizontal">
                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                  <a className="hs-nav-scroller-arrow-link" href="#">
                    <i className="bi-chevron-left"></i>
                  </a>
                </span>

                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                  <a className="hs-nav-scroller-arrow-link" href="#">
                    <i className="bi-chevron-right"></i>
                  </a>
                </span>

                { this.state.searchResults && this.state.searchResults.length > 0 ? (
                  <h4>Search Results ({ displayCount })</h4>
                ) : (
                    <h4>Count ({ displayCount })</h4>
                )}
              </div>


                  <div className="d-sm-flex align-items-sm-center mb-5">

                    <div className="mb-2 mb-sm-0 me-3">
                      <span><strong className="text-dark">Site</strong></span>
                    </div>

                    <div className="tom-select-custom">
                      <select className="js-select form-select form-select-sm" onChange={ this.handleSiteLimitClick } value={ siteName }>
                        <option value="all">All</option>
                        <option value="changesamui">Samui</option>
                        <option value="changehuahin">Hua Hin</option>
                        <option value="sellerfi">SellerFi</option>
                      </select>
                    </div>

                    <div className="mb-2 mb-sm-0 mx-3">
                      <span><strong className="text-dark">Kind</strong></span>
                    </div>
                    <div className="tom-select-custom">
                      <select className="js-select form-select form-select-sm" onChange={ this.handleKindLimitClick } value={ listingKind }>
                        <option value="all">All</option>
                        <option value="sale">Sales</option>
                        <option value="rent">Rent</option>
                      </select>
                    </div>

                    <div className="mb-2 mb-sm-0 mx-3">
                      <span><strong className="text-dark">Type</strong></span>
                    </div>
                    <div className="tom-select-custom">
                      <select className="js-select form-select form-select-sm" onChange={ this.handleTypeLimitClick } value={ listingType }>
                        <option value="all">All</option>
                        <option value="villa">Villa</option>
                        <option value="land">Land</option>
                        <option value="house">House</option>
                        <option value="town">Townhouse</option>
                        <option value="condo">Condo</option>
                        <option value="apartments">Apartment</option>
                        <option value="commercial">Commercial</option>
                      </select>
                    </div>

                    <div className="mb-2 mb-sm-0 mx-3">
                      <span><strong className="text-dark">Include</strong></span>
                    </div>
                    <div className="tom-select-custom">
                      <select className="js-select form-select form-select-sm" onChange={ this.handleVisibilityClick } value={ visibility }>
                        <option value="visible">Visible Only</option>
                        <option value="hidden">Hidden Only (pocket listings)</option>
                        <option value="all">Visible or Hidden (all)</option>
                      </select>
                    </div>


                  </div>

                  <div className="d-sm-flex align-items-sm-center mb-5">


                    <div className="mb-2 mb-sm-0 me-3">
                      <span><strong className="text-dark">Show</strong></span>
                    </div>

                    <div className="tom-select-custom">
                      <select className="js-select form-select form-select-sm" onChange={ this.handlePageLimitClick } value={ pageSize }>
                        <option value="10">10 per page</option>
                        <option value="20">20 per page</option>
                        <option value="50">50 per page</option>
                        <option value="100">100 per page</option>
                      </select>
                    </div>

                    <div className="mb-2 mb-sm-0 me-3 ms-3">
                      <span><strong className="text-dark">Filter</strong></span>
                    </div>

                    <div className="tom-select-custom">
                      <select className="js-select form-select form-select-sm" onChange={ this.handlePriceLimitClick } value={ priceLimit }>
                        <option value="-1">All Prices</option>
                        <option value="100000000">฿100,000,000 max price</option>
                        <option value="75000000">฿75,000,000 max price</option>
                        <option value="50000000">฿50,000,000 max price</option>
                        <option value="45000000">฿45,000,000 max price</option>
                        <option value="40000000">฿40,000,000 max price</option>
                        <option value="35000000">฿35,000,000 max price</option>
                        <option value="30000000">฿30,000,000 max price</option>
                        <option value="25000000">฿25,000,000 max price</option>
                        <option value="20000000">฿20,000,000 max price</option>
                        <option value="17500000">฿17,500,000 max price</option>
                        <option value="15000000">฿15,000,000 max price</option>
                        <option value="14000000">฿14,000,000 max price</option>
                        <option value="13000000">฿13,000,000 max price</option>
                        <option value="12000000">฿12,000,000 max price</option>
                        <option value="11000000">฿11,000,000 max price</option>
                        <option value="10000000">฿10,000,000 max price</option>
                        <option value="9000000">฿9,000,000 max price</option>
                        <option value="8000000">฿8,000,000 max price</option>
                        <option value="7000000">฿7,000,000 max price</option>
                        <option value="6000000">฿6,000,000 max price</option>
                        <option value="5000000">฿5,000,000 max price</option>
                      </select>
                    </div>

                    <div className="mb-2 mb-sm-0 me-3">
                      <span><strong className="text-dark">Sort</strong></span>
                    </div>

                    <div className="tom-select-custom">
                      <select className="js-select form-select form-select-sm" onChange={ this.handleSortOrderChangeClick } value={ sortOrder }>
                        <option value="date">Date Created</option>
                        <option value="priceHighLow">Price - High to Low</option>
                      </select>
                    </div>



                    <a type="button" className="btn btn-primary ms-auto" href="/listing/add"><i className="bi bi-house-fill"></i> Add Listing</a>

                  </div>


              <div className="tab-content" id="listingTabContent">
                { listings.length === 0 ?  "" : this.pageNavigation(currentPage, pageNumbers) }
                { listings.length === 0 ?  "No matching listings..." : this.listingList(listings) }
                { listings.length === 0 ?  "" : this.pageNavigation(currentPage, pageNumbers) }
              </div>

            </div>

          </div>

        </div>

      </div>

    </div>
    <Footer />
    </>


    ); 
  }

  

  componentDidMount(){

    if(this.state.user){
      console.log('Property List Mounted. User: ', this.state.user);
      this.getListings();
    }

  }
}

export default Listings;
