import './ListingPrint.css';
import React from 'react';
import BreadcrumbArea from './BreadcrumbArea';
import ListingDisplay from './ListingDisplay';
import { firestore } from './firebase';
import GoogleMapReact from 'google-map-react';
import ReactToPrint from 'react-to-print';

const GoogleMapContainerComponent = ({ text }) => <div className='pinClass'>{text}</div>;

const emptyListing = {
    id: '',
    //property_id: '', // 'IVL205' (IVL ID)
    name: '', 
    villaName: '', // Villa Amaze, (from spreadsheet)
    //descriptions: [{ ja: '', ru: '', fr: '', ch: '', en: ''}],
    description: '',
    kind: 'sale', //sale or rent
    //neighborhood: [{ ja: '', ru: '', fr: '', ch: '', en: ''}],
    neighborhoodInfo: '',
    images: [],
    sourceWebsite: '',
    //lat: 0,
    //lng: 0,
    type: 'villa', //or 'land', 'condo', 'townhouse', 'house', 'apartment', 'commercial'
    featuredImage: '',
    yearBuilt: '',
    visible: false,
    mapCoordinates: {latitude: 9.560027, longitude: 100.029887}, //{lat: 0, lng: 0}, //geo-point (lat, lng),
    address: '', //'79/9 Moo 1 Fisherman Village, Bo Put, Surat Thani 84320',
    city: 'samui',
    region: 'bo put', //(sub-area)
    isNew: false, //OLD
    isSold: false,
    bed: 0,
    bath: 0,
    metaDescription: '', //IVL Metadata (crap),
    metaKeywords: '', // IVL Keywords (crap),
    metaTitle: '', // IVL Title (crap),
    landSize: 0,
    landType: '', //'beach_land'
    price: 0, //0 for rental; number for sale,
    priceRent: 0, // number (THB / night)
    videoEmbed: '', // 'video widget',
    landTitle: '', // 'chanote',
    idRelated: 0, // number (if rental has a related sales listing),
    propertySize: 0, // number/null,
    features: [], // array of feature strings
    rentalTerms: '', //HTML Description
    internalNotes: '',
    commission: '', // 5%, (from spreadsheet)
    // NEW / CLEAN FIELDS:
    displaySites: ['changesamui'], // ['changesamui', 'samuiOfficeTV', 'samuioffplan', etc.],
    status: 'active', //in-contract, sold
    chanoteDeedNumbers: [], // [deed number, deed number],
    ownership: '', // one of - leasehold, company, freehold,
    privatePool: false,
    privateSpa: false,
    communityFee: 0, //number (monthly)
    communityFeatures: [], // [guard, gate, pool, tennis, gym, housekeeping, landscaping, roving security, maintenance, water, sewer, trash, etc.]
    waterRate: 0, // govt, govt + markup, truck
    privateWell: false,
    electricRate: 0, //govt, govt + markup
    solarElectric: false,
    solarSize: 0, // kwh
    electricBackup: [], // [battery, generator],
    garage: 0,
    carport: 0,
    driveway: 0,
    furnitureIncluded: 'no', // no, partial, full,
    dateList: new Date(), //timestamp (calculate 90 days from list date as "New"),
    dateExpire:  '', //timestamp
    dateSold: '', //timestamp
    //owner: {name:'', mobile:'', email:'', whatsApp: '', line:'', facebook:''}, // 'Mr. Smith +66998887777', (from spreadsheet)
    owner: null, // 'Mr. Smith +66998887777', (from spreadsheet)
    contacts: [],
    //Array of contact objects -- Collection
    //{name:'', mobile:'', email:'', whatsApp: '', line:'', facebook:'', type:''}
        //name:
        //phone:
        //email:
        //whatsApp:
        //line:
        //contact_type: owner, cleaning person, manager, prospective_buyer, prospective_renter
        //original_point_of_contct: andy, andrew, shop  
    showings: [], //Array of showing objects -- Collection
                    //date_showing: timestamp,
                    //listing: reference,
                    //agent_names: ['Jakchai Wimaz', 'Yuwathida Poboonrueng'],
                    //notes: string
    offers: [], //Array of offer objects -- Collection
                    //date_offer: timestamp,
                    //listing: reference,
                    //offer_amount: number,
                    //success: boolean,
                    //agent_names: ['Jakchai Wimaz', 'Yuwathida Poboonrueng'],
                    //notes: string
    notes: [] //Array of note objects -- Collection
                    //note: text
                    //entered_by: name
                    //date_entered: timestamp
}


class ListingPrint extends React.Component{

    constructor(props){
      super(props);
      console.log('Listing Print Props: ', this.props);

      const pathParts = document.location.pathname.split("/");

      let listingID = '';
      if(pathParts.length === 4){
        listingID = pathParts[3];
      }

      this.state = {
        user: props.user,
        listingID: listingID,
        listing: emptyListing,
      }
      console.log('ListingPrint Constructor', this.state);
    }

    getListing = () => {
        let listingID = this.state.listingID;
        var listingRef = firestore.collection('changeSamuiListings2022').doc(listingID);
        listingRef.get().then((listingDoc) => {
            if(listingDoc.exists){
                var listing = listingDoc.data();
                this.setState({listing: listing});
            } else {
                console.log("No document for listingID: ", listingID);
            }
        }).catch((error) =>{
            console.error('Error getting document for listingID: ', listingID, error);
        });

    }

    titleCase = (inputString) => {
        return inputString.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }
      
    getChangedPos = (currentPos, newPos) => {
        console.log(currentPos, newPos);
    };

    handleApiLoaded = (map, maps) => {
        // use map and maps objects
    };    

    render() {
        const { user } = this.state;
        const { action } = this.props;

        var listing = this.state.listing;
        console.log("💻 Rendering... Listing: ", listing);

        if(listing.id === ''){
            return(
                <>
                <BreadcrumbArea currentPage={"Loading..."} />
                <div className="container">
                  <div className=" min-height-400 d-flex justify-content-center">
                    <div className="spinner-border text-primary" role="status" style={{marginTop: 'auto', marginBottom: 'auto'}}>
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
                </>            
            );
        }


        return(

        <>


        <BreadcrumbArea currentPage={ action === 'print-customer' ? "Print Customer Listing" : "Print Agent Listing"} parentPage={{url: "/listings", name: "Listings"}} user={user} />


        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="printButton  float-end">
                                <ReactToPrint trigger={() => { return <a className="btn btn-primary" href="#"><i className="bi bi-printer small me-2"></i> Print Listing</a>; }} content={() => this.componentRef} />
                                <div style={{ display: "none" }}>
                                    <ListingDisplay user={user} listing={listing} action={action} ref={el => (this.componentRef = el)} />
                                </div>        
                            </div>


                            <div className="row">
                                <div className="col">                                
                                    <h3>Listing ID: <small className="text-muted">{this.state.listing.id}</small></h3>
                                    <p className="my-0"><strong>Transaction Type</strong>: {this.titleCase(this.state.listing.kind)}</p>
                                    <p className="my-0"><strong>Listing Type</strong>: {this.titleCase(this.state.listing.type)}</p>

                                    {this.state.listing.visible ?
                                    <p className="my-0"><strong>Display</strong>: Visibile</p>
                                    :
                                    <p className="my-0"><strong>Display</strong>: Pocket Listing (admin.changesamui.com only)</p>
                                    }

                                    { action === 'print-customer' &&                                     
                                    <div>
                                        <p className="mt-3 mb-0"><strong>Presented By</strong>:</p>
                                        <div className='row row-cols-auto'>
                                            { user.profilePhoto ? <div className='col'><img className='img-fluid' src={user.profilePhoto} alt='' style={{ maxWidth: 80 }} /></div> : <></> }
                                            <div className='col'>
                                                <p className="my-0">{ user.displayName }</p>
                                                <p className="my-0">{ user.email }</p>
                                                { user.phone ? <p className="my-0">{user.phone}</p> : <></> }
                                            </div>
                                        </div>
                                    </div>
                                    }


                                    { action !== 'print-customer' && 
                                    <>
                                    <p className="my-0"><strong>Display Sites</strong>:</p>
                                    <ul>
                                    {(this.state.listing.displaySites && this.state.listing.displaySites.includes('changesamui')) &&
                                        <li>Change Samui Website</li>
                                    }
                                    {(this.state.listing.displaySites && this.state.listing.displaySites.includes('changehuahin')) &&
                                        <li>Change Hua Hin Website</li>
                                    }
                                    {(this.state.listing.displaySites && this.state.listing.displaySites.includes('samuiOfficeTV')) &&
                                        <li>Change Samui Office TV</li>
                                    }
                                    {(this.state.listing.displaySites && this.state.listing.displaySites.includes('samuioffplan')) &&
                                        <li>Samui Off Plan Website</li>
                                    }
                                    {(this.state.listing.displaySites && this.state.listing.displaySites.includes('sellerfi')) &&
                                        <li>SellerFi Website</li>
                                    }
                                    </ul>
                                    </>
                                    }


                                    { (this.state.listing.owner && action !== 'print-customer') &&
                                        <p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-rolodex" viewBox="0 0 16 16">
                                                <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                                                <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z"/>
                                            </svg>&nbsp;
                                            <strong>Owner Info</strong>:
                                            {this.state.listing.owner.name ? <><br /> {this.state.listing.owner.name} </> : <></>} 
                                            {this.state.listing.owner.phone ? <><br /><i className="bi bi-telephone"></i>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'tel:' + this.state.listing.owner.phone}>{this.state.listing.owner.phone}</a></> : <></> } 
                                            {this.state.listing.owner.email ? <><br /><i className="bi bi-envelope"></i>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'mailto:' + this.state.listing.owner.email}>{this.state.listing.owner.email}</a></> : <></> }
                                            {this.state.listing.owner.whatsApp ? <><br /><i className="bi bi-whatsapp"></i>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'https://wa.me/' + this.state.listing.owner.whatsApp}>{this.state.listing.owner.whatsApp}</a></> : <></> }
                                            {this.state.listing.owner.line ? <><br /><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-line" viewBox="0 0 16 16"><path d="M8 0c4.411 0 8 2.912 8 6.492 0 1.433-.555 2.723-1.715 3.994-1.678 1.932-5.431 4.285-6.285 4.645-.83.35-.734-.197-.696-.413l.003-.018.114-.685c.027-.204.055-.521-.026-.723-.09-.223-.444-.339-.704-.395C2.846 12.39 0 9.701 0 6.492 0 2.912 3.59 0 8 0ZM5.022 7.686H3.497V4.918a.156.156 0 0 0-.155-.156H2.78a.156.156 0 0 0-.156.156v3.486c0 .041.017.08.044.107v.001l.002.002.002.002a.154.154 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157Zm.791-2.924a.156.156 0 0 0-.156.156v3.486c0 .086.07.155.156.155h.562c.086 0 .155-.07.155-.155V4.918a.156.156 0 0 0-.155-.156h-.562Zm3.863 0a.156.156 0 0 0-.156.156v2.07L7.923 4.832a.17.17 0 0 0-.013-.015v-.001a.139.139 0 0 0-.01-.01l-.003-.003a.092.092 0 0 0-.011-.009h-.001L7.88 4.79l-.003-.002a.029.029 0 0 0-.005-.003l-.008-.005h-.002l-.003-.002-.01-.004-.004-.002a.093.093 0 0 0-.01-.003h-.002l-.003-.001-.009-.002h-.006l-.003-.001h-.004l-.002-.001h-.574a.156.156 0 0 0-.156.155v3.486c0 .086.07.155.156.155h.56c.087 0 .157-.07.157-.155v-2.07l1.6 2.16a.154.154 0 0 0 .039.038l.001.001.01.006.004.002a.066.066 0 0 0 .008.004l.007.003.005.002a.168.168 0 0 0 .01.003h.003a.155.155 0 0 0 .04.006h.56c.087 0 .157-.07.157-.155V4.918a.156.156 0 0 0-.156-.156h-.561Zm3.815.717v-.56a.156.156 0 0 0-.155-.157h-2.242a.155.155 0 0 0-.108.044h-.001l-.001.002-.002.003a.155.155 0 0 0-.044.107v3.486c0 .041.017.08.044.107l.002.003.002.002a.155.155 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156Z"/></svg>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'https://line.me/ti/p/' + this.state.listing.owner.line}>{this.state.listing.owner.line}</a></> : <></> }
                                            {this.state.listing.owner.notes ? <><br /><i className="bi bi-pencil-square"></i>&nbsp;{ this.state.listing.owner.notes}</> : <></> }
                                      </p>
                                    }
                                </div>
                            </div>
                            <hr />
                            <p className='my-0'><strong>Listing Name</strong>: {this.state.listing.name}</p>
                            { this.state.listing.villaName &&
                                <p className='my-0'><strong>Villa Proper Name</strong>: {this.state.listing.villaName}</p>
                            }

                            { this.state.listing.kind === 'rent' ?
                            <p className='my-0'><strong>Rental Price (nightly price)</strong>: {new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(this.state.listing.price)}</p>
                            :
                            <p className='my-0'><strong>Price</strong>: {new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(this.state.listing.price)}</p>
                            }


                            { this.state.listing.bed &&
                            <p className='my-0'><strong>Bedroom Count</strong>: {this.state.listing.bed}</p>
                            }

                            { this.state.listing.bath &&
                            <p className='my-0'><strong>Bathroom Count</strong>: {this.state.listing.bath}</p>
                            }
                            { this.state.listing.yearBuilt &&
                            <p className='my-0'><strong>Year Built</strong>: {this.state.listing.yearBuilt}</p>
                            }
                            <hr />
                            {this.state.listing.description && 
                            <div className="mt-3">
                                <h3>Description</h3>
                                <div className="row">
                                    <div className="col mb-3" dangerouslySetInnerHTML={{ __html: this.state.listing.description}} />
                                </div>
                            </div>
                            }

                            {(this.state.listing.address && action !== 'print-customer') &&
                            <>
                            <h4 className='mt-3'>Listing Location: </h4>
                            <div className="mb-3">
                                <p>Address: {this.state.listing.address}</p>
                            </div>
                            </>
                            }

                            {(this.state.listing.mapCoordinates && action !== 'print-customer') && 
                            <div>
                                <p className="my-0"><strong>Latitude</strong>: {(this.state.listing.mapCoordinates && this.state.listing.mapCoordinates.latitude) ? this.state.listing.mapCoordinates.latitude : 0}</p>
                                <p className="my-0"><strong>Longitude</strong>: {(this.state.listing.mapCoordinates && this.state.listing.mapCoordinates.longitude) ? this.state.listing.mapCoordinates.latitude : 0}</p>
                            </div>
                            }


                            {(this.state.listing.mapCoordinates && action !== 'print-customer') &&
                            <div className="mb-3">
                                <div style={{ height: '500px', width: '100%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: 'AIzaSyAqmGyVMm9TYcZpY8qbPgGfWmx9jErsx1I' }}
                                        center={{lat: this.state.listing.mapCoordinates.latitude, lng: this.state.listing.mapCoordinates.longitude}}
                                        defaultZoom={13}
                                        options={ {mapTypeControl: true, streetViewControl: true, fullscreenControl: true }}
                                        yesIWantToUseGoogleMapApiInternals={true}
                                        onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                                    >
                                        <GoogleMapContainerComponent
                                            lat={this.state.listing.mapCoordinates.latitude}
                                            lng={this.state.listing.mapCoordinates.longitude}
                                            text={<i className="bi bi-geo-fill text-danger"></i>}
                                        />
                                    </GoogleMapReact>
                                </div>
                            </div>
                            }

                            { this.state.listing.images && 
                            <div id="imageDisplayArea" className="my-3">
                                <h4>Listing Images: </h4>
                                <div className='row row-cols-1 row-cols-md-2 gx-2 gy-2'>
                                    {this.state.listing.images.map((image, index) => 
                                        <div key={index} className="col file-item" >
                                            <div className="border rounded p-1">
                                                <img src={image} className="img-fluid file-img" alt={index} />
                                            </div>
                                        </div>                                    
                                    )}
                                </div>
                            </div>
                            }

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        </>
        );
    }

    componentDidMount(){
        
        if(this.state.listingID){
            this.getListing();
        }

    }
}
    
export default ListingPrint;
    