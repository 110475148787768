import './ListingPrint.css';
import React from 'react';
import GoogleMapReact from 'google-map-react';
const GoogleMapContainerComponent = ({ text }) => <div className='pinClass'>{text}</div>;

class ListingDisplay extends React.Component{

    constructor(props){
      super(props);
      console.log('ListingDisplay Props: ', this.props);

      this.state = {
        user: props.user,
        listingID: props.listing.id,
        listing: props.listing,
      }
      console.log('ListingDisplay Constructor. State: ', this.state);
    }

    titleCase = (inputString) => {
        return inputString.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }
      
    getChangedPos = (currentPos, newPos) => {
        console.log(currentPos, newPos);
    };

    handleApiLoaded = (map, maps) => {
        // use map and maps objects
    };    

    render() {
        const { user, action } = this.props;

        var listing = this.state.listing;
        console.log("💻 Rendering... Listing Display: ", listing, ' Props: ', this.props);

        return(
        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
            { this.state.listing.displaySites.includes('changesamui') &&
            <div className='text-center'><img style={{ width: 400 }}  src="/img/logo.1922.png" alt="Change Samui Real Estate" /></div>
            }
            { this.state.listing.displaySites.includes('changehuahin') &&
            <div className='text-center'><img style={{ width: 400 }}  src="/img/logo.huahin.1922.png" alt="Change Hua Hin Real Estate" /></div>
            }
            { action === 'print-agent' && 
            <div className='text-center bg-danger text-white rounded'><h4 className="text-white p-2"><i className="bi bi-exclamation-triangle-fill"></i> <i className="bi bi-exclamation-triangle-fill"></i> DO NOT SHARE WITH CUSTOMERS <i className="bi bi-exclamation-triangle-fill"></i> <i className="bi bi-exclamation-triangle-fill"></i></h4></div>
            }
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">                                
                                    <h3>Listing ID: <small className="text-muted">{this.state.listing.id}</small></h3>
                                    <p className="my-0"><strong>Transaction Type</strong>: {this.titleCase(this.state.listing.kind)}</p>
                                    <p className="my-0"><strong>Listing Type</strong>: {this.titleCase(this.state.listing.type)}</p>

                                    {this.state.listing.visible ?
                                    <p className="my-0"><strong>Display</strong>: Visibile</p>
                                    :
                                    <p className="my-0"><strong>Display</strong>: Pocket Listing (admin.changesamui.com only)</p>
                                    }
                                    { action !== 'print-customer' && 
                                    <>
                                    <p className="my-0"><strong>Display Sites</strong>:</p>
                                    <ul>
                                    {(this.state.listing.displaySites && this.state.listing.displaySites.includes('changesamui')) &&
                                        <li>Change Samui Website</li>
                                    }
                                    {(this.state.listing.displaySites && this.state.listing.displaySites.includes('samuiOfficeTV')) &&
                                        <li>Change Samui Office TV</li>
                                    }
                                    {(this.state.listing.displaySites && this.state.listing.displaySites.includes('samuioffplan')) &&
                                        <li>Samui Off Plan Website</li>
                                    }
                                    </ul>
                                    </>
                                    }


                                    { (this.state.listing.owner && action !== 'print-customer') &&
                                        <p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-rolodex" viewBox="0 0 16 16">
                                                <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                                                <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z"/>
                                            </svg>&nbsp;
                                            <strong>Owner Info</strong>:
                                            {this.state.listing.owner.name ? <><br /> {this.state.listing.owner.name} </> : <></>} 
                                            {this.state.listing.owner.phone ? <><br /><i className="bi bi-telephone"></i>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'tel:' + this.state.listing.owner.phone}>{this.state.listing.owner.phone}</a></> : <></> } 
                                            {this.state.listing.owner.email ? <><br /><i className="bi bi-envelope"></i>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'mailto:' + this.state.listing.owner.email}>{this.state.listing.owner.email}</a></> : <></> }
                                            {this.state.listing.owner.whatsApp ? <><br /><i className="bi bi-whatsapp"></i>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'https://wa.me/' + this.state.listing.owner.whatsApp}>{this.state.listing.owner.whatsApp}</a></> : <></> }
                                            {this.state.listing.owner.line ? <><br /><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-line" viewBox="0 0 16 16"><path d="M8 0c4.411 0 8 2.912 8 6.492 0 1.433-.555 2.723-1.715 3.994-1.678 1.932-5.431 4.285-6.285 4.645-.83.35-.734-.197-.696-.413l.003-.018.114-.685c.027-.204.055-.521-.026-.723-.09-.223-.444-.339-.704-.395C2.846 12.39 0 9.701 0 6.492 0 2.912 3.59 0 8 0ZM5.022 7.686H3.497V4.918a.156.156 0 0 0-.155-.156H2.78a.156.156 0 0 0-.156.156v3.486c0 .041.017.08.044.107v.001l.002.002.002.002a.154.154 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157Zm.791-2.924a.156.156 0 0 0-.156.156v3.486c0 .086.07.155.156.155h.562c.086 0 .155-.07.155-.155V4.918a.156.156 0 0 0-.155-.156h-.562Zm3.863 0a.156.156 0 0 0-.156.156v2.07L7.923 4.832a.17.17 0 0 0-.013-.015v-.001a.139.139 0 0 0-.01-.01l-.003-.003a.092.092 0 0 0-.011-.009h-.001L7.88 4.79l-.003-.002a.029.029 0 0 0-.005-.003l-.008-.005h-.002l-.003-.002-.01-.004-.004-.002a.093.093 0 0 0-.01-.003h-.002l-.003-.001-.009-.002h-.006l-.003-.001h-.004l-.002-.001h-.574a.156.156 0 0 0-.156.155v3.486c0 .086.07.155.156.155h.56c.087 0 .157-.07.157-.155v-2.07l1.6 2.16a.154.154 0 0 0 .039.038l.001.001.01.006.004.002a.066.066 0 0 0 .008.004l.007.003.005.002a.168.168 0 0 0 .01.003h.003a.155.155 0 0 0 .04.006h.56c.087 0 .157-.07.157-.155V4.918a.156.156 0 0 0-.156-.156h-.561Zm3.815.717v-.56a.156.156 0 0 0-.155-.157h-2.242a.155.155 0 0 0-.108.044h-.001l-.001.002-.002.003a.155.155 0 0 0-.044.107v3.486c0 .041.017.08.044.107l.002.003.002.002a.155.155 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156Z"/></svg>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'https://line.me/ti/p/' + this.state.listing.owner.line}>{this.state.listing.owner.line}</a></> : <></> }
                                            {this.state.listing.owner.notes ? <><br /><i className="bi bi-pencil-square"></i>&nbsp;{ this.state.listing.owner.notes}</> : <></> }
                                    </p>
                                    }
                                </div>

                                { action === 'print-customer' &&                                     
                                <div className="col">
                                    <p className="my-0"><strong>Presented By</strong>:</p>
                                    <div className='row row-cols-auto'>
                                        { user.profilePhoto ? <div className='col'><img className='img-fluid' src={user.profilePhoto} alt='' style={{ maxWidth: 80 }}  /></div> : <></> }
                                        <div className='col'>
                                            <p className="my-0">{ user.displayName }</p>
                                            <p className="my-0">{ user.email }</p>
                                            { user.phone ? <p className="my-0">{user.phone}</p> : <></> }
                                        </div>
                                    </div>
                                </div>
                                }

                            </div>
                            <hr />
                            <p className='my-0'><strong>Listing Name</strong>: {this.state.listing.name}</p>
                            { this.state.listing.villaName &&
                                <p className='my-0'><strong>Villa Proper Name</strong>: {this.state.listing.villaName}</p>
                            }

                            { this.state.listing.kind === 'rent' ?
                            <p className='my-0'><strong>Rental Price (nightly price)</strong>: {new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(this.state.listing.price)}</p>
                            :
                            <p className='my-0'><strong>Price</strong>: {new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(this.state.listing.price)}</p>
                            }


                            { this.state.listing.bed &&
                            <p className='my-0'><strong>Bedroom Count</strong>: {this.state.listing.bed}</p>
                            }

                            { this.state.listing.bath &&
                            <p className='my-0'><strong>Bathroom Count</strong>: {this.state.listing.bath}</p>
                            }
                            { this.state.listing.yearBuilt &&
                            <p className='my-0'><strong>Year Built</strong>: {this.state.listing.yearBuilt}</p>
                            }
                            <hr />
                            {this.state.listing.description && 
                            <div className="mt-3">
                                <h3>Description</h3>
                                <div className="row">
                                    <div className="col mb-3" dangerouslySetInnerHTML={{ __html: this.state.listing.description}} />
                                </div>
                            </div>
                            }


                            {action !== 'print-customer' &&
                            <div style={{'pageBreakBefore': 'always'}}>                            
                                <h4 className='mt-3'>Listing Location: </h4>
                            </div>
                            }

                            {(this.state.listing.address && action !== 'print-customer') &&
                            <div className="mb-3">
                                <p>Address: {this.state.listing.address}</p>
                            </div>
                            }

                            {(this.state.listing.mapCoordinates && action !== 'print-customer') && 
                            <div>
                                <p className="my-0"><strong>Latitude</strong>: {(this.state.listing.mapCoordinates && this.state.listing.mapCoordinates.latitude) ? this.state.listing.mapCoordinates.latitude : 0}</p>
                                <p className="my-0"><strong>Longitude</strong>: {(this.state.listing.mapCoordinates && this.state.listing.mapCoordinates.longitude) ? this.state.listing.mapCoordinates.latitude : 0}</p>
                            </div>
                            }


                            {(this.state.listing.mapCoordinates && action !== 'print-customer') &&
                            <div className="mb-3">
                                <div style={{ height: '500px', width: '100%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: 'AIzaSyAqmGyVMm9TYcZpY8qbPgGfWmx9jErsx1I' }}
                                        center={{lat: this.state.listing.mapCoordinates.latitude, lng: this.state.listing.mapCoordinates.longitude}}
                                        defaultZoom={13}
                                        options={ {mapTypeControl: true, streetViewControl: true, fullscreenControl: true }}
                                        yesIWantToUseGoogleMapApiInternals={true}
                                        onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                                    >
                                        <GoogleMapContainerComponent
                                            lat={this.state.listing.mapCoordinates.latitude}
                                            lng={this.state.listing.mapCoordinates.longitude}
                                            text={<i className="bi bi-geo-fill text-danger"></i>}
                                        />
                                    </GoogleMapReact>
                                </div>
                            </div>
                            }

                            { this.state.listing.images && 
                            <div id="imageDisplayArea" className="my-3" style={{'pageBreakBefore': 'always'}}>
                                <h4>Listing Images: </h4>
                                <div className='row row-cols-1 row-cols-md-2 gx-2 gy-2'>
                                    {this.state.listing.images.map((image, index) => 
                                        <div key={index} className="col file-item">
                                            <img src={image} className="img-fluid file-img" alt={index} style={{'breakInside': 'avoid-page'}} />
                                        </div>                                    
                                    )}
                                </div>
                            </div>
                            }

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
    
export default ListingDisplay;
    