import './CustomerAddEdit.css';
import React from 'react';
//import { Navigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import BreadcrumbArea from './BreadcrumbArea';
import firebase, { analytics, auth, firestore, storage } from './firebase';
import DateTimePicker from 'react-datetime-picker'
import { FileUploader } from "react-drag-drop-files";
import phoneCountryCodes from "./phoneCountryCodes.json";

const emptyCustomer = {
    firstname: '', 
    lastname: '', 
    email: '', 
    phone: '', 
    buyer: false, 
    seller: false, 
    source: "walk-in",
    rating: 0,
    budget: '', 
    timelines: [], 
    locations: [],
    type: [],
    style: [],
    otherstyle: '',
    bedrooms: 0,
    bathrooms: 0,
    sizehome: '',
    sizeland: '',
    features: [],
    notes: '',
    visitDate: new Date(),
}


class CustomerAddEdit extends React.Component{

    constructor(props){
      super(props);
      console.log('CustomerAddEdit Props: ', this.props);

      const pathParts = document.location.pathname.split("/");

      let customerID = '';
      if(pathParts.length === 3){
        customerID = pathParts[2];
      }
      if (customerID === 'add'){
          customerID = '';
      }

      this.state = {
        user: props.user,
        customerID: customerID,
        customer: emptyCustomer,
        showAlert: false,
        action: props.action,
        redirect: false,
        uploadFile: null,
      }
      
      console.log('CustomerAddEdit Constructor', this.state);
    }

    getCustomer = () => {
        let customerID = this.state.customerID;
        var customerRef = firestore.collection('changeSamuiCustomers').doc(customerID);
        customerRef.get().then((customerDoc) => {
            if(customerDoc.exists){
                var customer = customerDoc.data();
                if(customer.visitDate){
                    customer.visitDate = customer.visitDate.toDate();
                } else {
                    customer.visitDate = new Date();
                }
                this.setState({customer: customer});
            } else {
                console.log("No document for customerID: ", customerID);
            }
        }).catch((error) =>{
            console.error('Error getting document for customerID: ', customerID, error);
        });

    }

    disableButtons = (disable) => {

        var buttons = document.getElementsByClassName('formButton');

        for (let i = 0; i < buttons.length; i++) {
            if(disable === true){
                console.log('Disabling button: ', i);
                buttons[i].setAttribute('disabled', 'disabled');
            } else {
                console.log('Enabling button: ', i);
                buttons[i].removeAttribute('disabled');
            }
        }
    }


    handleSave = (event) =>{
        console.log('Save clicked! Validating...');
        this.disableButtons(true);

        var form = document.forms['customerForm'];
        var isValid = true;

        var office = this.state.customer.office;
        if(!this.state.customer.office){
            isValid = false;
            form['officeSamui'].classList.remove('is-valid');
            form['officeHuaHin'].classList.remove('is-valid');
            form['officeSamui'].classList.add('is-invalid');
            form['officeHuaHin'].classList.add('is-invalid');
        } else {
            form['officeSamui'].classList.remove('is-invalid');
            form['officeHuaHin'].classList.remove('is-invalid');
            form['officeSamui'].classList.add('is-valid');
            form['officeHuaHin'].classList.add('is-valid');
        }

        var firstname = form['firstname'].value;
        var lastname = form['lastname'].value;

        if(firstname.length === 0){
            isValid = false;
            form['firstname'].classList.add('is-invalid');
            form['firstname'].classList.remove('is-valid');
        } else {
            form['firstname'].classList.add('is-valid');
            form['firstname'].classList.remove('is-invalid');
        }
        if (lastname.length === 0){
            isValid = false;
            form['lastname'].classList.add('is-invalid');
            form['lastname'].classList.remove('is-valid');
        } else {
            form['lastname'].classList.add('is-valid');
            form['lastname'].classList.remove('is-invalid');
        }
        var email = form['email'].value;
        var phone = form['phone'].value;

        if(email.length === 0 && phone.length === 0){
            isValid = false;
            form['email'].classList.add('is-invalid');
            form['email'].classList.remove('is-valid');
            form['phone'].classList.add('is-invalid');
            form['phone'].classList.remove('is-valid');
        } else {
            form['email'].classList.add('is-valid');
            form['email'].classList.remove('is-invalid');
            form['phone'].classList.add('is-valid');
            form['phone'].classList.remove('is-invalid');
        }

        var buyer = form['buyer'].checked;
        var seller = form['seller'].checked;
        var renter = form['renter'].checked;

        if(buyer === false && seller === false && renter === false){
            isValid = false;
            form['buyer'].classList.add('is-invalid');
            form['buyer'].classList.remove('is-valid');
            form['seller'].classList.add('is-invalid');
            form['seller'].classList.remove('is-valid');
            form['renter'].classList.add('is-invalid');
            form['renter'].classList.remove('is-valid');
        } else {
            form['buyer'].classList.add('is-valid');
            form['buyer'].classList.remove('is-invalid');
            form['seller'].classList.add('is-valid');
            form['seller'].classList.remove('is-invalid');
            form['renter'].classList.add('is-valid');
            form['renter'].classList.remove('is-invalid');
        }

        var budget = form['budget'].value;
        if(budget.length === 0){
            isValid = false;
            form['budget'].classList.add('is-invalid');
            form['budget'].classList.remove('is-valid');
        } else {
            form['budget'].classList.add('is-valid');
            form['budget'].classList.remove('is-invalid');
        }

        if(isValid === false){
            console.log('Form is invalid...');
            event.preventDefault();
            event.stopPropagation();
            this.disableButtons(false);

            this.setState({ showAlert: true });
            this.timeout = setTimeout(() => {
                this.setState({ showAlert: false });
            }, 5000);


        } else {

            var notes = form['notes'].value;
            var timelines = [];
            if(form['months1to3'].checked){
                timelines.push('1-3 months');
            }
            if(form['months3to6'].checked){
                timelines.push('3-6 months');
            }
            if(form['months6to12'].checked){
                timelines.push('6-12 months');
            }
            

            var locations = [];

            if(form['northeast'].checked){
                locations.push('northeast');
            }
            if(form['east'].checked){
                locations.push('east');
            }
            if(form['southeast'].checked){
                locations.push('southeast');
            }
            if(form['south'].checked){
                locations.push('south');
            }
            if(form['southwest'].checked){
                locations.push('southwest');
            }
            if(form['west'].checked){
                locations.push('west');
            }
            if(form['northwest'].checked){
                locations.push('northwest');
            }
            if(form['north'].checked){
                locations.push('north');
            }

            var type = [];

            if(form['villa'].checked){
                type.push('villa');
            }
            if(form['townhouse'].checked){
                type.push('townhouse');
            }
            if(form['condo'].checked){
                type.push('condo');
            }
            if(form['apartment'].checked){
                type.push('apartment');
            }
            if(form['land'].checked){
                type.push('land');
            }
            if(form['commercial'].checked){
                type.push('commercial');
            }

            var style = [];
            if(form['modern'].checked){
                style.push('modern');
            }
            if(form['traditional'].checked){
                style.push('traditional');
            }
            if(form['other'].checked){
                style.push('other');
            }

            var otherstyle = form['otherstyle'].value;

            var bedrooms = form['bedrooms'].value;
            var bathrooms = form['bathrooms'].value;
            var sizehome = form['sizehome'].value;
            var sizeland = form['sizeland'].value;

            var features = [];
            if(form['pool'].checked){
                features.push('pool');
            }
            if(form['spa'].checked){
                features.push('spa');
            }
            if(form['beachfront'].checked){
                features.push('beachfront');
            }
            if(form['seaview'].checked){
                features.push('seaview');
            }
            if(form['carport'].checked){
                features.push('carport');
            }
            if(form['garage'].checked){
                features.push('garage');
            }

            var source = "walk-in";
            if (this.state.customer.source){
                source = this.state.customer.source;
            }

            var visitDate = this.state.customer.visitDate;

            var customer = {
                office: office,
                source: source,
                firstname: firstname, 
                lastname: lastname, 
                email: email, 
                phone: phone, 
                buyer: buyer, 
                seller: seller,
                renter: renter,
                rating: this.state.customer.rating, 
                budget: budget, 
                timelines: timelines, 
                locations: locations,
                type: type,
                style: style,
                otherstyle: otherstyle,
                bedrooms: bedrooms,
                bathrooms: bathrooms,
                sizehome: sizehome,
                sizeland: sizeland,
                features: features,
                notes: notes,
                visitDate: visitDate,
            }
            console.log('Form is valid. Customer ID: ', this.state.customerID, ' Customer: ', customer);

            event.preventDefault();
            event.stopPropagation();


            if(this.state.customerID && this.state.customerID.length > 1){
                customer.dateUpdated = firebase.firestore.FieldValue.serverTimestamp();
                customer.updatedBy = { name: this.state.user.displayName, email: this.state.user.email, uid: this.state.user.uid };

                firestore.collection('changeSamuiCustomers').doc(this.state.customerID).update(customer)
                .then(async() => {
                    console.log("Customer updated with Customer ID: ", this.state.customerID);

                    //Upload images before redirecting...
                    if(this.state.uploadFile){

                        console.log('Upload Loop starting...');
                        this.uploadFile(this.state.customerID, this.state.uploadFile).then(() =>{
                            console.log('Upload customer photo complete!!!');
                            //this.disableButtons(false);
                            window.location.assign("/customers");

                        });
                        console.log('Upload Loop complete...');
                    } else {
                            //this.disableButtons(false);
                            window.location.assign("/customers");
                    }                                        

                })
                .catch((error) => {
                    console.error('Error updating customer: ', error);
                });
    

            } else {
                customer.dateCreated = firebase.firestore.FieldValue.serverTimestamp();
                customer.createdBy = { name: this.state.user.displayName, email: this.state.user.email, uid: this.state.user.uid };
                firestore.collection('changeSamuiCustomers').add(customer)
                .then(async(customerRef) => {
                    console.log("Customer written with Customer ID: ", customerRef.id);

                    //Upload images before redirecting...
                    if(this.state.uploadFile){

                        console.log('Upload Loop starting...');
                        this.uploadFile(customerRef.id, this.state.uploadFile).then(() =>{
                            console.log('Upload customer photo complete!!!');
                            //this.disableButtons(false);
                            window.location.assign("/customers");

                        });
                        console.log('Upload Loop complete...');
                    } else {
                            //this.disableButtons(false);
                            window.location.assign("/customers");
                    }                                        

                })
                .catch((error) => {
                    console.error('Error adding customer: ', error);
                });
    
            }


        }

        //form.classList.add('was-validated');
    }

    handleCancel = (event) =>{
        //REDIRECT BACK TO CUSTOMER LIST PAGE...
        //this.setState({redirect: true});
        window.location.assign("/customers");

    }

    handleChange = (event) => {
        console.log('Handle Change for Event: ', event.target.name, event.target.value);
        var customer = this.state.customer;
        customer[event.target.id.trim()] = event.target.value;
        this.setState({customer: customer});

    }

    handleCheckChange = (event) => {
        console.log('Handle Change for Check Change Event: ', event.target.id, event.target.checked);
        var customer = this.state.customer;
        customer[event.target.id.trim()] = event.target.checked;
        this.setState({customer: customer});

    }

    handleOptionChange = (event) => {
        console.log('Handle Change for Option Change Event: ', event.target.id, event.target.name);
        var customer = this.state.customer;
        customer[event.target.name.trim()] = event.target.value;
        this.setState({customer: customer});
    }

    handleTimelinesChange = (event) => {
        console.log('Handle Change for Timelines Check Event: ', event.target.id, event.target.checked);
        var customer = this.state.customer;
        var timelines = this.state.customer.timelines;
        switch(event.target.id){
            case 'months1to3': 
                if(event.target.checked){
                    timelines.push('1-3 months');
                } else {
                    var valueIndex = timelines.indexOf('1-3 months');
                    if(valueIndex !== -1){
                        timelines.splice(valueIndex, 1);
                    }  
                }
                break;
            case 'months3to6': 
                if(event.target.checked){
                    timelines.push('3-6 months');
                } else {
                    var valueIndex = timelines.indexOf('3-6 months');
                    if(valueIndex !== -1){
                        timelines.splice(valueIndex, 1);
                    }  
                }
                break;
            case 'months6to12': 
                if(event.target.checked){
                    timelines.push('6-12 months');
                } else {
                    var valueIndex = timelines.indexOf('6-12 months');
                    if(valueIndex !== -1){
                        timelines.splice(valueIndex, 1);
                    }  
                }
                break;

        }
        customer[timelines] = timelines;
        this.setState({customer: customer});
    }

    handleLocationsChange = (event) => {
        console.log('Handle Change for Locations Check Event: ', event.target.id, event.target.checked);
        var customer = this.state.customer;
        var locations = this.state.customer.locations;

        if(event.target.checked){
            locations.push(event.target.id);
        } else {
            var valueIndex = locations.indexOf(event.target.id);
            if(valueIndex !== -1){
                locations.splice(valueIndex, 1);
            }  
        }

        customer[locations] = locations;
        this.setState({customer: customer});
    }

    handlePropertyTypeChange = (event) => {
        console.log('Handle Change for Property Type Check Event: ', event.target.id, event.target.checked);
        var customer = this.state.customer;
        var types = this.state.customer.type;

        if(event.target.checked){
            types.push(event.target.id);
        } else {
            var valueIndex = types.indexOf(event.target.id);
            if(valueIndex !== -1){
                types.splice(valueIndex, 1);
            }  
        }

        customer['type'] = types;
        this.setState({customer: customer});
    }

    handlePropertyStyleChange = (event) => {
        console.log('Handle Change for Property Style Check Event: ', event.target.id, event.target.checked);
        var customer = this.state.customer;
        var styles = this.state.customer.style;

        if(event.target.checked){
            styles.push(event.target.id);
        } else {
            var valueIndex = styles.indexOf(event.target.id);
            if(valueIndex !== -1){
                styles.splice(valueIndex, 1);
            }  
        }

        customer['style'] = styles;
        this.setState({customer: customer});
    }

    handlePropertyFeaturesChange = (event) => {
        console.log('Handle Change for Property Features Check Event: ', event.target.id, event.target.checked);
        var customer = this.state.customer;
        var features = this.state.customer.features;

        if(event.target.checked){
            features.push(event.target.id);
        } else {
            var valueIndex = features.indexOf(event.target.id);
            if(valueIndex !== -1){
                features.splice(valueIndex, 1);
            }  
        }

        customer['features'] = features;
        this.setState({customer: customer});
    }

    handleRatingChange = ( rating, event ) => {
        console.log('Handle Change for Customer Rating. New Value: ', rating, ', Event: ', event);

        event.preventDefault();
        event.stopPropagation();

        var customer = this.state.customer;
        customer['rating'] = rating;
        this.setState({customer: customer});
    }

    handleDateChange = (newDate) => {
        console.log('Date Change: ', newDate);
        var customer = this.state.customer;
        customer['visitDate'] = newDate;
        this.setState({customer: customer});
    }

    uploadFile(customerID, file){
        return new Promise((resolve, reject) => {
            var storageRef = firebase.storage().ref();
            var fileRef = storageRef.child('changesamui/customers/' + customerID + '/' + file.name);
            var uploadTask = fileRef.put(file);

            uploadTask.on('state_changed', 
            (snapshot) => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');

              var progressBar = document.getElementById('progress-customer-photo').querySelector('.progress-bar');
              if(progressBar){
                progressBar.style.width = progress + '%';
                progressBar.setAttribute('aria-valuenow', progress);
              }

              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            }, 
            (error) => {
              // Handle unsuccessful uploads
              console.error('Error uploading file...', error);
              reject(error);
            }, 
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                var customer = this.state.customer;
                console.log('File available at', downloadURL, 'for Customer ID: ', customerID);
                console.log('Customer: ', customer);
                customer.photo = downloadURL;
                firestore.collection('changeSamuiCustomers').doc(customerID).update(customer);
                console.log('Customer updated...');
                resolve();
              });
            }
          );        
        });
    }

    handleImageDrop = (file) => {

        console.log('Handle Image Drop: ', file);
        this.setState({ uploadFile: file });

    }


    render() {
        //if(this.state.redirect){
            //return <Navigate to={'/customers'} />
            //window.location.assign("/customers");
        //}
        const { user } = this.state;

        var action = this.state.action.charAt(0).toUpperCase() + this.state.action.substr(1).toLowerCase();
        var customer = this.state.customer;
        console.log("Rendering... Customer: ", customer);

        return(

        <>
        <Header user={user} />
        <BreadcrumbArea currentPage={ action + " Customer"} parentPage={{url: "/customers", name: "Customers"}} user={user} />
        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">

                            <form id='customerForm' className="needs-validation" noValidate  onSubmit={this.handleSave}>
                            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2">
                                <div className="col">

                                    <div className="mb-3">
                                        <label htmlFor="visitDate" className="form-label">Visit Date / Time</label>
                                        <DateTimePicker showLeadingZeros={true} className="form-control" id='visitDate' value={this.state.customer.visitDate} onChange={this.handleDateChange} />
                                        <div className="invalid-feedback">Please enter the date and time the customer visited...</div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="customerPhoto" className="form-label">Office<span className="text-danger"><sup>*</sup></span></label>

                                        <input className="form-check-input" type="radio" name="office" value="samui" id="officeSamui"
                                        checked={(this.state.customer.office && this.state.customer.office === 'samui')} 
                                        onChange={this.handleOptionChange } />&nbsp;
                                        <label htmlFor="officeSamui" className="form-value-label">Samui</label>
                                        &nbsp;&nbsp;
                                        <input className="form-check-input" type="radio" name="office" value="huahin" id="officeHuaHin"
                                        checked={this.state.customer.office && this.state.customer.office === 'huahin'} 
                                        onChange={this.handleOptionChange } />&nbsp;
                                        <label htmlFor="officeHuaHin" className="form-value-label">Hua Hin</label>
                                        &nbsp;&nbsp;
                                        <div className="invalid-feedback">Please select the appropriate office</div>
                                    </div>


                                    <div className="mb-3">
                                        <label className="form-label">Customer Type(s)<span className="text-danger">*</span></label>
                                        <div className="form-check form-check-inline ms-4">
                                            <input className="form-check-input" type="checkbox" id="buyer" checked={this.state.customer.buyer} onChange={this.handleCheckChange} />
                                            <label className="form-value-label" htmlFor="buyer">&nbsp;Buyer</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="seller" checked={this.state.customer.seller} onChange={this.handleCheckChange}/>
                                            <label className="form-value-label" htmlFor="seller">&nbsp;Seller</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="renter" checked={this.state.customer.renter} onChange={this.handleCheckChange} />
                                            <label className="form-value-label" htmlFor="renter">&nbsp;Renter</label>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="source" className="form-label">Source</label>
                                        <select className="form-select" aria-label="Lead Source" id="source" defaultValue="walk-in" value={this.state.customer.source} onChange={this.handleChange}>
                                            <option value="walk-in">Walk In</option>
                                            <option value="website">Website</option>
                                            <option value="referral">Referral</option>
                                            <option value="social">Facebook/Social Media</option>
                                            <option value="agent-development">Agent Development</option>
                                        </select>
                                     </div>

                                    <div className="mb-3">
                                        <label htmlFor="firstname" className="form-label">First Name<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="firstname" placeholder="John" value={this.state.customer.firstname} onChange={this.handleChange} required />
                                        <div className="invalid-feedback">Please enter the customer's first name</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="lastname" className="form-label">Last Name<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="lastname" placeholder="Smith" value={this.state.customer.lastname} onChange={this.handleChange} required />
                                        <div className="invalid-feedback">Please enter the customer's last name</div>
                                    </div>

                                </div>

                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="customerPhoto" className="form-label">Customer Photo</label>

                                        { (this.state.customer && this.state.customer.photo) && 
                                        <div id="imageDisplayArea" className="my-3 border rounded p-3">
                                            <img style={{ maxHeight: 200, maxWidth: 200 }} src={this.state.customer.photo}  className='rounded' />
                                        </div>
                                        }

                                        {this.state.uploadFile &&
                                        <div id="imagePreviewArea" className="my-3 border rounded p-3">
                                            <img style={{ maxHeight: 200, maxWidth: 200 }} src={URL.createObjectURL(this.state.uploadFile)} />
                                            <div id='progress-customer-photo' className="progress my-1" style={{ minHeight: '20px'}}>
                                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width: '0%'}}></div>
                                            </div>
                                        </div>
                                        }

                                        <FileUploader handleChange={this.handleImageDrop} name="file" types={["JPG", "JPEG", "PNG"]} multiple={false} />
                                    </div>
                                </div>

                                <div className="col">
                                    <label className="form-label">Phone</label>
                                    <div className="mb-3 input-group">
                                        {false &&
                                        <select className="form-select" aria-label="Phone Country Code" id="countryCode" defaultValue="+66">
                                            {phoneCountryCodes.map(code => (
                                                <option key={code.id} value={"+" + code.code}>{code.country + " +" + code.code}</option>
                                            ))}
                                        </select>
                                        }
                                        <input type="tel" className="form-control" id="phone" placeholder="+66 99 999 9999"  value={this.state.customer.phone} onChange={this.handleChange}  />
                                        <div className="invalid-feedback">Please enter the customer's phone number OR email</div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email address</label>
                                        <input type="email" className="form-control" id="email" placeholder="name@example.com" value={this.state.customer.email} onChange={this.handleChange}  />
                                        <div className="invalid-feedback">Please enter the customer's phone number OR email</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <legend className="col-form-label col-sm-1 pt-0">Rating</legend>
                                <div className="col">
                                    <div className="mb-3">
                                        <div>
                                            <a href="#1" onClick={ this.handleRatingChange.bind(this, 1) }>
                                            { this.state.customer.rating > 0 ? (
                                                <img src="/assets/svg/illustrations/star.svg" alt="Review rating" width="16" className='align-baseline' />
                                            ) : (
                                                <img src="/assets/svg/illustrations/star-muted.svg" alt="Review rating" width="16" className='align-baseline' />
                                            )}
                                            </a>
                                            <a href="#2" onClick={ this.handleRatingChange.bind(this, 2) }>
                                            { this.state.customer.rating > 1 ? (
                                                <img src="/assets/svg/illustrations/star.svg" alt="Review rating" width="16" className='align-baseline' />
                                            ) : (
                                                <img src="/assets/svg/illustrations/star-muted.svg" alt="Review rating" width="16" className='align-baseline' />
                                            )}
                                            </a>
                                            <a href="#3" onClick={ this.handleRatingChange.bind(this, 3) }>
                                            { this.state.customer.rating > 2 ? (
                                                <img src="/assets/svg/illustrations/star.svg" alt="Review rating" width="16" className='align-baseline' />
                                            ) : (
                                                <img src="/assets/svg/illustrations/star-muted.svg" alt="Review rating" width="16" className='align-baseline' />
                                            )}
                                            </a>
                                            <a href="#4" onClick={ this.handleRatingChange.bind(this, 4) }>
                                            { this.state.customer.rating > 3 ? (
                                                <img src="/assets/svg/illustrations/star.svg" alt="Review rating" width="16" className='align-baseline' />
                                            ) : (
                                                <img src="/assets/svg/illustrations/star-muted.svg" alt="Review rating" width="16" className='align-baseline' />
                                            )}
                                            </a>
                                            <a  href="#5" onClick={ this.handleRatingChange.bind(this, 5) }>
                                            { this.state.customer.rating > 4 ? (
                                                <img src="/assets/svg/illustrations/star.svg" alt="Review rating" width="16" className='align-baseline' />
                                            ) : (
                                                <img src="/assets/svg/illustrations/star-muted.svg" alt="Review rating" width="16" className='align-baseline' />
                                            )}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="budget" className="form-label">Budget / Sales Price<span className="text-danger">*</span></label>
                                        <div className='input-group'>
                                            { false &&
                                            <select className="form-select" aria-label="Currency" id="budgetCurrency" defaultValue="฿THB" >
                                                <option value="฿THB">฿ THB</option>
                                                <option value="$USD">$ USD</option>
                                                <option value="€EUR">€ EUR</option>
                                                <option value="£GBP">£ GBP</option>
                                                <option value="$AUD">$ AUD</option>
                                            </select>
                                            }
                                            <input type="text" className="form-control" id="budget" placeholder="45,000,000"  value={this.state.customer.budget} onChange={this.handleChange}  />
                                        </div>
                                        <div className="invalid-feedback">Please enter the customer's budget</div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <legend className="form-label col-sm-2 pt-0">Timeline</legend>
                                <div className="col">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="months1to3" checked={this.state.customer.timelines.includes('1-3 months')} onChange={this.handleTimelinesChange} />
                                        <label className="form-value-label" htmlFor="months1to3">&nbsp;1-3 months</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="months3to6" checked={this.state.customer.timelines.includes('3-6 months')} onChange={this.handleTimelinesChange} />
                                        <label className="form-value-label" htmlFor="months3to6">&nbsp;3-6 months</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="months6to12" checked={this.state.customer.timelines.includes('6-12 months')} onChange={this.handleTimelinesChange}  />
                                        <label className="form-value-label" htmlFor="months6to12">&nbsp;6-12 months</label>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <legend className="form-label col-sm-2 pt-0">Location</legend>
                                <div className="col">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="northeast" checked={this.state.customer.locations.includes('northeast')} onChange={this.handleLocationsChange} />
                                        <label className="form-value-label" htmlFor="northeast">&nbsp;Northeast</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="east" checked={this.state.customer.locations.includes('east')} onChange={this.handleLocationsChange} />
                                        <label className="form-value-label" htmlFor="east">&nbsp;East</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="southeast"  checked={this.state.customer.locations.includes('southeast')} onChange={this.handleLocationsChange}/>
                                        <label className="form-value-label" htmlFor="southeast">&nbsp;Southeast</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="south" checked={this.state.customer.locations.includes('south')} onChange={this.handleLocationsChange} />
                                        <label className="form-value-label" htmlFor="south">&nbsp;South</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="southwest" checked={this.state.customer.locations.includes('southwest')} onChange={this.handleLocationsChange} />
                                        <label className="form-value-label" htmlFor="southwest">&nbsp;Southwest</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="west" checked={this.state.customer.locations.includes('west')} onChange={this.handleLocationsChange} />
                                        <label className="form-value-label" htmlFor="west">&nbsp;West</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="northwest"  checked={this.state.customer.locations.includes('northwest')} onChange={this.handleLocationsChange}/>
                                        <label className="form-value-label" htmlFor="northwest">&nbsp;Northwest</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="north"  checked={this.state.customer.locations.includes('north')} onChange={this.handleLocationsChange}/>
                                        <label className="form-value-label" htmlFor="north">&nbsp;North</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <legend className="form-label col-sm-2 pt-1">Property Type(s)</legend>
                                <div className="col">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="villa" checked={this.state.customer.type.includes('villa')} onChange={this.handlePropertyTypeChange} />
                                        <label className="form-value-label" htmlFor="villa">&nbsp;Villa</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="townhouse" checked={this.state.customer.type.includes('townhouse')} onChange={this.handlePropertyTypeChange} />
                                        <label className="form-value-label" htmlFor="townhouse">&nbsp;Townhouse</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="condo" checked={this.state.customer.type.includes('condo')} onChange={this.handlePropertyTypeChange} />
                                        <label className="form-value-label" htmlFor="condo">&nbsp;Condo</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="apartment" checked={this.state.customer.type.includes('apartment')} onChange={this.handlePropertyTypeChange} />
                                        <label className="form-value-label" htmlFor="apartment">&nbsp;Apartment</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="land" checked={this.state.customer.type.includes('land')} onChange={this.handlePropertyTypeChange} />
                                        <label className="form-value-label" htmlFor="land">&nbsp;Land</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="commercial" checked={this.state.customer.type.includes('commercial')} onChange={this.handlePropertyTypeChange} />
                                        <label className="form-value-label" htmlFor="commercial">&nbsp;Commercial</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <legend className="form-label col-sm-2 pt-2">Style</legend>
                                <div className="col">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="modern" checked={this.state.customer.style.includes('modern')} onChange={this.handlePropertyStyleChange} />
                                        <label className="form-value-label" htmlFor="modern">&nbsp;Modern</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" value="" id="traditional" checked={this.state.customer.style.includes('traditional')} onChange={this.handlePropertyStyleChange}  />
                                        <label className="form-value-label" htmlFor="traditional">&nbsp;Traditional</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input check-text-fix" type="checkbox" value="" id="other" checked={this.state.customer.style.includes('other')} onChange={this.handlePropertyStyleChange}  />
                                    <input type="text" className="form-control form-control-sm" id="otherstyle" placeholder="Other / describe..."  value={this.state.customer.otherstyle} onChange={this.handleChange}  />
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <legend className="form-label col-sm-2">Features</legend>
                                <div className="col">
                                    <div className="row pt-2">
                                        <div className="col-md-3">
                                            <div className="form-check form-check-inline">
                                            <label className="form-label" htmlFor="bedrooms">&nbsp;Bedrooms (minimum)</label>
                                            <select className="form-select" aria-label="Bedrooms" id="bedrooms" value={this.state.customer.bedrooms}  onChange={this.handleChange}>
                                                    <option value="0">Choose one...</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11+</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check form-check-inline">
                                                <label className="form-label" htmlFor="bathrooms">&nbsp;Bathrooms (minimum)</label>
                                                <select className="form-select" aria-label="Bathrooms" id="bathrooms" value={this.state.customer.bathrooms}  onChange={this.handleChange}>
                                                    <option value="0">Choose one...</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11+</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">

                                            <div className="form-check form-check-inline">
                                                <label className="form-label" htmlFor="sizehome">&nbsp;Home Size (m<sup>2</sup>)</label>
                                                <input type="number" className="form-control" id="sizehome" placeholder="Enter a number..."  value={this.state.customer.sizehome}  onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check form-check-inline">
                                                <label className="form-label" htmlFor="sizeland">&nbsp;Land Size (m<sup>2</sup>)</label>
                                                <input type="number" className="form-control" id="sizeland" placeholder="Enter a number..."  value={this.state.customer.sizeland}  onChange={this.handleChange} />
                                            </div>
                                        </div>                                      
                                    </div>
                                    <div className="row pt-2">
                                        <div className="col">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" value="" id="pool" checked={this.state.customer.features.includes('pool')} onChange={this.handlePropertyFeaturesChange}  />
                                                <label className="form-value-label" htmlFor="pool">&nbsp;Pool</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" value="" id="spa" checked={this.state.customer.features.includes('spa')} onChange={this.handlePropertyFeaturesChange}  />
                                                <label className="form-value-label" htmlFor="spa">&nbsp;Spa</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" value="" id="beachfront" checked={this.state.customer.features.includes('beachfront')} onChange={this.handlePropertyFeaturesChange}  />
                                                <label className="form-value-label" htmlFor="beachfront">&nbsp;Beach Front</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" value="" id="seaview" checked={this.state.customer.features.includes('seaview')} onChange={this.handlePropertyFeaturesChange}  />
                                                <label className="form-value-label" htmlFor="seaview">&nbsp;Sea View</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" value="" id="carport" checked={this.state.customer.features.includes('carport')} onChange={this.handlePropertyFeaturesChange}  />
                                                <label className="form-value-label" htmlFor="carport">&nbsp;Carport</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" value="" id="garage" checked={this.state.customer.features.includes('garage')} onChange={this.handlePropertyFeaturesChange}  />
                                                <label className="form-value-label" htmlFor="garage">&nbsp;Garage</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="notes" className="form-label">Notes</label>
                                <textarea className="form-control" id="notes" rows="3" value={this.state.customer.notes}  onChange={this.handleChange} ></textarea>
                            </div>

                            <div className="mb-3">
                                <button type="submit" className="btn btn-primary mb-3 formButton"><i className="bi bi-cloud-check-fill"></i>&nbsp;Save Customer</button>
                                <button type="button" className="btn btn-outline-secondary mb-3 ms-3 formButton" onClick={this.handleCancel}><i className="bi bi-x-octagon-fill"></i>&nbsp;Cancel</button>
                            </div>

                            {this.state.showAlert && (

                            <div className="position-fixed top-50 start-50 translate-middle" style={{zIndex: 11}}>
                                <div id="liveToast" className="toast show border border-danger bg-light text-black" role="alert" aria-live="assertive" aria-atomic="true">
                                    <div className="toast-header">
                                        <h5 className="me-auto"><span className="text-danger"><i className="bi bi-exclamation-triangle-fill"></i></span> Error!</h5>
                                    </div>
                                    <div className="toast-body">
                                        Please correct the missing or invalid info above.
                                    </div>
                                </div>
                            </div>

                            )}


                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
        );
    }

    componentDidMount(){
        this.onAuthStateChangedObserver = auth.onAuthStateChanged((user) => {
          if (user) {
            var uid = user.uid;
            if(this.state.customerID){
                this.getCustomer();
            }
            this.setState({ user: user });
          } else {
    
    
            this.setState({ 
              user: null,    
            });
            
            console.log('User is logged out...');
          }
        });
      }
    }
    
export default CustomerAddEdit;
    