import './App.css';
import React from 'react';
import firebase, { analytics, auth, firestore, storage } from './firebase';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Listings from './Listings';
import Customers from './Customers';
import CustomerAddEdit from './CustomerAddEdit';
import ListingAddEdit from './ListingAddEdit';
import ListingPrint from './ListingPrint';
//import OfficeStatusHuaHin from './OfficeStatusHuaHin';
import DailyStatusUpdate from './DailyStatusUpdate';
import OfficeStatusSamui from './OfficeStatusSamui';
import Unauthorized from './Unauthorized';
import Header from './Header';
import Footer from './Footer';
import UserList from './UserList';
import MessageList from './MessageList';
import OfficeStatusLog from './OfficeStatusLog';
import TestPage from './TestPage';

function AdminHome(props){
  const {user, login, loading} = props;

  const handleGoogleLogout = () => {

    auth.signOut().then(() => {
      // Sign-out successful.
  
    }).catch((error) => {
      // An error happened.
    });
  }
  
  const handleGoogleLogin = () => {
  
    var provider = new firebase.auth.GoogleAuthProvider();  
  
    auth.signInWithPopup(provider)
    .then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      var credential = result.credential;
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      console.log(user);

      firestore.collection('users').doc(user.uid).get().then((userDoc) =>{
        console.log('Checking to see if user exists...');
        if(!userDoc.exists){
          console.log('User does not exist. Adding...');
          firestore.collection('users').doc(user.uid).set({
            admin: false,
            displayName: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            photoURL: user.photoURL,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
          }).then((newUserDocRef) =>{
            console.log('Added new User Doc with ID: ', newUserDocRef.uid);
          }).catch((error) => {
            console.error('Unable to add User Doc to FireStore for Authenticated User: ', user, error);
          })

        }

      });

    }).catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });  
  }
  


  return(
    <>
    <Header user={user} />

    <div className="navbar-dark bg-dark" style={{ backgroundImage: "url(./assets/svg/components/wave-pattern-light.svg)" }}>
      <div className="container content-space-1 content-space-b-lg-3">
        <div className="row align-items-center">
          <div className="col">
            <div className="d-none d-lg-block">
              { window.location.hostname === 'admin.changehuahin.com' ? 
                <h1 className="h2 text-white">Change Hua Hin Admin Console</h1>
              :
                <h1 className="h2 text-white">Change Samui Admin Console</h1>
              }
            </div>

            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-light mb-0">
              <li className="breadcrumb-item" aria-current="page">{ window.location.hostname }</li>
                <li className="breadcrumb-item active" aria-current="page">Admin</li>
              </ol>
            </nav>
          </div>

          <div className="col-auto">
            { user &&
            <div className="d-none d-lg-block">
                <span>{ user.displayName }&nbsp;</span>
              <a className="btn btn-soft-light btn-sm" onClick={ handleGoogleLogout }>Log out</a>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
    { user && 
      <>
      { (user.admin === true) ? (
          <>
          <div className="container">
            <div className="d-flex justify-content-center">
              <div className="text-center mt-4" role="status">
                <h2>Welcome {user.displayName}</h2>
              </div>          
            </div>
            <div className="text-center">
            <div className="row justify-content-center">
                  <div className="col-md-4 col-sm-12">
                    <div className="card my-3">
                      <div className='text-center mt-5'>
                      <a className="link-dark" href="/customers">
                      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" className="bi bi-person-circle" viewBox="0 0 16 16">
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                      </svg>
                      </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title"><a className="link-dark" href="/customers">Customers</a></h5>
                        <p className="card-text"><a className="link-dark" href="/customers">View, Find, Edit, Add Customers</a></p>
                        <a href="/customers" className="btn btn-primary">Customers</a>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-12">
                    <div className="card my-3">
                    <div className='text-center mt-5'>
                    <a className="link-dark" href="/listings">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" className="bi bi-house-fill" viewBox="0 0 16 16">
                      <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                      <path d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                    </svg>
                    </a>
                    </div>


                      <div className="card-body">
                        <h5 className="card-title"><a className="link-dark" href="/listings">Listings</a></h5>
                        <p className="card-text"><a className="link-dark" href="/listings">View, Find, Edit, Add Listings</a></p>
                        <a href="/listings" className="btn btn-primary">Listings</a>
                      </div>
                    </div>
                  </div>
                </div>
             </div>
          </div>    
          </>
        ) : (
          <>
          <Header user={user} />
          <Unauthorized user={user} />
          </>
        ) 
    }
    </>
  }
  { login &&
      <>
      <div className="container">
        <div className=" min-height-400 d-flex justify-content-center">
        <div className="text-primary text-center" role="status" style={{marginTop: 'auto', marginBottom: 'auto'}}>
          <h2>Please Login</h2>
            <button type="submit" className="btn btn-outline-primary" onClick={ handleGoogleLogin }>
              <span className="d-flex justify-content-center align-items-center">
                <img className="avatar avatar-xss me-2" src="../assets/svg/brands/google-icon.svg" alt="Image Description" />
                  Login with Google
              </span>
            </button>
          </div>
        </div>
      </div>    
      </>
  }  
  { loading &&
        <>
        <div className="container">
          <div className=" min-height-400 d-flex justify-content-center">
            <div className="text-primary text-center" role="status" style={{marginTop: 'auto', marginBottom: 'auto'}}>
              <h2>Loading...</h2>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        </>

  }
  <Footer />

  </>
);
}

class App extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      user: null, 
      loading: true,
    }
  }


  render() {
    console.log('Render App.js');
    const { user, loading } = this.state;
    if(user){
      return (

        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <Routes>
            <Route path="/" element={<AdminHome user={user} />} />
            <Route path="/customers" element={<Customers user={user} />} />
            <Route handler={App}>
              <Route path="/customer/add" element={<CustomerAddEdit user={user} action="add" />} />
              <Route path="/customer/:customerID" element={<CustomerAddEdit user={user} action="edit" />} />
            </Route>
            <Route path="/listings" element={<Listings user={user} />} />
            <Route handler={App}>
              <Route path="/listing/add" element={<ListingAddEdit user={user} action="add" />} />
              <Route path="/listing/print/:listingID" element={<ListingPrint user={user} action="print-agent" />} />
              <Route path="/listing/print-customer/:listingID" element={<ListingPrint user={user} action="print-customer" />} />
              <Route path="/listing/:listingID" element={<ListingAddEdit user={user} action="edit" />} />
            </Route>
            <Route path="/officeStatusSamui" element={<OfficeStatusSamui user={user} />} />
            <Route path="/dailyStatusUpdate" element={<DailyStatusUpdate user={user} />} />
            { (user && user.superadmin === true) &&
            <>
            <Route path="/userList" element={<UserList user={user} />} />
            <Route path="/messageList" element={<MessageList user={user} />} />
            <Route path="/officeStatusLog" element={<OfficeStatusLog user={user} />} />
            </>
            }
          </Routes>
      </BrowserRouter>

      );
      } else {
        if(!loading){
          console.log("1");
          return(
            <AdminHome login={true} />
          );          
        } 
      } 
      console.log("2");
      if(document.location.pathname.indexOf('test') !== -1){
        return(
          <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <Routes>
            <Route path="/test/:token" element={<TestPage />} />
          </Routes>
          </BrowserRouter>
        );

      } else {
        return(
          <AdminHome loading={true} />
        );

      }
  }

  componentDidMount(){
    this.onAuthStateChangedObserver = auth.onAuthStateChanged((user) => {
      if (user) {

        var that = this;
        this.userDocumentSnapshotListener = firestore.collection('users').doc(user.uid).onSnapshot((snapshot) => {
          const data = snapshot.data();
          console.log(data);
          user.admin = data.admin;
          user.timezone = data.timezone;
          user.superadmin = false;
          if(data.superadmin){
            user.superadmin = data.superadmin;
          }
          if(data.profilePhoto){
            user.profilePhoto = data.profilePhoto;
          }
          user.phone = data.phoneNumber
          console.log(user);
          if(user.admin){
            console.log('User is admin!', user);
          }
          that.setState({user: user, loading: false});
        }, (error) => {
          console.error('Error accessing snapshot: ', error);
        });
      } else {
        this.setState({user: null, loading: false});
        console.log('User is logged out...');
      }
    });
    intializeThemeComponents();    
  }

  componentWillUnmount(){
    if (this.onAuthStateChangedObserver) {
      this.onAuthStateChangedObserver();
    }

    if (this.userDocumentSnapshotListener) {
      this.userDocumentSnapshotListener();
    }
  }

}

export default App;
