import './CustomerAddEdit.css';
import React from 'react';
//import { Navigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import BreadcrumbArea from './BreadcrumbArea';
import firebase, { analytics, auth, firestore, storage } from './firebase';
import DateTimePicker from 'react-datetime-picker'
import { FileUploader } from "react-drag-drop-files";


class UserList extends React.Component{

    constructor(props){
      super(props);
      console.log('UserList Props: ', this.props);

      this.state = {
        user: props.user,
        loaded: false,
        userList: []
      }
      
      console.log('UserList Constructor', this.state);
    }

    getUserList = () => {
        var userListRef = firestore.collection('users').orderBy("admin", "desc").orderBy("displayName");
        userListRef.get().then((userListSnap) => {
            var userList = [];
            userListSnap.forEach((user) => {
                //console.log(user.id, " => ", user.data());
                var userObject = user.data();
                userObject.id = user.id;
                userList.push(userObject)
            });
            console.log("Storing UserList in State: ", userList);
            this.setState({userList: userList, loaded: true});
            
        }).catch((error) =>{
            console.error('Error getting users: ', error);
        });
    }

    deleteUser = (userID, event) => {
        if(confirm("Are you sure you want to delete this user?")){

            event.preventDefault();
            event.stopPropagation();

            firestore.collection('users').doc(userID).delete().then(() => {
                console.log("User successfully deleted!");
                this.getUserList();
            }).catch((error) => {
                console.error("Error removing user: ", error);
            });
        }
    }

    superChange = (userID, event) => {
        if(confirm("Are you sure you want to change super user status for this user?")){
            console.log("Super Change", userID);

            event.preventDefault();
            event.stopPropagation();

            var updateValues = { superadmin: event.target.checked };
            if(event.target.checked){
                updateValues = { admin: true, superadmin: true }
            }

            firestore.collection('users').doc(userID).update(updateValues).then(() => {
                this.getUserList();
            });
        }
    }

    adminChange = (userID, event) => {
        console.log("Admin Change", userID, event);

        event.preventDefault();
        event.stopPropagation();

        var updateValues = { admin: event.target.checked };
        if(event.target.checked === false){
            updateValues = { admin: false, superadmin: false }
        }


        firestore.collection('users').doc(userID).update(updateValues).then(() => {
            this.getUserList();
        });
    }

    render() {
        var userList = this.state.userList;
        var user = this.state.user;
        var loaded = this.state.loaded;
        console.log("Rendering... User List: ", userList.length, userList);

        if(userList.length === 0){
            return(
              <>
                <Header user={user} />
                <BreadcrumbArea currentPage={"Loading..."} />
                <div className="container">
                  <div className=" min-height-400 d-flex justify-content-center">
                    <div className="text-primary text-center" role="status" style={{marginTop: 'auto', marginBottom: 'auto'}}>
                        <h2>Loading...</h2>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </>
            )    
        }  

        console.log('UserList length != 0');
        return(

        <>
        <Header user={user} />
        <BreadcrumbArea currentPage={"User List"} user={user} />
        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row row-cols-3">
                                    { userList.map(appUser => (
                                        <div key={appUser.id} className="col mb-2">

                                            <div className="card border">
                                                { appUser.photoURL &&
                                                    <img src={ appUser.photoURL} className="card-img-top" alt={appUser.displayName} />
                                                }
                                                <div className="card-body">
                                                    <div className="card-title">
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <h2 className="h3 mb-0">{ appUser.displayName }</h2>
                                                                <p className="text-muted small mb-0">{ appUser.id }</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-text small">
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="checkAdmin" checked={ appUser.admin } onChange={ this.adminChange.bind(this, appUser.id) } />
                                                            <label className="form-check-label" htmlFor="checkAdmin">Admin</label>
                                                        </div>                                                        
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="checkSuperAdmin" checked={ appUser.superadmin } onChange={ this.superChange.bind(this, appUser.id) } />
                                                            <label className="form-check-label" htmlFor="checkSuperAdmin">Superadmin</label>
                                                        </div>                                                        
                                                        { !appUser.superadmin &&
                                                            <button type="button" className="btn btn-outline-secondary float-end" onClick={ this.deleteUser.bind(this, appUser.id) }><i className="bi bi-trash-fill"></i></button>
                                                        }
                                                        <p className="mb-0"><a href={"mailto:" + appUser.email }>{ appUser.email }</a></p>
                                                        <p className="mb-0"><b>Time Zone: </b>{ appUser.timezone }</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
        );
    }

    componentDidMount(){
        this.onAuthStateChangedObserver = auth.onAuthStateChanged((user) => {
          if (user) {
            var uid = user.uid;
            this.setState({ user: user });
            this.getUserList();
          } else {
        
            this.setState({ 
              user: null,    
            });        
            console.log('User is logged out...');
          }
        });
      }
    }
    
export default UserList;
    