import firebase from "firebase/compat/app";

import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/performance";

firebase.initializeApp({
  /*
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  */
  apiKey: "AIzaSyDTuIR_dj3FWG6hUnAsVEyAw0VqwUGookI",
  authDomain: "change-real-estate.firebaseapp.com",
  databaseURL: "https://change-real-estate.firebaseio.com",
  projectId: "change-real-estate",
  storageBucket: "change-real-estate.appspot.com",
  messagingSenderId: "839812614565",
  appId: "1:839812614565:web:a35ccde3cfb7d92101804c",
  measurementId: "G-MKZGC8CQJ1"  
});

export default firebase;

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const performance = firebase.performance();

export let analytics;

if (process.env.NODE_ENV !== "test") {
  analytics = firebase.analytics();
}
