import './Header.css';
import React from 'react';

function Header(props){

    console.log('Header Props ', props);

    const handleGoogleLogout = () => {
      auth.signOut().then(() => {
        // Sign-out successful.
        navigate("/", {replace:true});        
    
      }).catch((error) => {
        // An error happened.
      });
    }

    
    return(
  
      <header id="header" className="navbar navbar-expand-lg navbar-end navbar-light bg-white">  
        <div className="container">

          <div className="navbar-nav-wrap">
            <div className="navbar-brand-wrapper">
            { window.location.hostname === 'admin.changehuahin.com' ? 

            <a className="navbar-brand" href="/" aria-label="Change Samui Admin">
              <img className="navbar-brand-logo" src="/img/logo.huahin.1922.png" alt="Change Hua Hin" />
            </a>

            :

            <a className="navbar-brand" href="/" aria-label="Change Hua Hin Admin">
              <img className="navbar-brand-logo" src="/img/logo.1922.png" alt="Change Samui" />
            </a>

            }
            </div>

            <button type="button" className="navbar-toggler ms-auto" data-bs-toggle="collapse" data-bs-target="#navbarNavMenuHeight" aria-label="Toggle Navigation" aria-expanded="false" aria-controls="navbarNavMenuHeight">
              <span className="navbar-toggler-default">
                <i className="bi-list"></i>
              </span>
              <span className="navbar-toggler-toggled">
                <i className="bi-x"></i>
              </span>
            </button>

            { props.user &&  
            <nav className="navbar-nav-wrap-col collapse navbar-collapse" id="navbarNavMenuHeight">
              <ul className="navbar-nav">

                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarHeightDropdownSubMenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">Actions</a>
                  <div className="dropdown-menu" aria-labelledby="navbarHeightDropdownSubMenu" style={{minWidth: 230 }}>
                    <a className="dropdown-item" href="/customers">View Customer List</a>
                    <a className="dropdown-item" href="/customer/add">Add Customer</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/listings">View Listings</a>
                    <a className="dropdown-item" href="/listing/add">Add Listing</a>
                    <div className="dropdown-divider"></div>

                    { (props.user.superadmin === true) &&
                    <>
                    <a className="dropdown-item" href="/userList">User List</a>
                    <a className="dropdown-item" href="/messageList">Message List</a>
                    <a className="dropdown-item" href="/officeStatusLog">Office Status Log</a>
                    <div className="dropdown-divider"></div>
                    </>
                    }

                    <a className="dropdown-item" href="/officeStatusSamui">Update Office Open/Closed Status</a>
                    <a className="dropdown-item" href="/dailyStatusUpdate">Daily Office Status Report</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" onClick={ handleGoogleLogout }>Log out&nbsp;{ props.user.displayName }</a>
                  </div>
                </li>
              </ul>
            </nav>
            }
          </div>
        </div>
      </header>  
    );  
  }


export default Header;

  