import './ListingAddEdit.css';
import React from 'react';
import BreadcrumbArea from './BreadcrumbArea';
import Header from './Header';
import Footer from './Footer';
import firebase, { auth, firestore } from './firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
//import DateTimePicker from 'react-datetime-picker'
import ReactQuill from 'react-quill';
import { FileUploader } from "react-drag-drop-files";
import 'react-quill/dist/quill.snow.css';
import {Modal} from 'bootstrap';
import exifr from 'exifr'
import GoogleMapReact from 'google-map-react';
import ImageList from './ImageList';
import { DndProvider } from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import update from "immutability-helper";
import features from "./features.json";

const GoogleMapContainerComponent = ({ text }) => <div className='pinClass'>{text}</div>;

const emptyListing = {
    id: '',
    name: '', 
    villaName: '', // Villa Amaze, (from spreadsheet)
    description: '',
    kind: 'sale', //sale or rent
    images: [],
    type: 'villa', //or 'land', 'condo', 'townhouse', 'house', 'apartment', 'commercial'
    visible: false,
    isSold: false,
    mapCoordinates: {latitude: 9.560027, longitude: 100.029887}, //{lat: 0, lng: 0}, //geo-point (lat, lng),
    country: 'Thailand',
    province: 'Surat Thani',
    city: 'Ko Samui District',
    region: 'Bo Put',
    geoResult: { country: ['Thailand'], province: ['Surat Thani'], city: ['Ko Samui District'], region: ['Bo Put']},
    bed: 0,
    bath: 0,
    landSize: 0,
    price: 0, //0 for rental; number for sale,
    propertySize: 0, // number/null,
    features: [], // array of feature strings
    internalNotes: '',
    commission: '', // 5%, (from spreadsheet)
    // NEW / CLEAN FIELDS:
    displaySites: [], // ['changesamui', 'samuiOfficeTV', 'samuioffplan', etc.],
    dateList: new Date(), //timestamp (calculate 90 days from list date as "New"),
    owner: null, // 'Mr. Smith +66998887777', (from spreadsheet)
}


class ListingAddEdit extends React.Component{

    constructor(props){
      super(props);
      console.log('ListingAddEdit Props: ', this.props);

      const pathParts = document.location.pathname.split("/");

      let listingID = '';
      if(pathParts.length === 3){
        listingID = pathParts[2];
      }
      if (listingID === 'add'){
        listingID = '';
      }

      this.state = {
        user: props.user,
        listingID: listingID,
        listing: emptyListing,
        uploadFiles: null,
        action: props.action,
        redirect: false,
        activePDFTarget: 0,
        pdfImages: [],
      }
      console.log('ListingAddEdit Constructor', this.state);
    }

    getListing = () => {
        let listingID = this.state.listingID;
        //var listingRef = firestore.collection('changeSamuiListings').doc(listingID);
        var listingRef = firestore.collection('changeSamuiListings2022').doc(listingID);
        listingRef.get().then((listingDoc) => {
            if(listingDoc.exists){
                var listing = listingDoc.data();

                if(!listing.isSold){
                    listing.isSold = false;
                }

                if(!listing.country){
                    listing.country = 'Thailand';
                }
                if(!listing.province){
                    listing.province = 'Surat Thani';
                }
                if(!listing.city){
                    listing.city = 'Ko Samui District';
                }
                if(!listing.region){
                    listing.region = 'Bo Put';
                }           
                if(!listing.geoResult){
                    listing.geoResult = { country: ['Thailand'], province: ['Surat Thani'], city: ['Ko Samui District'], region: ['Bo Put']}
                }

                if(listing.features && listing.features.length === 0){
                    if (listing.seaview === true){
                        listing.features.push('sea-view');    
                    }
                    if (listing.beachfront === true){
                        listing.features.push('beach-front');    
                    }
                    if (listing.privatePool === true){
                        listing.features.push('private-pool');    
                    }
                    if (listing.privateSpa === true){
                        listing.features.push('private-spa');    
                    }
                    if (listing.privateWell === true){
                        listing.features.push('well-water');    
                    }
                    if (listing.governmentWater === true){
                        listing.features.push('government-water');    
                    }
                    if (listing.solarElectric === true){
                        listing.features.push('solar-electric');    
                    }
                    if (listing.governmentElectric === true){
                        listing.features.push('government-electric');    
                    }
                    if (listing.batteryBackup === true){
                        listing.features.push('battery-backup');    
                    }
                    if (listing.generatorBackup === true){
                        listing.features.push('generator-backup');    
                    }
                    if (listing.highspeedInternet === true){
                        listing.features.push('fiber-internet');    
                    }
                    if (listing.privateParking === true){
                        listing.features.push('parking');    
                    }
                    if (listing.coveredParking === true){
                        listing.features.push('covered-parking');    
                    }
                    if (listing.evCharging === true){
                        listing.features.push('ev-charging');    
                    }                    
                }

                console.log("Listing after load and edit: ", listing);
                if(listing.imagesForPrint){
                    this.setState({pdfImages: listing.imagesForPrint, listing: listing});
                } else {
                    this.setState({listing: listing});
                }
            } else {
                console.log("No document for listingID: ", listingID);
            }
        }).catch((error) =>{
            console.error('Error getting document for listingID: ', listingID, error);
        });

    }

    titleCase = (inputString) => {
        return inputString.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }

    prettyFeatureName = (inputString) => {
        let newStr = inputString.replace(/-/g, " ");
        return newStr.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }

    featureExists = (feature, featureArray) => {
        for (let i = 0; i < featureArray.length; i++) {
            if (featureArray[i] === feature) {
                return true;
            }
        }
        return false;
    }

    disableButtons = (disable) => {

        var buttons = document.getElementsByClassName('formButton');

        for (let i = 0; i < buttons.length; i++) {
            if(disable === true){
                console.log('Disabling button: ', i);
                buttons[i].setAttribute('disabled', 'disabled');
            } else {
                console.log('Enabling button: ', i);
                buttons[i].removeAttribute('disabled');
            }
        }
    }
    
    uploadFile(listingID, file, index){
        return new Promise((resolve, reject) => {
            var storageRef = firebase.storage().ref();
            var fileRef = storageRef.child('changesamui/listings2022/' + listingID + '/' + file.name);
            var uploadTask = fileRef.put(file);

            uploadTask.on('state_changed', 
            (snapshot) => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');

              var progressBar = document.getElementById('progress-' + index).querySelector('.progress-bar');
              if(progressBar){
                progressBar.style.width = progress + '%';
                progressBar.setAttribute('aria-valuenow', progress);
              }

              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            }, 
            (error) => {
              // Handle unsuccessful uploads
              console.error('Error uploading file...', error);
              reject(error);
            }, 
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...

              console.log('File Upload Complete! ', uploadTask.snapshot.ref);

              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                var listing = this.state.listing;
                console.log('File available at', downloadURL, 'for Listing ID: ', listingID);
                console.log('Listing: ', listing);

                //CHECK FOR EXISTING MATCHING IMAGE (all but the key will match)
                //https://firebasestorage.googleapis.com/v0/b/change-real-estate.appspot.com/o/changesamui%2Flistings2022%2FJXVGmhlke5gcQkZxfg2x%2FVilla%20Signature%20008.jpg?alt=media&token=25b4b2c7-7df8-41b2-8601-660096f58ce8
                //Match up to the "Token"
                //regex to match all but last parameter of URL

                let downloadURLMatch = downloadURL.match('(.*)&token=.*')[1];
                console.log('downloadURLMatch: ', downloadURLMatch)
                let hasMatch = false;

                for (let i = 0; i < listing.images.length; i++) {

                    let currMatch = listing.images[i].match('(.*)&token=.*');
                    console.log('currentImageMatch: ', currMatch);
                    if(currMatch && currMatch[1] === downloadURLMatch){
                        //replace current item
                        listing.images[i] = downloadURL;
                        hasMatch = true;
                        console.log('Replaced Existing Image');
                    }
                }
                
                if(hasMatch === false){
                    listing.images.push(downloadURL);
                    console.log('Added New Image');
                }

                firestore.collection('changeSamuiListings2022').doc(listingID).update(listing);
                console.log('Listing updated...');
                resolve();
              });
            }
          );        
        });
    }

    geolocatePhotos = (event) => {
        var fileList = this.state.uploadFiles;
        var fileLength = fileList.length;
        var photoGeo = {};
        console.log(fileLength);
        var fileLoop = new Promise((resolve, reject) => {
            Array.from(fileList).forEach(async(file, index) => {
                var geoInfo = await exifr.gps(file);
                if(geoInfo){
                    photoGeo[file.name] = geoInfo;
                    console.log('Image GPS: Lat: ', geoInfo.latitude, ' Long: ', geoInfo.longitude);
                } else {
                    console.log('No GPS info available for this photo...')
                }    
                if(index === (fileLength-1)){
                    console.log('Resolving...', photoGeo);
                    resolve();
                    //this.forceUpdate();
                }
            });
        });

        fileLoop.then(() => {
            console.log('HERE', photoGeo);
            this.setState({ photoGeo: photoGeo});
        });
        //event.preventDefault();
        //event.stopPropagation();
    }
    
    uploadFiles(listingID) {
        return new Promise((resolve, reject) => {
            var fileList = this.state.uploadFiles;
            console.log(fileList);
            var promises = [];
            Array.from(fileList).forEach((file, index) => {
                promises.push(this.uploadFile(listingID, file, index));
                console.log('Upload files... Index: ', index);
            });
            Promise.all(promises).then((results) => {
                console.log('All promises resolved...');
                resolve();         
            });
        });
    
    }

    updateListing = () => {

        var listing = this.state.listing;
        listing.dateUpdated = firebase.firestore.FieldValue.serverTimestamp();
        listing.updatedBy = { name: this.state.user.displayName, email: this.state.user.email, uid: this.state.user.uid };
        firestore.collection('changeSamuiListings2022').doc(this.state.listingID).update(listing)
        .then(() => {
            console.log('🤓 Drag and Drop Update Completed 🤓');
            this.setState({listing: listing});
        }).catch((error) => {
            console.error('Error updating listing: ', error);
        });
    }

    handleSave = (event) =>{
        event.preventDefault();
        event.stopPropagation();

        this.disableButtons(true);

        console.log('Save clicked! Validating...');
        var listing = this.state.listing;

        var form = document.forms['listingForm'];
        var isValid = true;

        if(listing.name.length === 0){
            isValid = false;
            form['name'].classList.add('is-invalid');
            form['name'].classList.remove('is-valid');
        } else {
            form['name'].classList.add('is-valid');
            form['name'].classList.remove('is-invalid');
        }

        if(isValid === false){
            console.log('Form is invalid...');


        } else {

            console.log('Form is valid. Listing ID: ', this.state.listingID, ' Listing: ', listing);
            
            //Updating Existing Listing...
            if(this.state.listingID && this.state.listingID.length > 1){
                listing.dateUpdated = firebase.firestore.FieldValue.serverTimestamp();
                listing.updatedBy = { name: this.state.user.displayName, email: this.state.user.email, uid: this.state.user.uid };
                firestore.collection('changeSamuiListings2022').doc(this.state.listingID).update(listing)
                .then(async() => {
                    console.log("Listing updated with Listing ID: ", this.state.listingID);

                    //Upload images before redirecting...
                    if(this.state.uploadFiles){

                        console.log('Upload Loop starting...');
                        this.uploadFiles(listing.id).then(() =>{
                            console.log('Upload files complete!!!');
                            this.disableButtons(false);
                            this.setState({uploadFiles: null});
                        });
                        console.log('Upload Loop complete...');
                    } else {
                            this.disableButtons(false);
                            window.location.assign("/listings");
                    }                                        

                })
                .catch((error) => {
                    console.error('Error updating listing: ', error);
                });
    

            } else {
                listing.dateCreated = firebase.firestore.FieldValue.serverTimestamp();
                listing.createdBy = { name: this.state.user.displayName, email: this.state.user.email, uid: this.state.user.uid };
                var listingRef = firestore.collection('changeSamuiListings2022').doc();
                console.log(listingRef);
                listing.id = listingRef.id;
                listingRef.set(listing).then((listingRef) => {
                    console.log("Listing written with Listing ID: ", listing.id);

                    //Upload images before redirecting...
                    if(this.state.uploadFiles){

                        console.log('Upload Loop starting...');
                        this.uploadFiles(listing.id).then(() =>{
                            console.log('Upload files complete!!!');                            
                            this.disableButtons(false);
                            window.location.assign("/listing/" + listing.id + "#UploadImageSection");

                        });
                        console.log('Upload Loop complete...');
                    } else {
                        this.disableButtons(false);
                        window.location.assign("/listing/" + listing.id + "#UploadImageSection");
                    }                                        

                })
                .catch((error) => {
                    console.error('Error setting listing: ', error);
                }); 
            
    
            }

        }
        //form.classList.add('was-validated');
    }

    handleCancel = (event) =>{
        window.location.assign("/listings");
    }

    handleChange = (event) => {
        if(event.target && event.target.id){
            console.log('Handle Change for Event: ', event.target.id, event.target.value);
            var listing = this.state.listing;
            listing[event.target.id.trim()] = event.target.value;
            this.setState({listing: listing});
        }

    }
    
    handleIntChange = (event) => {
        if(event.target && event.target.id){
            console.log('Handle IntChange for Event: ', event.target.id, event.target.value);
            var listing = this.state.listing;
            var intValue = parseInt(event.target.value);
            if (isNaN(intValue)){
                intValue = 0;
            }
            listing[event.target.id.trim()] = intValue;
            this.setState({listing: listing});
        }

    }

    handleFloatChange = (event) => {
        if(event.target && event.target.id){
            console.log('Handle Float Change for Event: ', event.target.id, event.target.value);
            var listing = this.state.listing;
            var floatValue = parseFloat(event.target.value);
            if (isNaN(floatValue)){
                floatValue = 0;
            }
            listing[event.target.id.trim()] = floatValue;
            this.setState({listing: listing});
        }

    }



    handleOwnerContactChange = (event) => {
        if(event.target && event.target.id){
            console.log('Handle Change for Event: ', event.target.id, event.target.value);
            var listing = this.state.listing;
            var owner = {};
            if(listing.owner){
                owner = listing.owner;
            }
            if(event.target.id === 'ownerName'){
                owner.name = event.target.value;
            }
            if(event.target.id === 'ownerEmail'){
                owner.email = event.target.value;
            }
            if(event.target.id === 'ownerPhone'){
                owner.phone = event.target.value;
            }
            if(event.target.id === 'ownerWhatsApp'){
                owner.whatsApp = event.target.value;
            }
            if(event.target.id === 'ownerLine'){
                owner.line = event.target.value;
            }
            if(event.target.id === 'ownerNotes'){
                owner.notes = event.target.value;
            }

            listing['owner'] = owner;
            this.setState({listing: listing});
        }

    }

    handleOwnerSave = () => {
        if(this.state.listingID){
            this.disableButtons(true);
            var listing = this.state.listing;
            console.log("Saving listing with updated owner info: ", listing);
            firestore.collection('changeSamuiListings2022').doc(listing.id).update(listing).then(() => {
                this.disableButtons(false);
            }).catch((error) => {
                console.error("Error saving owner info: ", error);
                this.disableButtons(false);
            })
        } else {
            this.disableButtons(false);
        }
    }

    handleQuillChange = (content, delta, source, editor) => {
        var listing = this.state.listing;
        listing['description'] = content
        console.log('Setting HTML for Description');
        this.setState({listing: listing});
    }     

    handleHighlight = (event) => {
        if(event.target && event.target.id){
            console.log('Handle Change for Event: ', event.target.id, event.target.value);
            var listing = this.state.listing;
            listing[event.target.id.trim()] = highlight(event.target.value, languages.js);
            this.setState({listing: listing});
        }
    }

    handleFeatureCheckChange = (event) => {
        console.log('Handle Change for Feature Change Event: ', event.target.id, event.target.checked);
        var listing = this.state.listing;
        var features = listing.features;
        if(event.target.checked){
            if(!features.includes(event.target.id)){
                features.push(event.target.id);
            }
        }else{
            if(features.includes(event.target.id)){
                let index = features.indexOf(event.target.id);
                features.splice(index, 1)
            }
        }

        listing.features = features;
        this.setState({listing: listing});

    }
   
    handleDisplaySiteChange = (event) => {
        console.log('Handle Display Site Check Change Event: ', event.target.id, event.target.checked);
        var listing = this.state.listing;
        var currentDisplaySites = [];
        if(listing.displaySites){
            currentDisplaySites = listing.displaySites;
        }
        console.log('Display Sites Before: ', currentDisplaySites);
        var thisDisplaySite = event.target.id.trim();

        let changingSite = false;
        let mapCoordinates = {latitude: 9.560027, longitude: 100.029887}; //{lat: 0, lng: 0}, //geo-point (lat, lng),
        let country = 'Thailand';
        let province = 'Surat Thani';
        let city = 'Ko Samui District';
        let region = 'Bo Put';
        let geoResult = { country: ['Thailand'], province: ['Surat Thani'], city: ['Ko Samui District'], region: ['Bo Put']};

        //Remove the other "site"
        if (thisDisplaySite === 'changesamui'){
            const huaHinIndex = currentDisplaySites.indexOf('changehuahin');
            if (huaHinIndex > -1){
                currentDisplaySites.splice(huaHinIndex, 1); 
                changingSite = true;
                mapCoordinates = {latitude: 9.560027, longitude: 100.029887}; //{lat: 0, lng: 0}, //geo-point (lat, lng),
                country = 'Thailand';
                province = 'Surat Thani';
                city = 'Ko Samui District';
                region = 'Bo Put';
                geoResult = { country: ['Thailand'], province: ['Surat Thani'], city: ['Ko Samui District'], region: ['Bo Put']};

            }
        } else if (thisDisplaySite === 'changehuahin'){
            const samuiIndex = currentDisplaySites.indexOf('changesamui');
            if (samuiIndex > -1){
                currentDisplaySites.splice(samuiIndex, 1);    
                changingSite = true;
                mapCoordinates = {latitude: 12.570943132983757, longitude: 99.95984493938332}; //{lat: 0, lng: 0}, //geo-point (lat, lng),
                country = 'Thailand';
                province = 'Prachuap Khiri Khan';
                city = 'Hua Hin District';
                region = 'Hua Hin';
                geoResult = { country: ['Thailand'], province: ['Prachuap Khiri Khan'], city: ['Hua Hin District'], region: ['Hua Hin']};
       
            }
        }

        var index = currentDisplaySites.indexOf(thisDisplaySite);

        if(thisDisplaySite === 'sellerfi'){
            var thisDisplaySiteState = event.target.checked;

            if(thisDisplaySiteState){
                if(index === -1){
                    currentDisplaySites.push(thisDisplaySite)
                }
            }else{
                if(index !== -1){
                    currentDisplaySites.splice(index, 1);
                }
            }    
        } else {
            currentDisplaySites.push(thisDisplaySite)
        }

        console.log('Display Sites After: ', currentDisplaySites);
        listing.displaySites = currentDisplaySites;
        if(changingSite){
            listing.mapCoordinates = mapCoordinates;
            listing.country = country;
            listing.province = province;
            listing.city = city;
            listing.region = region;
            listing.geoResult = geoResult;
        }
        this.setState({listing: listing});

    }

    handleVisiblityChange = (event) => {
        console.log('Handle Change for Visibility Event: ', event.target.id, event.target.checked);
        var listing = this.state.listing;
        if(event.target.id.trim() === 'visible'){
            listing['visible'] = true;
        } else {
            listing['visible'] = false;
        }

        this.setState({listing: listing});

    }

    geocodeLocation = (lat, lng) => {

        var that = this;
        var listing = this.state.listing;
        
        const latlng = {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
        };

        listing['mapCoordinates'] = { latitude: lat, longitude: lng};

        var geoResult = {
            'country': [],
            'province': [],
            'city': [],
            'region': []
        };
        var geocoder = new google.maps.Geocoder();
        geocoder
            .geocode({ location: latlng })
            .then((response) => {
                if (response.results[0]) {
                    console.log(response);
                    response.results.forEach((result) => {
                        result.address_components.forEach((address_component) => {
                            //console.log(address_component);
                            if(address_component.types.includes('country')){
                                if(geoResult.country.indexOf(address_component.long_name) === -1){
                                    geoResult.country.push(address_component.long_name);
                                }
                            }
                            if(address_component.types.includes('administrative_area_level_1')){
                                if(geoResult.province.indexOf(address_component.long_name) === -1){
                                    geoResult.province.push(address_component.long_name);
                                }
                            }
                            if(address_component.types.includes('administrative_area_level_2')){
                                if(geoResult.city.indexOf(address_component.long_name) === -1){
                                    geoResult.city.push(address_component.long_name);
                                }
                            }
                            if(address_component.types.includes('administrative_area_level_3')){
                                if(geoResult.region.indexOf(address_component.long_name) === -1){
                                    geoResult.region.push(address_component.long_name);
                                }
                            }
                            if(address_component.types.includes('administrative_area_level_4')){
                                if(geoResult.region.indexOf(address_component.long_name) === -1){
                                    geoResult.region.push(address_component.long_name);
                                }
                            }
                            if(address_component.types.includes('locality')){
                                if(geoResult.region.indexOf(address_component.long_name) === -1){
                                    geoResult.region.push(address_component.long_name);
                                }
                            }
                            if(address_component.types.includes('sublocality_level_1')){
                                if(geoResult.region.indexOf(address_component.long_name) === -1){
                                    geoResult.region.push(address_component.long_name);
                                }
                            }
                            if(address_component.types.includes('sublocality_level_2')){
                                if(geoResult.region.indexOf(address_component.long_name) === -1){
                                    geoResult.region.push(address_component.long_name);
                                }
                            }
                        })
                    })
                }
                console.log('GeoResult: ', geoResult);
                listing['geoResult'] = geoResult;
                if(geoResult.country.length == 1){
                    listing['country'] = geoResult.country[0];
                }
                if(geoResult.province.length > 0){
                    listing['province'] = geoResult.province[0];
                }
                if(geoResult.city.length > 0){
                    listing['city'] = geoResult.city[0];
                }
                if(geoResult.region.length > 0){
                    listing['region'] = geoResult.region[0];
                }

                that.setState({listing: listing});
        
            });
    }

    handleCoordinatesChange = (event) => {
        console.log('Handle Change for Coordinates Event: ', event.target.id, event.target.value);
        var listing = this.state.listing;
        if(listing.mapCoordinates === null){
            listing['mapCoordinates'] = { latitude: 0, longitude: 0};
        }
        if(event.target.id === 'latitude'){
            listing['mapCoordinates'] = { latitude: parseFloat(event.target.value), longitude: listing.mapCoordinates.longitude}; 
        } else {
            listing['mapCoordinates'] = { latitude: listing.mapCoordinates.latitude, longitude: parseFloat(event.target.value)}; 
        }

        this.geocodeLocation(listing['mapCoordinates'].latitude, listing['mapCoordinates'].longitude);        
    }

    handleImageDrop = (files) => {

        console.log('Handle Image Drop: ', files);
        this.setState({ uploadFiles: files });

    }

    handleEditOwner = () => {
        console.log('Open Edit Owner');    
        var editOwnerModal = new Modal(document.getElementById('editOwnerModal'), {});
        editOwnerModal.show();
      }

    handleSetCoordinatesFromImage = (geoInfo) => {
        console.log('Setting Map Coordinates...', geoInfo);
        var listing = this.state.listing;
        listing.mapCoordinates = {latitude: geoInfo.latitude, longitude: geoInfo.longitude};
        this.geocodeLocation(geoInfo.latitude, geoInfo.longitude);
    }
       
    editOwnerModal = (props) => {
        console.log(props);
        return (
          <div className="modal fade" id="editOwnerModal" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="editNotesLabel">Add/Edit Owner Info</h3>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div id="existing-internal-info"></div>
                  <form>
                    <div className="mb-3">
                      <label className="col-form-label" htmlFor='ownerName'>Owner Name:</label>
                      <input type="text" className="form-control form-control-lg" id="ownerName" value={this.state.listing.owner && this.state.listing.owner.name ? this.state.listing.owner.name : ''} onChange={this.handleOwnerContactChange} />
                    </div>
                    <div className="mb-3">
                      <label className="col-form-label" htmlFor='ownerPhone'>Phone:</label>
                      <input type="tel" className="form-control form-control-lg" id="ownerPhone" value={this.state.listing.owner && this.state.listing.owner.phone ? this.state.listing.owner.phone : ''} onChange={this.handleOwnerContactChange}  />
                    </div>
                    <div className="mb-3">
                      <label className="col-form-label" htmlFor='ownerEmail'>Email:</label>
                      <input type="email" className="form-control form-control-lg" id="ownerEmail" value={this.state.listing.owner && this.state.listing.owner.email ? this.state.listing.owner.email : ''} onChange={this.handleOwnerContactChange}  />
                    </div>
                    <div className="mb-3">
                      <label className="col-form-label" htmlFor='ownerWhatsApp'>WhatsApp:</label>
                      <input type="text" className="form-control form-control-lg" id="ownerWhatsApp" value={this.state.listing.owner && this.state.listing.owner.whatsApp ? this.state.listing.owner.whatsApp : ''} onChange={this.handleOwnerContactChange} />
                    </div>
                    <div className="mb-3">
                      <label className="col-form-label" htmlFor='ownerLine'>Line:</label>
                      <input type="text" className="form-control form-control-lg" id="ownerLine" value={this.state.listing.owner && this.state.listing.owner.line ? this.state.listing.owner.line : ''} onChange={this.handleOwnerContactChange} />
                    </div>
                    <div className="mb-3">
                      <label className="col-form-label" htmlFor='ownerNotes'>Notes:</label>
                      <textarea className="form-control form-control-lg" id="ownerNotes" value={this.state.listing.owner && this.state.listing.owner.notes ? this.state.listing.owner.notes : ''} onChange={this.handleOwnerContactChange} ></textarea>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary formButton" data-bs-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary formButton" onClick={this.handleOwnerSave} data-bs-dismiss="modal">Add/Update Owner</button>
                </div>
                </div>
              </div>
            </div>
        );
      
      }
      
    handleApiLoaded = (map, maps) => {
        // use map and maps objects
        console.log("API Loaded: ", map, maps)
    };    

    handleMapClick = (event) => {
        console.log('Map Click! ', event)
        this.geocodeLocation(event.lat, event.lng);
    }

    moveImage = (dragIndex, hoverIndex) => {
        console.log('Drag Index: ', dragIndex, ' Hover Index: ' + hoverIndex);
        let listing = this.state.listing;
        let images = this.state.listing.images;
        const draggedImage = images[dragIndex];
        
        listing.images = update(images, { $splice: [[dragIndex, 1], [hoverIndex, 0, draggedImage]]});
        //let itemToMove = listing.images.splice(dragIndex, 1);
        //console.log("Moving: ", itemToMove[0], " to: ", hoverIndex);
        //listing.images.splice(hoverIndex, 0, itemToMove[0]);

        this.updateListing();
        //this.setState({listing: listing});

    }

    deleteImage = (event, index) => {
        console.log('Delete image at index: ', index);
        if(window.confirm('Are you sure you want to delete this image?')){
            console.log('Deleting image!');

            let listing = this.state.listing;
            let images = this.state.listing.images;
            
            listing.images = update(images, { $splice: [[index, 1]]});
    
            this.updateListing();

        }else{
            event.target.checked = false;
        }
    }

    placeImage = (event, index) => {
        let selectedImage = this.state.listing.images[index];
        let activePDFTarget = this.state.activePDFTarget
        console.log('Place image %s at index: %s. In PDF Slot: %s', selectedImage, index, activePDFTarget);
        let pdfImages = this.state.pdfImages;
        pdfImages[activePDFTarget - 1] = selectedImage;


        if(pdfImages.length === 3){
            let listing = this.state.listing;
            listing.imagesForPrint = pdfImages;
            this.setState({listing: listing, pdfImages: pdfImages});
        } else {
            this.setState({pdfImages: pdfImages});
        }
    }

    activatePDFImage = (event, index) => {
        if(event.target.checked){
            console.log('Activate PDF Image Target: ', index);

            for(var i=1; i < 4; i++){
                if(i !== index){
                    document.getElementById("pdfImage" + i).checked = false;
                }
            }
            this.setState({activePDFTarget: index});

        } else {
            this.setState({activePDFTarget: 0});
            console.log('Dectivate PDF Image Target: ', index);
        }
    }

    reloadPage = (event) => {
        this.setState({reload: Date.now()});
    }

    handleSortImages = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if(window.confirm('Are you sure you want to re-sort these images into name/numerical order?')){

            let listing = this.state.listing;
            let images = this.state.listing.images;
            listing.images = images.sort();
            this.updateListing();

        }else{
            console.log("Skipped!");
        }
    }

    handleStampImages = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let companyLogo = 'Samui';
        if(this.state.listing.displaySites.indexOf('changehuahin') > -1){
            companyLogo = 'Hua Hin';
        }

        if(window.confirm('Are you sure you want to stamp and optimize these images with the Change ' + companyLogo + ' Real Estate company logo? Please note - this can take several minutes and happens in the background.')){
            //TODO: Mark Listing as "Stamped" so display can use the proper marked.file.webp file
            const functions = getFunctions();
            const stampPhotosForBucket = httpsCallable(functions, 'stampPhotosForBucket');
            let parameters = { listingID: this.state.listing.id, stampName: companyLogo.toLowerCase() };
            console.log('Calling stampPhotosForBucket with parameters: ', parameters);

            stampPhotosForBucket(parameters).then((result) => {
                console.log(result);
            });
            

        }else{
            console.log("Skipped!");
        }
    }

    handleAvailabilityChange = (event) => {
        console.log('Handle Change for Availability Change Event: ', event.target.id, event.target.name, event.target.value);
        var listing = this.state.listing;
        if(event.target.value === 'available'){
            listing.isSold = false;
        } else {
            listing.isSold = true;
        }

        this.setState({listing: listing});
    }
    
    slugifyName = (listing_name) => {
        //Lower case everything
        listing_name = listing_name.trim().toLowerCase();
        //Make alphanumeric (removes all other characters)
        listing_name = listing_name.replace(/[^a-z0-9_\s-]/g, "");
        //Clean up multiple dashes or whitespaces
        listing_name = listing_name.replace(/[\s-]+/g, " ");
        //Convert whitespaces and underscore to dash
        listing_name = listing_name.replace(/[\s_]/g, "-");
        return listing_name;
    }
    
    openDynamicLink = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let dynamicLink = this.state.listing.dynamicLink;
        window.open(dynamicLink, '_blank');
    }

    handleGenerateDynamicLink = (event) => {
        event.preventDefault();
        event.stopPropagation();

        var that = this;

        console.log('Handle Generate Dynamic Link.');

        //const FirebaseWebApiKey = "AIzaSyDTuIR_dj3FWG6hUnAsVEyAw0VqwUGookI";
        /*
        POST: https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDTuIR_dj3FWG6hUnAsVEyAw0VqwUGookI
        Headers: Content-Type: application/json
        Body:
        {
        "dynamicLinkInfo": {
            "domainUriPrefix": "https://changesiam.com/p",
            "link": "https://changehuahin.com/properties/Z0lFHseaNSDSTexwO01U/luxury-beachfront-villa-at-marrakesh-hua-hin/"
        },
            "suffix": {
                "option": "SHORT"
            }
        }
        */

        let listing = this.state.listing;
        let targetLink = '';
        if(listing.displaySites.includes('changesamui')){
            targetLink = 'https://changesamui.com/';
        }
        if(listing.displaySites.includes('changehuahin')){
            targetLink = 'https://changehuahin.com/';
        }
        if(targetLink){
            targetLink += '/properties/' + listing.id + '/' + this.slugifyName(listing.name) + '/';
            let requestBody = {
                "dynamicLinkInfo": {
                    "domainUriPrefix": "https://changesiam.com/p",
                    "link": targetLink
                },
                "suffix": {
                    "option": "SHORT"
                }
            };
            
            console.log(requestBody);

            fetch('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDTuIR_dj3FWG6hUnAsVEyAw0VqwUGookI', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            }).then(function(response) {
                // The response is a Response instance.
                // You parse the data into a useable format using `.json()`
                return response.json();
            }).then(function(data) {
                // `data` is the parsed version of the JSON returned from the above endpoint.
                console.log(data);  // JSON
                console.log("Short Link: ", data.shortLink);
                if(data.shortLink){
                    let listing = that.state.listing;
                    listing.dynamicLink = data.shortLink;
                    //Save to the database...
                    listing.dateUpdated = firebase.firestore.FieldValue.serverTimestamp();
                    listing.updatedBy = { name: that.state.user.displayName, email: that.state.user.email, uid: that.state.user.uid };
                    firestore.collection('changeSamuiListings2022').doc(that.state.listingID).update(listing).then((result) =>{
                        that.setState({listing: listing});
                    });
                }
            }).catch((exception) => {
                console.error(exception);
            });
        } else {
            console.log('No supported site found...');
        }

    }


    render() {
        const { user } = this.state;

        var action = this.state.action.charAt(0).toUpperCase() + this.state.action.substr(1).toLowerCase();
        var listing = this.state.listing;

        console.log("💻 Rendering... Listing: ", listing, ' Photo Geo:', this.state.photoGeo);
        console.log("PDF Images: ", this.state.pdfImages);

        return(

        <>
        <Header user={user} />
        <BreadcrumbArea currentPage={ action + " Listing"} parentPage={{url: "/listings", name: "Listings"}} user={user} />
        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">

                            <form id='listingForm' className="needs-validation" noValidate  onSubmit={this.handleSave}>

                            <div className="row">
                                <div className="col">                                
                                    {this.state.listing.id  ?
                                    <h3>Listing ID: {this.state.listing.id}</h3>
                                    :
                                    <h3>Listing ID will be available after saving...</h3>
                                    }

                                    <label htmlFor="type" className="form-label">Transaction Type:</label>

                                    <select className="form-select mb-3" aria-label="Type" id="kind" value={this.state.listing ? this.state.listing.kind: 'sale'}  onChange={this.handleChange}>
                                        <option value="sale">Sale</option>
                                        <option value="rent">Rent</option>
                                    </select>                                                
                                    <div className="invalid-feedback">Please enter the transaction type</div>

                                    <div className="mb-3">
                                        <label htmlFor="type" className="form-label">Listing Type</label>
                                        <select className="form-select" aria-label="Type" id="type" value={this.state.listing ? this.state.listing.type : 'villa'}  onChange={this.handleChange}>
                                            <option value="villa">Villa</option>
                                            <option value="house">House</option>
                                            <option value="townhouse">Townhouse</option>
                                            <option value="condo">Condo</option>
                                            <option value="apartment">Apartment</option>
                                            <option value="land">Land</option>
                                            <option value="commercial">Commercial</option>
                                        </select>                                                
                                        <div className="invalid-feedback">Please enter the listing's type</div>
                                    </div>

                                    { this.state.listing.owner &&
                                        <p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-rolodex" viewBox="0 0 16 16">
                                                <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                                                <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z"/>
                                            </svg>&nbsp;
                                            Owner Info: {this.state.listing.owner.name ? this.state.listing.owner.name + ' ' : ''} 
                                            {this.state.listing.owner.phone ? <><br /><i className="bi bi-telephone"></i>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'tel:' + this.state.listing.owner.phone}>{this.state.listing.owner.phone}</a></> : <></> } 
                                            {this.state.listing.owner.email ? <><br /><i className="bi bi-envelope"></i>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'mailto:' + this.state.listing.owner.email}>{this.state.listing.owner.email}</a></> : <></> }
                                            {this.state.listing.owner.whatsApp ? <><br /><i className="bi bi-whatsapp"></i>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'https://wa.me/' + this.state.listing.owner.whatsApp}>{this.state.listing.owner.whatsApp}</a></> : <></> }
                                            {this.state.listing.owner.line ? <><br /><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-line" viewBox="0 0 16 16"><path d="M8 0c4.411 0 8 2.912 8 6.492 0 1.433-.555 2.723-1.715 3.994-1.678 1.932-5.431 4.285-6.285 4.645-.83.35-.734-.197-.696-.413l.003-.018.114-.685c.027-.204.055-.521-.026-.723-.09-.223-.444-.339-.704-.395C2.846 12.39 0 9.701 0 6.492 0 2.912 3.59 0 8 0ZM5.022 7.686H3.497V4.918a.156.156 0 0 0-.155-.156H2.78a.156.156 0 0 0-.156.156v3.486c0 .041.017.08.044.107v.001l.002.002.002.002a.154.154 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157Zm.791-2.924a.156.156 0 0 0-.156.156v3.486c0 .086.07.155.156.155h.562c.086 0 .155-.07.155-.155V4.918a.156.156 0 0 0-.155-.156h-.562Zm3.863 0a.156.156 0 0 0-.156.156v2.07L7.923 4.832a.17.17 0 0 0-.013-.015v-.001a.139.139 0 0 0-.01-.01l-.003-.003a.092.092 0 0 0-.011-.009h-.001L7.88 4.79l-.003-.002a.029.029 0 0 0-.005-.003l-.008-.005h-.002l-.003-.002-.01-.004-.004-.002a.093.093 0 0 0-.01-.003h-.002l-.003-.001-.009-.002h-.006l-.003-.001h-.004l-.002-.001h-.574a.156.156 0 0 0-.156.155v3.486c0 .086.07.155.156.155h.56c.087 0 .157-.07.157-.155v-2.07l1.6 2.16a.154.154 0 0 0 .039.038l.001.001.01.006.004.002a.066.066 0 0 0 .008.004l.007.003.005.002a.168.168 0 0 0 .01.003h.003a.155.155 0 0 0 .04.006h.56c.087 0 .157-.07.157-.155V4.918a.156.156 0 0 0-.156-.156h-.561Zm3.815.717v-.56a.156.156 0 0 0-.155-.157h-2.242a.155.155 0 0 0-.108.044h-.001l-.001.002-.002.003a.155.155 0 0 0-.044.107v3.486c0 .041.017.08.044.107l.002.003.002.002a.155.155 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156Z"/></svg>&nbsp;<a target="_blank" rel="noopener noreferrer" href={'https://line.me/ti/p/' + this.state.listing.owner.line}>{this.state.listing.owner.line}</a></> : <></> }
                                            {this.state.listing.owner.notes ? <><br /><i className="bi bi-pencil-square"></i>&nbsp;{ this.state.listing.owner.notes}</> : <></> }
                                      </p>
                                    }
                                    <button type="button" className="btn btn-white btn-sm mb-3" onClick={ this.handleEditOwner }>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-rolodex" viewBox="0 0 16 16">
                                            <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                                            <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z"/>
                                        </svg>&nbsp; Add/Edit Owner Info
                                    </button>                                    
                                </div>
                                <div className="col">
                                    <p>Visibility:</p>

                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="visible" id="visible" 
                                        checked={this.state.listing.visible} 
                                        onChange={this.handleVisiblityChange} />
                                        <label className="form-check-label" htmlFor="visible">
                                        Visible
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="pocketListing" id="pocketListing" 
                                        checked={!this.state.listing.visible} 
                                        onChange={this.handleVisiblityChange} />
                                        <label className="form-check-label" htmlFor="pocketListing">
                                        Hidden (Pocket Listing ONLY visible in the office)
                                        </label>
                                    </div>


                                    <p className="mt-3">Status:</p>

                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" value="available" id="statusAvailable"
                                        checked={(!this.state.listing.isSold)} 
                                        onChange={this.handleAvailabilityChange } />&nbsp;
                                        <label htmlFor="statusAvailable" className="form-label">Available</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" value="sold" id="statusSold"
                                        checked={this.state.listing.isSold && this.state.listing.isSold === true} 
                                        onChange={this.handleAvailabilityChange } />&nbsp;
                                        <label htmlFor="officeHuaHin" className="form-label">Sold</label>
                                    </div>


                                    <p className="mt-3">Display Sites:</p>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" id="changesamui" checked={this.state.listing.displaySites && this.state.listing.displaySites.includes('changesamui')} onChange={this.handleDisplaySiteChange} />
                                        <label className="form-label" htmlFor="changesamui">&nbsp;Samui Website</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" id="changehuahin" checked={this.state.listing.displaySites && this.state.listing.displaySites.includes('changehuahin')} onChange={this.handleDisplaySiteChange} />
                                        <label className="form-label" htmlFor="changehuahin">&nbsp;Hua Hin Website</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="sellerfi" checked={this.state.listing.displaySites && this.state.listing.displaySites.includes('sellerfi')} onChange={this.handleDisplaySiteChange} />
                                        <label className="form-label" htmlFor="sellerfi">&nbsp;SellerFi Website</label>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Listing Name</label>
                                <input type="text" className="form-control" id="name" value={this.state.listing ? this.state.listing.name : ''} onChange={this.handleChange} required />
                                <div className="invalid-feedback">Please enter the listing's name / title</div>
                            </div>

                            { (this.state.listing && this.state.listing.dynamicLink) &&
                                <>
                                    <label htmlFor="dynamicLink" className="form-label">"Short" Link</label>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" readOnly id="dynamicLink" value={this.state.listing.dynamicLink} aria-describedby="buttonVisitLink" />
                                        <button class="btn btn-outline-secondary" type="button" id="buttonVisitLink" onClick={this.openDynamicLink}>Open<i className="ms-2 bi bi-box-arrow-up-right"></i></button>
                                    </div>
                                </>
                            }
                            { (this.state.listing && this.state.listing.id && this.state.listing.dynamicLink === undefined) &&
                                <div className="mb-3">
                                    <button className="btn btn-outline-secondary" onClick={this.handleGenerateDynamicLink}><i className="bi bi-arrow-left-right"></i> Generate "Short" Link</button>
                                </div>
                            }

                            { (this.state.listing  && this.state.listing.type !== 'land') &&

                            <div className="mb-3">
                                <label htmlFor="villaName" className="form-label">Villa Proper Name</label>
                                <input type="text" className="form-control" id="villaName" value={this.state.listing.villaName} onChange={this.handleChange} />
                                <div className="invalid-feedback">Please enter the listing's proper name</div>
                            </div>
                            
                            }


                            <div className='row'>
                                <div className="col-md-3 col-sm-12">                                
                                    <div className="mb-3">

                                        { this.state.listing && this.state.listing.kind === 'rent' ?
                                        <label htmlFor="price" className="form-label">Rental Price (monthly price)</label>
                                        :
                                        <label htmlFor="price" className="form-label">Price</label>
                                        }
                                        <div className="input-group">
                                            <div className="input-group-text">฿</div>
                                            <input type="number" className="form-control" id="price" placeholder="20000000"  value={this.state.listing.price} onChange={this.handleIntChange}  />
                                            <div className="invalid-feedback">Please enter the price</div>
                                        </div>
                                    </div>

                                </div>

                                {  (this.state.listing  && this.state.listing.type !== 'land') &&
                                <>
                                <div className="col-md-3 col-sm-auto">                                
                                    <div className="mb-3">
                                        <label htmlFor="bed" className="form-label">Bedroom Count</label>
                                        <input type="number" className="form-control" id="bed" placeholder="3"  value={this.state.listing.bed} onChange={this.handleIntChange}  />
                                        <div className="invalid-feedback">Please enter the number of bedrooms</div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-auto">                                
                                    <div className="mb-3">
                                        <label htmlFor="bath" className="form-label">Bathroom Count</label>
                                        <input type="number" className="form-control" id="bath" placeholder="3" step="any" value={this.state.listing.bath} onChange={this.handleFloatChange}  />
                                        <div className="invalid-feedback">Please enter the number of bathrooms</div>
                                    </div>
                                </div>
                                <div className="col-md-3">                                
                                    <div className="mb-3">
                                        <label htmlFor="yearBuilt" className="form-label">Year Built</label>
                                        <input type="number" className="form-control" id="yearBuilt"  value={this.state.listing.yearBuilt} onChange={this.handleIntChange}  />
                                        <div className="invalid-feedback">Please enter the year the listing was built</div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-12">                                
                                    <div className="mb-3">
                                        <label htmlFor="propertySize" className="form-label">Building Size (square meters - number only)</label>
                                        <input type="number" className="form-control" id="propertySize" placeholder="300"  value={this.state.listing.propertySize} onChange={this.handleIntChange}  />
                                        <div className="invalid-feedback">Please enter the number of square meters for the built up area</div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-12">                                
                                    <div className="mb-3">
                                        <label htmlFor="interiorSize" className="form-label">Interior Size (square meters - number only)</label>
                                        <input type="number" className="form-control" id="interiorSize" placeholder="300"  value={this.state.listing.interiorSize} onChange={this.handleIntChange}  />
                                        <div className="invalid-feedback">Please enter the number of square meters for the interior area of the building</div>
                                    </div>
                                </div>

                                </>
                                }

                                <div className="col-md-4 col-sm-12">                                
                                    <div className="mb-3">
                                        <label htmlFor="landSize" className="form-label">Land Size (square meters - number only)</label>
                                        <input type="number" className="form-control" id="landSize" placeholder="600"  value={this.state.listing.landSize} onChange={this.handleIntChange}  />
                                        <div className="invalid-feedback">Please enter the number of square meters for the land plot</div>
                                    </div>
                                </div>


                            </div>

                            {this.state.listing && this.state.listing.displaySites && this.state.listing.displaySites.includes('sellerfi') &&
                            <div>
                            <p className="mt-3">SellerFi Terms and Conditions:</p>
                                <div className='row'>
                                    <div className="col-md-2 col-sm-12">                                
                                        <div className="mb-3">
                                            <label htmlFor="sellerfiPrice" className="form-label">SellerFi Price</label>
                                            <input type="text" className="form-control" id="sellerfiPrice" placeholder="$5M USD"  value={this.state.listing.sellerfiPrice} onChange={this.handleChange}  />
                                            <div className="invalid-feedback">Please enter the price being offered with SellerFi Financing</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-sm-12">                                
                                        <div className="mb-3">
                                            <label htmlFor="sellerfiDownPayment" className="form-label">Down Payment</label>
                                            <input type="text" className="form-control" id="sellerfiDownPayment" placeholder="50%"  value={this.state.listing.sellerfiDownPayment} onChange={this.handleChange}  />
                                            <div className="invalid-feedback">Please enter the down payment required for SellerFi Financing</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-sm-12">                                
                                        <div className="mb-3">
                                            <label htmlFor="sellerfiDuration" className="form-label">Duration</label>
                                            <input type="text" className="form-control" id="sellerfiDuration" placeholder="10 years"  value={this.state.listing.sellerfiDuration} onChange={this.handleChange}  />
                                            <div className="invalid-feedback">Please enter the financing duration for SellerFi Financing</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-sm-12">                                
                                        <div className="mb-3">
                                            <label htmlFor="sellerfiInterestRate" className="form-label">Interest Rate</label>
                                            <input type="text" className="form-control" id="sellerfiInterestRate" placeholder="7%"  value={this.state.listing.sellerfiInterestRate} onChange={this.handleChange}  />
                                            <div className="invalid-feedback">Please enter the interest rate for SellerFi Financing</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-sm-12">                                
                                        <div className="mb-3">
                                            <label htmlFor="sellerfiPaymentType" className="form-label">Payment Type</label>
                                            <input type="text" className="form-control" id="sellerfiPaymentType" placeholder="P&I"  value={this.state.listing.sellerfiPaymentType} onChange={this.handleChange}  />
                                            <div className="invalid-feedback">Please enter the payment type for SellerFi Financing</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-12 col-sm-12">                                
                                        <div className="mb-3">
                                            <label htmlFor="sellerfiPropertyWebsite" className="form-label">SellerFi Property Source Website</label>
                                            <input type="text" className="form-control" id="sellerfiPropertyWebsite" placeholder="https://google.com"  value={this.state.listing.sellerfiPropertyWebsite} onChange={this.handleChange}  />
                                            <div className="invalid-feedback">Please enter the SellerFi Property Website</div>
                                        </div>
                                    </div>
                                </div>

                            </div>                            
                            }     

                            <div>
                            <p className="mt-3">Extra Info:</p>
                                <div className='row'>
                                    <div className="col-md-6 col-sm-12">                                
                                        <div className="mb-3">
                                            <label htmlFor="propertyVideo" className="form-label">Property Video</label>
                                            <input type="text" className="form-control" id="propertyVideo" placeholder="https://youtube.com/"  value={this.state.listing.propertyVideo} onChange={this.handleChange}  />
                                            <div className="invalid-feedback">Please enter the YouTube or Vimeo (or direct) Video URL</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">                                
                                        <div className="mb-3">
                                            <label htmlFor="propertyWebsite" className="form-label">Property Website</label>
                                            <input type="text" className="form-control" id="propertyWebsite" placeholder="https://property-site.com"  value={this.state.listing.propertyWebsite} onChange={this.handleChange}  />
                                            <div className="invalid-feedback">Please enter the Property Website</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <p className="mt-3">Features:</p>

                                <div className="row">
                                { features.map(feature => ( 
                                    <div className="col-md-3" key={feature.name}>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id={feature.name} checked={this.featureExists(feature.name, this.state.listing.features)} onChange={this.handleFeatureCheckChange} />
                                            <label className="form-label" htmlFor="seaview"><img className="property-icon-header" src={feature.image} />&nbsp;{this.prettyFeatureName(feature.name)}</label>
                                        </div>
                                    </div>
                                ))}                            
                                </div>
                            </div>


                            <div className="my-3">
                                <label htmlFor="description" className="form-label">Description</label>
                                <div className="row">
                                    <div className="col mb-3">
                                        <ReactQuill className="vh-50 mb-5" id="description" value={this.state.listing.description} onChange={this.handleQuillChange} />                                
                                    </div>
                                </div>
                            </div>

                            <div className="my-3">
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Additional Features (communuity features, builder features, etc.)
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <label htmlFor="propertyFeatures" className="form-label">HTML Code to be provided by Michael Blonsky</label>
                                                <textarea className="vh-50 w-100 mb-5 ta-code" id="propertyFeatures" value={this.state.listing.propertyFeatures} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h4 className='mt-3'>Listing Location: </h4>

                            <div className="mb-3">
                                <label htmlFor="address" className="form-label">Address</label>
                                <input type="text" className="form-control" id="address"  value={this.state.listing.address} onChange={this.handleChange}  />
                                <div className="invalid-feedback">Please enter the listing address</div>
                            </div>
                            

                            <div className="row">
                                <div className="col">                                
                                    <div className="mb-3">
                                        <label htmlFor="latitude" className="form-label">Latitude</label>
                                        <input type="number" className="form-control" id="latitude"  value={(this.state.listing.mapCoordinates && this.state.listing.mapCoordinates.latitude) ? this.state.listing.mapCoordinates.latitude : 0} onChange={this.handleCoordinatesChange}  />
                                        <div className="invalid-feedback">Please enter the map latitude</div>
                                    </div>
                                </div>
                                <div className="col">                                
                                    <div className="mb-3">
                                        <label htmlFor="longitude" className="form-label">Longitude</label>
                                        <input type="number" className="form-control" id="longitude"  value={(this.state.listing.mapCoordinates && this.state.listing.mapCoordinates.longitude) ? this.state.listing.mapCoordinates.longitude : 0} onChange={this.handleCoordinatesChange}  />
                                        <div className="invalid-feedback">Please enter the map longitude</div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col">                                
                                    <div className="mb-3">
                                        <label htmlFor="country" className="form-label">Country</label>
                                        <input type="text" className="form-control" id="country"  value={ this.state.listing.country } onChange={this.handleChange}  />
                                        <div className="invalid-feedback">Please enter the country</div>
                                    </div>
                                </div>
                                { (this.state.listing.geoResult && this.state.listing.geoResult.province) &&
                                <div className="col">                                
                                    <div className="mb-3">
                                        <label htmlFor="longitude" className="form-label">Province</label>

                                        <select className="form-select" aria-label="Type" id="province" value={this.state.listing.province}  onChange={this.handleChange}>
                                            { this.state.listing.geoResult.province.map(province => ( 
                                                <option key={province} value={province}>{province}</option>
                                            ))}
                                        </select>                                                


                                        <div className="invalid-feedback">Please enter the province</div>
                                    </div>
                                </div>
                                }
                                { (this.state.listing.geoResult && this.state.listing.geoResult.city) &&

                                <div className="col">                                
                                    <div className="mb-3">
                                        <label htmlFor="longitude" className="form-label">City</label>

                                        <select className="form-select" aria-label="Type" id="city" value={this.state.listing.city}  onChange={this.handleChange}>
                                            { this.state.listing.geoResult.city.map(city => ( 
                                                <option key={city} value={city}>{city}</option>
                                            ))}
                                        </select>                                                

                                        <div className="invalid-feedback">Please enter the city</div>
                                    </div>
                                </div>
                                
                                }
                                { (this.state.listing.geoResult && this.state.listing.geoResult.region) &&
                                <div className="col">                                
                                    <div className="mb-3">
                                        <label htmlFor="longitude" className="form-label">Region</label>

                                        <select className="form-select" aria-label="Type" id="region" value={this.state.listing.region}  onChange={this.handleChange}>
                                            { this.state.listing.geoResult.region.map(region => ( 
                                                <option key={region} value={region}>{region}</option>
                                            ))}
                                        </select>                                                

                                        <div className="invalid-feedback">Please enter the region</div>
                                    </div>
                                </div>
                                }
                            </div>


                            { this.state.listing.mapCoordinates &&
                            <div className="my-3 border rounded p-3">
                                <h4>Listing Map: </h4>
                                <span className="eyebrow">Click a spot on the map to move marker... (or enter the coordinates above)</span>
                                <div style={{ height: '500px', width: '100%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: 'AIzaSyAqmGyVMm9TYcZpY8qbPgGfWmx9jErsx1I' }}
                                        center={{lat: this.state.listing.mapCoordinates.latitude, lng: this.state.listing.mapCoordinates.longitude}}
                                        defaultZoom={13}
                                        options={ {mapTypeControl: true, streetViewControl: true, fullscreenControl: true }}
                                        yesIWantToUseGoogleMapApiInternals={true}
                                        onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                                        onClick={ this.handleMapClick }
                                    >
                                        <GoogleMapContainerComponent
                                            lat={this.state.listing.mapCoordinates.latitude}
                                            lng={this.state.listing.mapCoordinates.longitude}
                                            text={<i className="bi bi-geo-fill text-danger"></i>}
                                        />
                                    </GoogleMapReact>
                                </div>
                            </div>
                            }


                            { this.state.listing.id && 
                            <>
                            <div id="imageDisplayArea" className="my-3 border rounded p-3">
                                <h4>Listing Images <button className="btn btn-outline-secondary" onClick={this.handleSortImages}><i className="bi bi-sort-down-alt"></i> Sort Images</button>&nbsp;<button className="btn btn-outline-secondary" onClick={this.handleStampImages}><i className="bi bi-images"></i> Stamp Images</button><span className="float-end small text-muted text-uppercase pt-3">Image Count: {this.state.listing.images.length}; Watermark Count: {this.state.listing.watermarkCount ? this.state.listing.watermarkCount : 0} <a className="d-none" onClick={this.reloadPage}><i className="bi bi-arrow-clockwise"></i></a></span>
                                </h4>


                                <div className="accordion" id="imagesForPDF">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="pdfTitle">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pdfTitleCollapse" aria-expanded="false" aria-controls="pdfTitleCollapse">
                                        Images for PDF (Best 3)
                                        </button>
                                        </h2>
                                        <div id="pdfTitleCollapse" className="accordion-collapse collapse" aria-labelledby="pdfTitle" data-bs-parent="#imagesForPDF">
                                            <div className="accordion-body">
                                                <p><strong>Select Best 3 Images:</strong> Select one slot below and then click the arrow above the picture you wish to place in that spot. If no images are selected, the PDF will use the first 3 images from the listing.</p>

                                                <div className="row row-cols-3 gx-2 gy-2">
                                                    <div className="col">

                                                        <div className='card'>
                                                            <div className='card-body-pdf'>
                                                                <div className='p-1 mh-100'>
                                                                    <div>
                                                                        <input className="form-check-input" type="checkbox" id="pdfImage1" onChange={(event) => { this.activatePDFImage(event, 1); }} />
                                                                        <label htmlFor="pdfImage1" className="form-label">&nbsp;PDF Image 1</label>
                                                                        {(this.state.pdfImages && this.state.pdfImages[0]) &&
                                                                            <img className="img-fluid" src={this.state.pdfImages[0]} />
                                                                        }
                                                                    </div>                                                                    
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="col">

                                                        <div className='card'>
                                                            <div className='card-body-pdf'>
                                                                <div className='p-1 mh-100'>
                                                                    <div>
                                                                        <input className="form-check-input" type="checkbox" id="pdfImage2"  onChange={(event) => { this.activatePDFImage(event, 2); }}/>
                                                                        <label htmlFor="pdfImage2" className="form-label">&nbsp;PDF Image 2</label>
                                                                        {(this.state.pdfImages && this.state.pdfImages[1]) &&
                                                                            <img className="img-fluid" src={this.state.pdfImages[1]} />
                                                                        }
                                                                    </div>                                                                    
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="col">

                                                        <div className='card'>
                                                            <div className='card-body-pdf'>
                                                                <div className='p-1 mh-100'>
                                                                    <div>
                                                                        <input className="form-check-input" type="checkbox" id="pdfImage3"  onChange={(event) => { this.activatePDFImage(event, 3); }} />
                                                                        <label htmlFor="pdfImage3" className="form-label">&nbsp;PDF Image 3</label>
                                                                        {(this.state.pdfImages && this.state.pdfImages[2]) &&
                                                                            <img className="img-fluid" src={this.state.pdfImages[2]} />
                                                                        }

                                                                    </div>                                                                    
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <p className="small muted mb-2">(Click on an image box and drag and drop to re-order images)</p>
                                <div className='row row-cols-6 gx-2 gy-2'>
                                    <DndProvider backend={HTML5Backend}>
                                        <ImageList images={this.state.listing.images} moveImage={this.moveImage} deleteImage={this.deleteImage} placeImage={this.placeImage} activePDFTarget={this.state.activePDFTarget} />
                                    </DndProvider>
                                </div>
                            </div>

                            <h4>Please upload at least <span className="text-danger">8 images</span> for "Featured Listings":</h4>
                            <div id="UploadImageSection" className="mb-3">
                                <FileUploader handleChange={this.handleImageDrop} name="file" types={["JPG", "JPEG", "PNG", "WEBM", "WEBP"]} multiple={true} />
                            </div>
                            </>
                            }

                            {this.state.uploadFiles &&
                                <div id="imagePreviewArea" className="my-3 border rounded p-3">
                                    <div style={{ minHeight: '60px' }}>
                                        <h4>Images to be Added:<a className='btn btn-outline-secondary float-end' onClick={this.geolocatePhotos}><i className="bi bi-geo-alt"></i>&nbsp;Geolocate Photos</a></h4>
                                        <small>(NOTE: You must save listing to upload these images to the server)</small>
                                    </div>
                                    
                                    <div className='row row-cols-4 gx-2 gy-2'>
                                        { Array.from(this.state.uploadFiles).map((file, index) => (
                                            <div key={index}  className="col">
                                                <div className="border rounded p-1">
                                                    <input className="form-check-input" type="checkbox" id={'uploadfile-' + index} />
                                                    <label htmlFor={'uploadfile-' + index} className="form-label">&nbsp;Remove</label>
                                                    <img className="img-fluid" src={URL.createObjectURL(file)} />

                                                    { (this.state.photoGeo && this.state.photoGeo["" + file.name]) && 
                                                    <div className="geoInfo" id={'geoInfo-' + index}>
                                                        <small className="text-muted">
                                                        Image GPS <br />
                                                        Latitude: {this.state.photoGeo["" + file.name].latitude}<br />
                                                        Longitude: {this.state.photoGeo["" + file.name].longitude}
                                                        <a className="btn btn-secondary btn-sm" onClick={this.handleSetCoordinatesFromImage.bind(this, this.state.photoGeo["" + file.name])}>Use GPS for Listing</a>
                                                        </small>
                                                    </div>
                                                    }
                                                    <div id={'progress-' + index} className="progress my-1" style={{ minHeight: '20px'}}>
                                                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width: '0%'}}></div>
                                                    </div>

                                                </div>
                                            </div>
                                        )) }
                                    </div>
                                </div>
                            }

                            <div className="mb-3">
                                <button type="submit" className="formButton btn btn-primary mb-3"><i className="bi bi-cloud-check-fill"></i>&nbsp;Save Listing</button>
                                <button type="button" className="formButton btn btn-outline-secondary mb-3 ms-3" onClick={this.handleCancel}><i className="bi bi-x-octagon-fill"></i>&nbsp;Cancel</button>
                            </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        { this.editOwnerModal(this.props) }

        <Footer />
        </>
        );
    }

    componentDidMount(){
        
        if(this.state.listingID){
            this.getListing();
        }

    }


    componentDidMountOLD(){
        this.onAuthStateChangedObserver = auth.onAuthStateChanged((user) => {
          if (user) {
            var uid = user.uid;
            if(this.state.listingID){
                console.log('Getting listing from Firebase...');
                this.getListing();
            }
            this.setState({ user: user });
          } else {
    
    
            this.setState({ 
              user: null,    
            });
            
            console.log('User is logged out...');
          }
        });
      }
    }
    
export default ListingAddEdit;
    